<template>
    <div class="table-container">
      <!-- Top Controls Row: Filter and Sort Controls -->
      <div class="top-controls">
        <n-space align="center" class="controls-wrapper">
          <n-input placeholder="Filter" v-model="filter" class="control-input" />
          <n-button @click="sortTable" class="control-button">Sort</n-button>
        </n-space>
      </div>
  
      <!-- Column Headers -->
      <div class="column-headers">
        <n-space justify="space-between" class="header-row">
          <span v-for="col in columns" :key="col.key" class="column-header">
            {{ col.title }}
          </span>
        </n-space>
      </div>
  
      <!-- Data Table -->
      <div v-if="loading" class="loading-container">
        <n-spin size="large" description="Loading data..." />
      </div>
      <div v-else-if="tableData.length" class="table-data">
        <n-space vertical>
          <div v-for="(row, index) in paginatedData" :key="index" class="table-row">
            <n-space justify="space-between" align="center">
              <span v-for="col in columns" :key="col.key" class="table-cell">
                {{ row[col.key] }}
              </span>
            </n-space>
          </div>
        </n-space>
      </div>
      <div v-else class="empty-state">
        <n-empty description="No data available in this table." />
      </div>
  
      <!-- Bottom Row: Pagination Controls -->
      <div class="pagination-controls">
        <n-pagination
          v-model:page="pagination.page"
          v-model:page-size="pagination.pageSize"
          :page-count="totalPages"
          show-size-picker
          :page-sizes="[10, 20, 50, 100]"
          show-quick-jumper
          :item-count="pagination.itemCount"
          @update:page="handlePageChange"
          @update:page-size="handlePageSizeChange"
        />
      </div>
    </div>
  </template>
  
  <script>
  import { defineComponent, ref, computed } from 'vue';
  import { NSpin, NEmpty, NPagination, NButton, NSpace, NInput } from 'naive-ui';
  import { supabase } from '@/supabaseClient';
  
  export default defineComponent({
    name: 'TableDisplay',
    components: {
      NSpin,
      NEmpty,
      NPagination,
      NButton,
      NSpace,
      NInput,
    },
    props: {
      folder: {
        type: Object,
        required: false,
      },
    },
    setup(props) {
      const columns = ref([
        { title: 'id', key: 'id', width: 80 },
        { title: 'created_at', key: 'created_at', width: 180 },
        { title: 'name', key: 'name', width: 200 },
        { title: 'user_id', key: 'user_id', width: 200 },
        { title: 'type', key: 'type', width: 150 },
        { title: 'description', key: 'description', width: 300 },
      ]);
      const tableData = ref([]);
      const loading = ref(false);
      const filter = ref('');
      const pagination = ref({
        page: 1,
        pageSize: 10,
        itemCount: 0,
      });
  
      const paginatedData = computed(() => {
        const start = (pagination.value.page - 1) * pagination.value.pageSize;
        const end = start + pagination.value.pageSize;
        return tableData.value.slice(start, end);
      });
  
      const totalPages = computed(() => {
        return Math.ceil(pagination.value.itemCount / pagination.value.pageSize);
      });
  
      const fetchTableData = async () => {
        try {
          loading.value = true;
          const { data, error } = await supabase
            .from(props.folder.name)
            .select('*');
  
          if (error) throw error;
  
          tableData.value = data;
          pagination.value.itemCount = data.length;
        } catch (error) {
          console.error('Error fetching table data:', error);
        } finally {
          loading.value = false;
        }
      };
  
      const sortTable = () => {
        // Add your sorting logic here
      };
  
      const handlePageChange = (page) => {
        pagination.value.page = page;
      };
  
      const handlePageSizeChange = (pageSize) => {
        pagination.value.pageSize = pageSize;
        pagination.value.page = 1;
      };
  
      fetchTableData();
  
      return {
        columns,
        tableData,
        paginatedData,
        pagination,
        loading,
        filter,
        totalPages,
        sortTable,
        handlePageChange,
        handlePageSizeChange,
      };
    },
  });
  </script>
  
  <style scoped>
  .table-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    padding: 16px;
    box-sizing: border-box;
    position: relative;
  }
  
  .top-controls {
    position: fixed;
    top: 50px; /* Align directly below the navbar */
    left: 280px;
    right: 16px;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    padding: 8px;
    background-color: white;
    border-bottom: 1px solid #cbd5e0;
  }
  
  .controls-wrapper {
    width: 100%;
  }
  
  .control-input {
    width: 200px;
  }
  
  .control-button {
    margin-left: 8px;
  }
  
  .column-headers {
    position: fixed;
    top: 101px; /* Align directly below the top-controls */
    left: 280px;
    right: 16px;
    display: flex;
    justify-content: space-between;
    padding: 8px 0;
    border-bottom:#cbd5e0 1px solid;
    background-color: rgb(246, 246, 246);
    z-index: 1;
  }
  
  .header-row {
    width: 100%;
  }
  
  .column-header {
    font-weight: 400;
    color: #6b7280;
    text-align: left;
  }
  
  .table-data {
    margin-top: 144px; /* Adjust to account for the height of top-controls and column-headers */
    flex-grow: 1;
    overflow-y: auto;
    padding-bottom: 8px; /* Space for pagination */
  }
  
  .table-row {
    background-color: white;
    border: 1px solid #cbd5e0;
    padding: 8px 16px;
    margin-bottom: 8px;
  }
  
  .table-row:last-child {
    margin-bottom: 0;
  }
  
  .table-cell {
    flex-grow: 1;
  }
  
  .pagination-controls {
    display: flex;
    justify-content: flex-end;
    padding-top: 8px;
    border-top: 1px solid #ebebeb;
  }
  
  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
  }
  
  .empty-state {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    color: #6b7280;
    font-size: 16px;
  }
  </style>
  