<template>
  <div class="dropdown-container" v-click-outside="closeDropdown">
    <n-button type="default" class="dropdown-button" @click="toggleDropdown">
      <div class="button-content">
        <div class="org-name">{{ selectedOrgName || 'Select Organization' }}</div>
        <div class="org-balance">Balance: {{ selectedOrgBalance }}</div>
      </div>
      <n-icon class="dropdown-icon">
        <i class="material-icons custom-arrow-icon">unfold_more</i>
      </n-icon>
    </n-button>

    <transition name="fade">
      <div v-if="dropdownVisible" class="dropdown-card">
        <n-input v-model="searchQuery" placeholder="Find organization..." clearable class="search-input" />
        <n-space vertical>
          <div
            v-for="org in filteredOrganizations"
            :key="org.id"
            class="dropdown-item"
            @click="selectOrganization(org)"
          >
            <n-icon class="check-icon" v-if="selectedOrgName === org.name">
              <i class="material-icons">check</i>
            </n-icon>
            {{ org.name }}
          </div>
          <div class="dropdown-item new-item" @click="createNewOrganization">
            <n-icon class="add-icon">
              <i class="material-icons">add_circle</i>
            </n-icon>
            New organization
          </div>
        </n-space>
      </div>
    </transition>
  </div>
</template>

<script>
import { defineComponent, ref, computed, onMounted } from 'vue';
import { NButton, NIcon, NInput, NSpace } from 'naive-ui';
import { supabase } from '@/supabaseClient';
import clickOutside from '@/directives/clickOutside'; // Import custom clickoutside directive

export default defineComponent({
  name: 'MyOrganisationButton',
  components: {
    NButton,
    NIcon,
    NInput,
    NSpace,
  },
  directives: {
    clickOutside, // Register the custom directive for clickoutside
  },
  setup() {
    const selectedOrgName = ref('');
    const selectedOrgBalance = ref('');
    const organizations = ref([]);
    const searchQuery = ref('');
    const dropdownVisible = ref(false);

    const fetchOrganizations = async () => {
      try {
        const { data, error } = await supabase.from('organizations').select('id, name, balance');
        if (error) {
          console.error('Error fetching organizations:', error.message);
        } else {
          organizations.value = data;
          const selectedOrgId = sessionStorage.getItem('selectedOrgId');
          if (selectedOrgId) {
            const selectedOrg = organizations.value.find(org => org.id === selectedOrgId);
            if (selectedOrg) {
              selectedOrgName.value = selectedOrg.name;
              selectedOrgBalance.value = selectedOrg.balance ? `$${selectedOrg.balance}` : 'N/A';
            }
          }
        }
      } catch (error) {
        console.error('Failed to fetch organizations:', error);
      }
    };

    const filteredOrganizations = computed(() => {
      return organizations.value.filter(org =>
        org.name.toLowerCase().includes(searchQuery.value.toLowerCase())
      );
    });

    const toggleDropdown = () => {
      dropdownVisible.value = !dropdownVisible.value;
    };

    const closeDropdown = () => {
      dropdownVisible.value = false;
    };

    const selectOrganization = (org) => {
      selectedOrgName.value = org.name;
      selectedOrgBalance.value = org.balance ? `$${org.balance}` : 'N/A';
      sessionStorage.setItem('selectedOrgId', org.id);
      dropdownVisible.value = false;
    };

    const createNewOrganization = () => {
      console.log('Create New Organization Clicked');
    };

    onMounted(() => {
      fetchOrganizations();
    });

    return {
      selectedOrgName,
      selectedOrgBalance,
      searchQuery,
      filteredOrganizations,
      dropdownVisible,
      toggleDropdown,
      closeDropdown,
      selectOrganization,
      createNewOrganization,
    };
  },
});
</script>

<style scoped>
.dropdown-container {
  position: relative;
}

.dropdown-button {
  background-color: white;
  color: #333;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px;
  width: auto;
  max-width: 180px;
  height: 36px;
  border-radius: 4px;
  box-sizing: border-box;
}

.button-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.org-name {
  font-weight: 500;
  color: #333;
}

.org-balance {
  font-size: 12px;
  color: #888;
}

.dropdown-icon {
  margin-left: 8px;
  display: flex;
  align-items: center;
}

.custom-arrow-icon {
  font-size: 16px;
}

/* Dropdown card with shadow */
.dropdown-card {
  position: absolute;
  top: 100%;
  left: 0;
  width: 250px;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-top: 8px;
  z-index: 1001;
  padding: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Added shadow */
  transition: all 0.3s ease-in-out; /* Smooth transition */
}

.dropdown-item {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dropdown-item:hover {
  background-color: #f0f0f0;
}

.new-item {
  color: #18b67c;
}

.search-input {
  margin-bottom: 8px;
}

.check-icon,
.add-icon {
  margin-right: 8px;
  font-size: 18px;
  display: flex;
  align-items: center;
}
</style>
