<!-- src/components/DataDash.vue -->
<template>
    <div>
      
      <div class="dashboard-content flex">
        <div class="chart-container flex-1">
          <BarChart />
          <CreditUsageChart />
          <RecentUsageList />
        </div>
        <BalanceCard :userBalance="userBalance" class="balance-card" />
      </div>
    </div>
  </template>
  
  <script>
  import CreditUsageChart from '@/components/CreditUsageChart.vue';
  import BarChart from '@/components/BarChart.vue';
  import RecentUsageList from '@/components/RecentUsageList.vue';
  import BalanceCard from '@/components/BalanceCard.vue';
  
  export default {
    components: {
      BarChart,
      CreditUsageChart,
      RecentUsageList,
      BalanceCard
    },
    data() {
      return {
        userBalance: 100 // Replace with actual data
      };
    }
  };
  </script>
  
  <style scoped>
  .dashboard-content {
    display: flex;
    align-items: stretch; /* Ensure equal height for children */
    gap: 1rem; /* Space between components */
  }
  
  .chart-container {
    flex: 1; /* Allow charts to grow and fill space */
  }
  </style>
  