<template>
    <div class="dashboard-knowledge-page">
      <div class="content-area">
        <!-- Banner Section -->
        <div class="knowledge-banner">
          <div class="search-container">
            <h1 class="banner-title">How can we help?</h1>
            <div class="search-bar">
              <n-input class="search-input" placeholder="Search Beacon, Docs, Reports, etc." clearable>
                <template #suffix>
                  <n-icon>
                    <i class="material-icons">search</i>
                  </n-icon>
                </template>
              </n-input>
              <n-button type="primary" class="search-button">Search</n-button>
            </div>
          </div>
        </div>
  
        <!-- Help Desk Sections -->
        <div class="help-desk">
          <div class="help-desk-section">
            <n-card class="help-card" bordered>
              <div class="icon-container">
                <n-icon size="48">
                  <i class="material-icons">help_outline</i>
                </n-icon>
              </div>
              <h3 class="help-title">Getting Started</h3>
              <p class="help-description">Get your Help Scout account set up in just 6 steps.</p>
            </n-card>
          </div>
  
          <div class="help-desk-section">
            <n-card class="help-card" bordered>
              <div class="icon-container">
                <n-icon size="48">
                  <i class="material-icons">manage_accounts</i>
                </n-icon>
              </div>
              <h3 class="help-title">Account Management</h3>
              <p class="help-description">Manage your account, create new users, and more.</p>
            </n-card>
          </div>
  
          <div class="help-desk-section">
            <n-card class="help-card" bordered>
              <div class="icon-container">
                <n-icon size="48">
                  <i class="material-icons">assessment</i>
                </n-icon>
              </div>
              <h3 class="help-title">Reporting</h3>
              <p class="help-description">Reporting features, metric definitions, and usage.</p>
            </n-card>
          </div>
        </div>
  
        <!-- Blog Posts Section -->
        <div class="blog-posts-section">
          <h2 class="section-title">Latest Blog Posts</h2>
          <ul class="blog-list">
            <li v-for="post in posts" :key="post" @click="openPostInNewTab(post)">
              {{ formatTitle(post) }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { ref } from 'vue';
  import { NButton, NInput, NIcon, NCard } from 'naive-ui';
  
  // Dynamically require posts from the posts directory
  const requirePost = require.context('@/posts', false, /\.vue$/);
  
  export default {
    components: {
      NButton,
      NInput,
      NIcon,
      NCard,
    },
    setup() {
      // Load the posts dynamically using require.context
      const posts = ref(requirePost.keys().map(file => file.replace('./', '').replace('.vue', '')));
  
      const openPostInNewTab = (post) => {
        const postUrl = `${window.location.origin}/knowledge/${post}`;
        window.open(postUrl, '_blank');
      };
  
      const formatTitle = (post) => {
        return post.replace(/-/g, ' ').replace(/_/g, ' ').replace(/([A-Z])/g, ' $1').trim();
      };
  
      return {
        posts,
        openPostInNewTab,
        formatTitle,
      };
    },
  };
  </script>
  
  <style scoped>
  .dashboard-knowledge-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: #f9fafb;
  }
  
  /* Content Area */
  .content-area {
    flex-grow: 1;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  /* Banner Section */
  .knowledge-banner {
    width: 100%;
    height: 200px;
    background-image: url('@/assets/KnowledgeBackground.png');
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
  }
  
  .search-container {
    text-align: center;
  }
  
  .banner-title {
    color: white;
    font-size: 32px;
    margin-bottom: 20px;
  }
  
  .search-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  
  .search-input {
    width: 400px;
    border-radius: 4px;
    padding: 10px;
  }
  
  .search-button {
    padding: 10px 20px;
  }
  
  /* Help Desk Sections */
  .help-desk {
    display: flex;
    justify-content: center;
    align-items: stretch;
    gap: 20px;
    padding: 40px;
  }
  
  .help-desk-section {
    flex: 1;
    display: flex;
    justify-content: center;
  }
  
  .help-card {
    width: 100%;
    text-align: center;
    padding: 20px;
    transition: box-shadow 0.3s ease;
  }
  
  .help-card:hover {
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
  }
  
  .icon-container {
    margin-bottom: 10px;
  }
  
  .help-title {
    font-size: 18px;
    margin: 10px 0;
  }
  
  .help-description {
    font-size: 14px;
    color: #666;
  }
  
  /* Blog Posts Section */
  .blog-posts-section {
    padding: 40px;
    background-color: #ffffff;
  }
  
  .section-title {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .blog-list {
    list-style-type: none;
    padding: 0;
  }
  
  .blog-list li {
    cursor: pointer;
    color: #219f73;
    font-size: 1.2rem;
    margin-bottom: 10px;
    text-decoration: underline;
  }
  
  .blog-list li:hover {
    color: #176f55;
  }
  </style>
  