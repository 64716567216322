<template>
  <div class="container mx-auto p-6 bg-white rounded-lg shadow-md mt-6">
    <p v-if="recentUsage === null" class="text-gray-600">Loading...</p>
    <p v-else-if="recentUsage.length === 0" class="text-gray-600">No data available for this user.</p>

    <div v-else>
      <div class="header mb-4 flex items-center justify-between">
        <n-input
          v-model="searchQuery"
          placeholder="Search..."
          class="search-input"
        />
        <n-select
          v-model="sortOption"
          :options="sortOptions"
          class="sort-select"
        />
      </div>

      <!-- Data Table -->
      <n-data-table
        :columns="columns"
        :data="paginatedRecentUsage"
        :pagination="pagination"
        :bordered="true"
      >
        <template #body-cell-date="{ row }">
          {{ formatDate(row.date) }}
        </template>
        <template #body-cell-credits="{ row }">
          {{ row.credits_used }}
        </template>
        <template #body-cell-description="{ row }">
          {{ row.description }}
        </template>
      </n-data-table>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import { supabase } from '@/supabaseClient';
import { NInput, NSelect, NDataTable } from 'naive-ui'; // Removed NButton import

const columns = [
  { title: 'Date', key: 'date' },
  { title: 'Credits Used', key: 'credits_used' },
  { title: 'Description', key: 'description' },
];

export default {
  components: {
    NInput,
    NSelect,
    NDataTable, // Removed NButton from the registered components
  },
  setup() {
    const recentUsage = ref(null);
    const searchQuery = ref('');
    const sortOption = ref('date_desc');
    const currentPage = ref(1);
    const itemsPerPage = 10;

    const sortOptions = [
      { label: 'Date (Newest First)', value: 'date_desc' },
      { label: 'Date (Oldest First)', value: 'date_asc' },
      { label: 'Credits Used (High to Low)', value: 'credits_desc' },
      { label: 'Credits Used (Low to High)', value: 'credits_asc' },
    ];

    const fetchRecentUsage = async () => {
      try {
        const { data: sessionData, error: sessionError } = await supabase.auth.getSession();

        if (sessionError) {
          console.error('Error getting session:', sessionError.message);
          recentUsage.value = [];
          return;
        }

        const user = sessionData.session?.user;
        if (!user) {
          console.error('User not logged in');
          recentUsage.value = [];
          return;
        }

        let query = supabase
          .from('credit_usage')
          .select('*')
          .eq('user_id', user.id)
          .range((currentPage.value - 1) * itemsPerPage, currentPage.value * itemsPerPage - 1);

        if (sortOption.value.includes('date')) {
          query = query.order('date', { ascending: sortOption.value === 'date_asc' });
        } else {
          query = query.order('credits_used', { ascending: sortOption.value === 'credits_asc' });
        }

        const { data, error } = await query;
        if (error) {
          console.error('Error fetching recent usage:', error.message);
          recentUsage.value = [];
        } else {
          recentUsage.value = data || [];
        }
      } catch (error) {
        console.error('Unexpected error fetching recent usage:', error);
        recentUsage.value = [];
      }
    };

    const filteredRecentUsage = computed(() => {
      const query = searchQuery.value.toLowerCase();
      return recentUsage.value.filter(usage =>
        usage.description?.toLowerCase().includes(query)
      );
    });

    const paginatedRecentUsage = computed(() => {
      const start = (currentPage.value - 1) * itemsPerPage;
      return filteredRecentUsage.value.slice(start, start + itemsPerPage);
    });

    const hasNextPage = computed(() => {
      return filteredRecentUsage.value.length > currentPage.value * itemsPerPage;
    });

    const previousPage = () => {
      if (currentPage.value > 1) {
        currentPage.value -= 1;
        fetchRecentUsage();
      }
    };

    const nextPage = () => {
      if (hasNextPage.value) {
        currentPage.value += 1;
        fetchRecentUsage();
      }
    };

    const formatDate = (dateString) => {
      const date = new Date(dateString);
      return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      });
    };

    onMounted(() => {
      fetchRecentUsage();
    });

    return {
      recentUsage,
      searchQuery,
      sortOption,
      sortOptions,
      currentPage,
      itemsPerPage,
      filteredRecentUsage,
      paginatedRecentUsage,
      hasNextPage,
      previousPage,
      nextPage,
      formatDate,
      columns,
      pagination: { pageSize: itemsPerPage, current: currentPage },
    };
  },
};
</script>


<style scoped>
.container {
  max-width: 1000px;
  margin: auto;
  padding: 1.5rem;
  border: #cbd5e0 1px solid;
  background-color: #ffffff;
  margin-top: 1.5rem; /* Added top margin */
}

.header {
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search-input,
.sort-select {
  width: 200px;
}
</style>
