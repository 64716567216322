import { createApp } from 'vue';
import App from './App.vue';
import naive from 'naive-ui';
import './assets/styles.css';  // Import any global styles

import router from './router';
import { supabase } from './supabaseClient';

const app = createApp(App);

app.use(router);
app.use(naive);
app.config.globalProperties.$supabase = supabase;
app.mount('#app');
