<template>
  <div class="sign-up-wrapper">
    <div class="sign-up-container">
      <n-card class="sign-up-card">
        <div class="card-header">
          <a href="https://www.outria.com/">
          <img src="@/assets/OutriaLogo.png" alt="Logo" class="logo" />
        </a>
          <h1 class="title">Join Us Today!</h1>
        </div>

        <!-- Username Input -->
        <n-input
          v-model:value="username"
          placeholder="Username"
          class="input-field"
          :status="usernameError ? 'error' : ''"
          @blur="validateUsername"
        />
        <div v-if="usernameError" class="error-message">{{ usernameError }}</div>

        <!-- Email Input -->
        <n-input
          v-model:value="email"
          placeholder="Email address"
          class="input-field"
          :status="emailError ? 'error' : ''"
          @blur="validateEmail"
        />
        <div v-if="emailError" class="error-message">{{ emailError }}</div>

        <!-- Password Input -->
        <n-input
          v-model:value="password"
          placeholder="Password"
          type="password"
          class="input-field"
          :status="passwordError ? 'error' : ''"
          @blur="validatePassword"
        />
        <div v-if="passwordError" class="error-message">{{ passwordError }}</div>

        <!-- Confirm Password Input -->
        <n-input
          v-model:value="confirmPassword"
          placeholder="Confirm Password"
          type="password"
          class="input-field"
          :status="confirmPasswordError ? 'error' : ''"
          @blur="validateConfirmPassword"
        />
        <div v-if="confirmPasswordError" class="error-message">{{ confirmPasswordError }}</div>

        <!-- Sign Up Button -->
        <n-button @click="signUp" class="submit-button">Sign Up</n-button>

        <!-- OR Separator -->
        <div class="or-separator">
          <span>OR</span>
        </div>

        <!-- Google Sign-Up Button -->
        <n-button class="oauth-button google-button" @click="handleGoogleSignUp">
          <img src="https://developers.google.com/identity/images/g-logo.png" class="oauth-logo" alt="Google logo" />
          <span>Continue with Google</span>
        </n-button>

        <!-- LinkedIn Sign-Up Button -->
        <n-button class="oauth-button linkedin-button" @click="handleLinkedInSignUp">
          <img src="https://upload.wikimedia.org/wikipedia/commons/c/ca/LinkedIn_logo_initials.png" class="oauth-logo" alt="LinkedIn logo" />
          <span>Continue with LinkedIn</span>
        </n-button>

        <!-- Already Have an Account Link -->
        <div class="log-in-link">
          <span>Already have an account? </span>
          <a href="/signin">Log in</a>
        </div>

      </n-card>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue';
import { NButton, NInput, NCard, useMessage } from 'naive-ui';
import { supabase } from '@/supabaseClient';

export default defineComponent({
  components: {
    NButton,
    NInput,
    NCard,
  },
  setup() {
    const message = useMessage();
    const username = ref('');
    const email = ref('');
    const password = ref('');
    const confirmPassword = ref('');

    const usernameError = ref('');
    const emailError = ref('');
    const passwordError = ref('');
    const confirmPasswordError = ref('');

    const validateUsername = () => {
      usernameError.value = username.value ? '' : 'Username is required';
    };

    const validateEmail = () => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      emailError.value = emailRegex.test(email.value) ? '' : 'Invalid email address';
    };

    const validatePassword = () => {
      passwordError.value = password.value.length >= 6 ? '' : 'Password must be at least 6 characters';
    };

    const validateConfirmPassword = () => {
      confirmPasswordError.value = confirmPassword.value === password.value ? '' : 'Passwords do not match';
    };

    const signUp = async () => {
      validateUsername();
      validateEmail();
      validatePassword();
      validateConfirmPassword();

      if (!usernameError.value && !emailError.value && !passwordError.value && !confirmPasswordError.value) {
        try {
          const { data, error } = await supabase.auth.signUp({
            email: email.value,
            password: password.value,
            options: { data: { username: username.value } },
          });

          if (error) {
            message.error('Sign-up error: ' + error.message);
            return;
          }

          const user = data.user;
          if (user) {
            message.success('User registered successfully.');
          }
        } catch (error) {
          message.error('Error signing up: ' + error.message);
        }
      }
    };

    const handleGoogleSignUp = async () => {
      try {
        const { error } = await supabase.auth.signInWithOAuth({
          provider: 'google',
        });

        if (error) {
          message.error('Google Sign-Up error: ' + error.message);
        }
      } catch (error) {
        message.error('An unexpected error occurred during Google sign-up');
      }
    };

    const handleLinkedInSignUp = async () => {
      try {
        const { error } = await supabase.auth.signInWithOAuth({
          provider: 'linkedin',
        });

        if (error) {
          message.error('LinkedIn Sign-Up error: ' + error.message);
        }
      } catch (error) {
        message.error('An unexpected error occurred during LinkedIn sign-up');
      }
    };

    return {
      username,
      email,
      password,
      confirmPassword,
      usernameError,
      emailError,
      passwordError,
      confirmPasswordError,
      validateUsername,
      validateEmail,
      validatePassword,
      validateConfirmPassword,
      signUp,
      handleGoogleSignUp,
      handleLinkedInSignUp,
    };
  },
});
</script>

<style scoped>
.sign-up-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #ffffff;
}

.sign-up-container {
  width: 100%;
  max-width: 400px;
}

.sign-up-card {
  padding: 40px 20px;
  background-color: #ffffff;
  
  border: 1px solid #cbd5e0;
}

.card-header {
  text-align: center;
  margin-bottom: 20px;
}

.logo {
  height: 50px;
  margin-bottom: 20px;
}

.title {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 20px;
  color: #333333;
}

.input-field {
  margin-bottom: 10px;
}

.submit-button {
  width: 100%;
  background-color: #3ecda0;
  color: white;
}

.or-separator {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  font-size: 12px;
  color: #999999;
}

.or-separator:before,
.or-separator:after {
  content: '';
  flex: 1;
  height: 1px;
  background: #e0e0e0;
  margin: 0 10px;
}

.oauth-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  border: 1px solid #e0e0e0;
  background-color: #f5f5f5;
  color: #333333;
  font-size: 14px;
  height: 40px;
}

.oauth-logo {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}

.error-message {
  color: red;
  font-size: 12px;
  margin-bottom: 10px;
}

.log-in-link {
  margin-top: 20px;
  text-align: center;
  font-size: 14px;
}

.log-in-link a {
  color: #129b59;
  font-weight: bold;
  text-decoration: none;
}
</style>
