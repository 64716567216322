<template>
  <div class="help-container" v-click-outside="closeHelp">
    <n-icon size="24" class="navbar-icon" @click="toggleHelp">
      <i class="material-icons-outlined">help_outline</i>
    </n-icon>
    <transition name="fade">
      <n-card v-if="showHelp" class="help-card">
        <n-space vertical class="help-space">
          <!-- Text at the top -->
          <p class="help-text">Need help? Visit our docs or contact support for assistance.</p>
          <div class="help-item" @click="navigateTo('knowledge')">
            <n-icon size="18" class="help-icon">
              <i class="material-icons">lightbulb_outline</i>
            </n-icon>
            <span>Knowledge</span>
          </div>
          <div class="help-item" @click="navigateTo('docs')">
            <n-icon size="18" class="help-icon">
              <i class="material-icons">description</i>
            </n-icon>
            <span>Docs</span>
          </div>
        </n-space>
      </n-card>
    </transition>
  </div>
</template>

<script>
import { ref } from 'vue';
import { NIcon, NCard, NSpace } from 'naive-ui';
import clickOutside from '@/directives/clickOutside';

export default {
  name: 'HelpButton',
  components: {
    NIcon,
    NCard,
    NSpace,
  },
  directives: {
    clickOutside,
  },
  setup() {
    const showHelp = ref(false);

    const toggleHelp = () => {
      showHelp.value = !showHelp.value;
    };

    const closeHelp = () => {
      showHelp.value = false;
    };

    const navigateTo = (section) => {
      console.log(`Navigating to ${section}`);
    };

    return {
      showHelp,
      toggleHelp,
      closeHelp,
      navigateTo,
    };
  },
};
</script>

<style scoped>
.help-container {
  position: relative;
}

.help-card {
  position: absolute;
  top: 100%;
  right: 0;
  width: 250px;
  z-index: 1001;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 0; /* Removed padding around the card */
  overflow: hidden;
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.help-space {
  padding: 0; /* Removed padding inside the space */
}

.help-text {
  font-size: 13px;
  margin: 4px 8px; /* Reduced margin to control spacing */
  color: #626262;
}

.help-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 4px 8px; /* Minimal padding for compact look */
  transition: background-color 0.3s;
}

.help-item:hover {
  background-color: #f0f0f0;
}

.help-icon {
  margin-right: 8px;
  display: flex;
  align-items: center;
}

.navbar-icon {
  cursor: pointer;
}
</style>
