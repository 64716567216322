<template>
    <div class="image-component">
      <!-- Image Preview -->
      <div v-if="localImageUrl" class="image-preview">
        <img :src="localImageUrl" alt="Uploaded Image" />
      </div>
      <!-- Image Upload -->
      <input type="file" @change="onFileChange" />
    </div>
  </template>
  
  <script>
  export default {
    name: 'ImageComponent',
    props: {
      imageUrl: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        localImageUrl: this.imageUrl, // Local copy of the imageUrl prop
      };
    },
    methods: {
      onFileChange(event) {
        const file = event.target.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onload = (e) => {
            this.localImageUrl = e.target.result;
            // Emit the updated image URL to the parent component
            this.$emit('update:imageUrl', this.localImageUrl);
          };
          reader.readAsDataURL(file);
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .image-component {
    padding: 10px;
    border: 1px solid #ccc;
  }
  .image-preview img {
    max-width: 100%;
    height: auto;
  }
  </style>
  