<template>
    <div class="feature-container">
      <div class="text-section">
        <h1 class="feature-title">Find Employee Information Instantly</h1>
        <n-divider />
        <p class="feature-description">
          Easily search and locate employee contact information with our intuitive and powerful search tool. Whether you need to connect with someone from a specific department or find a colleague's phone number, we've got you covered.
        </p>
      </div>
      <div class="image-section">
        <img src="@/assets/FindEmployeeContact.png" alt="Find Employee Contact" class="feature-image">
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "FeatureSection",
  };
  </script>
  
  <style scoped>
  .feature-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px 20px;
    max-width: 1200px;
    margin: auto;
    flex-wrap: wrap;
  }
  
  .text-section {
    flex: 1;
    max-width: 50%;
    padding-right: 20px;
  }
  
  .feature-title {
    font-size: 2.5em;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .feature-description {
    font-size: 1.2em;
    color: #555;
  }
  
  .image-section {
    flex: 1;
    max-width: 50%;
    text-align: right;
  }
  
  .feature-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  </style>
  