<template>
  <div class="features-slider-section" @mouseenter="pauseSlider" @mouseleave="startSlider">
    <div class="slider-container" ref="sliderContainer">
      <div class="slides-wrapper" ref="slidesWrapper">
        <div class="slide" v-for="(feature, index) in features" :key="index">
          <div class="feature-card">
            <img :src="feature.icon" alt="Feature Icon" class="feature-icon" />
            <div class="text-content">
              <h3>{{ feature.title }}</h3>
              <p class="description">{{ feature.description }}</p>
              <p class="extra-description">{{ feature.extraDescription }}</p>
            </div>
          </div>
        </div>
        <!-- Duplicate the slides for a smooth loop -->
        <div class="slide" v-for="(feature, index) in features" :key="'duplicate-' + index">
          <div class="feature-card">
            <img :src="feature.icon" alt="Feature Icon" class="feature-icon" />
            <div class="text-content">
              <h3>{{ feature.title }}</h3>
              <p class="description">{{ feature.description }}</p>
              <p class="extra-description">{{ feature.extraDescription }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import SearchC from '@/assets/searchc.png';
import id from '@/assets/id.png';
import similar from '@/assets/similar.png';
import server from '@/assets/server.png';


export default {
  name: "FeaturesSlider",
  data() {
    return {
      features: [
        { 
          title: "Find Companies", 
          description: "Find companies matching your criteria.", 
          extraDescription: "Search by industry, location, website metrics, technology and more! Best used for Outreach purposes.", 
          icon: SearchC
        },
        { 
          title: "Find Decision Makers", 
          description: "We have multiple ways you can find information about an employee.", 
          extraDescription: "Pay only for information we found, you have nothing to lose and a lot of time to save.", 
          icon: id
        },
        { 
          title: "Query based on similarities", 
          description: "Already have your target audience?", 
          extraDescription: "Build your own list and get recommendations based on patterns like similar industries, technologies, and much more!", 
          icon: similar
        },
        { 
          title: "Data Relativity", 
          description: "Our data is updated every day.", 
          extraDescription: "New key points and query angles are added on monthly basis, ensuring fresh and high quality data.", 
          icon: server
        },
      ],
      currentPosition: 0,
      sliderWidth: 0,
      animationFrameId: null,
    };
  },
  mounted() {
    this.calculateWidth();
    this.startSlider();
    window.addEventListener('resize', this.calculateWidth);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.calculateWidth);
    this.pauseSlider(); // Ensure the animation stops when the component is destroyed
  },
  methods: {
    calculateWidth() {
      const slider = this.$refs.slidesWrapper;
      this.sliderWidth = slider.scrollWidth / 2;
    },
    startSlider() {
      const moveSlider = () => {
        this.currentPosition += 0.5; // Adjust this value to control the speed
        if (this.currentPosition >= this.sliderWidth) {
          this.currentPosition = 0; // Reset position for smooth looping
        }
        this.$refs.sliderContainer.style.transform = `translateX(-${this.currentPosition}px)`;
        this.animationFrameId = requestAnimationFrame(moveSlider);
      };
      this.animationFrameId = requestAnimationFrame(moveSlider);
    },
    pauseSlider() {
      if (this.animationFrameId) {
        cancelAnimationFrame(this.animationFrameId);
        this.animationFrameId = null;
      }
    },
  },
};
</script>

<style scoped>
.features-slider-section {
  width: 100%;
  background-color: #f3f3f3; /* Background color matching your app */
  padding: 20px 0; /* Narrower padding */
  border-top: 1px solid #219f73; /* Green top border */
  border-bottom: 1px solid #219f73; /* Green bottom border */
  position: relative;
  overflow: hidden;
  font-family: 'Roboto', sans-serif; /* Matching your app's font */
}

.slider-container {
  display: flex;
  width: max-content; /* Allow the slider to be as wide as necessary */
  white-space: nowrap; /* Prevent line breaks */
  will-change: transform;
}

.slides-wrapper {
  display: flex;
  padding: 0 20px;
}

.slide {
  flex: 0 0 auto;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start; /* Align items to the top */
  margin-right: 20px; /* Adjusted margin to make it narrower */
}

.feature-card {
  background-color: #ffffff;
  border: 1px solid #ddd; /* 1px border without radius */
  padding: 10px; /* Reduced padding */
  display: flex;
  align-items: flex-start; /* Align items to the top */
  width: 350px; /* Made the cards narrower */
  box-sizing: border-box;
  color: #333; /* Text color matching your app */
  font-family: Arial, sans-serif; /* Use your app's font */
  white-space: normal; /* Ensure text wraps within the card */
}

.feature-icon {
  width: 40px; /* Adjusted size */
  height: 40px; /* Adjusted size */
  margin-right: 10px; /* Reduced margin */
  margin-top: 5px; /* Optional: Add some space to the top */
  align-self: flex-start; /* Align the icon to the top */
}

.text-content {
  flex: 1;
  overflow-wrap: break-word; /* Allow long words to wrap */
}

.feature-card h3 {
  margin: 0 0 5px;
  font-size: 1rem; /* Slightly smaller font */
  font-weight: bold;
}

.feature-card .description,
.feature-card .extra-description {
  margin: 0;
  font-size: 0.8rem; /* Smaller font size for descriptions */
  color: #666;
}

.extra-description {
  font-size: 0.7rem; /* Smaller font for extra description */
  color: #888;
  margin-top: 5px;
}
</style>
