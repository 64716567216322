<template>
    <div class="table-editor-page">
      <div class="sidebar">
        <div class="menu-section">
          <h3 class="menu-section-title">Manage Tables</h3>
          <n-button block text @click="openCreateDrawer" class="menu-item">
            Create Table
          </n-button>
          <n-divider class="divider" />
          <div v-for="folder in folders" :key="folder.id" class="menu-item" @click="selectFolder(folder)">
            <n-button block text class="folder-item">{{ folder.name }}</n-button>
          </div>
        </div>
      </div>
      <div class="content">
        <TableDisplay :folder="selectedFolder" />
      </div>
  
      <!-- Create Table Drawer -->
      <n-drawer v-model:show="showCreateDrawer" width="350px" placement="right">
        <n-drawer-content title="Create New Table">
          <n-form ref="createFormRef">
            <n-form-item label="Table Name" path="name">
              <n-input v-model="newFolder.name" placeholder="Enter table name" />
            </n-form-item>
            <n-form-item label="Description" path="description">
              <n-input v-model="newFolder.description" placeholder="Enter table description" />
            </n-form-item>
            <n-form-item label="Type" path="type">
              <n-select v-model="newFolder.type" :options="tableTypes" placeholder="Select table type" />
            </n-form-item>
            <n-space justify="end">
              <n-button @click="showCreateDrawer = false">Cancel</n-button>
              <n-button type="primary" @click="submitCreateTable">Create</n-button>
            </n-space>
          </n-form>
        </n-drawer-content>
      </n-drawer>
    </div>
  </template>
  
  <script>
  import { defineComponent, ref } from 'vue';
  import { NButton, NDivider, NDrawer, NDrawerContent, NForm, NFormItem, NInput, NSelect, NSpace } from 'naive-ui';
  import TableDisplay from '@/components/TableDisplay.vue';
  import { supabase } from '@/supabaseClient';
  
  export default defineComponent({
    name: 'TableEditor',
    components: {
      NButton,
      NDivider,
      NDrawer,
      NDrawerContent,
      NForm,
      NFormItem,
      NInput,
      NSelect,
      NSpace,
      TableDisplay,
    },
    setup() {
      const folders = ref([]);
      const selectedFolder = ref(null);
      const showCreateDrawer = ref(false);
      const newFolder = ref({
        name: '',
        description: '',
        type: '',
      });
  
      const tableTypes = ref([
        { label: 'Type A', value: 'type_a' },
        { label: 'Type B', value: 'type_b' },
        // Add more types as needed
      ]);
  
      const fetchFolders = async () => {
        try {
          const { data: foldersData, error } = await supabase
            .from('folders')
            .select('*');
  
          if (error) {
            console.error('Error fetching folders:', error);
            return;
          }
  
          folders.value = foldersData;
        } catch (error) {
          console.error('Unexpected error:', error);
        }
      };
  
      const openCreateDrawer = () => {
        showCreateDrawer.value = true;
      };
  
      const submitCreateTable = async () => {
        if (!newFolder.value.name) {
          alert('Please enter a table name');
          return;
        }
  
        try {
          const { data, error } = await supabase
            .from('folders')
            .insert([newFolder.value]);
  
          if (error) {
            console.error('Error creating new table:', error);
            return;
          }
  
          folders.value.push(data[0]); // Add the new folder to the list
          showCreateDrawer.value = false; // Close the drawer
          newFolder.value = { name: '', description: '', type: '' }; // Reset form
        } catch (error) {
          console.error('Unexpected error:', error);
        }
      };
  
      const selectFolder = (folder) => {
        selectedFolder.value = folder;
      };
  
      fetchFolders(); // Fetch folders on component mount
  
      return {
        folders,
        selectedFolder,
        showCreateDrawer,
        newFolder,
        tableTypes,
        openCreateDrawer,
        submitCreateTable,
        selectFolder,
      };
    },
  });
  </script>
  
  <style scoped>
  .table-editor-page {
    display: flex;
    min-height: 100vh;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  .sidebar {
    width: 220px;
    background-color: white;
    border-right: 1px solid #cbd5e0;
    padding-top: 20px;
    position: fixed;
    top: 50px;
    bottom: 0;
    left: 60px;
    box-sizing: border-box;
    overflow-y: auto;
  }
  
  .menu-section {
    margin-bottom: 20px;
  }
  
  .menu-section-title {
    font-size: 12px;
    font-weight: 600;
    color: #6b7280;
    margin: 0 20px 10px 20px;
    text-transform: uppercase;
  }
  
  .menu-item {
    text-align: left;
    padding: 8px 20px;
    color: #1f2937;
    font-size: 14px;
    font-weight: 400;
    border-radius: 4px;
    margin: 2px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .menu-item-text {
    flex-grow: 1;
  }
  
  .menu-item:hover {
    background-color: #f3f4f6;
  }
  
  .menu-item.active {
    background-color: #f9fafb;
    font-weight: 600;
  }
  
  .divider {
    margin: 20px 0;
    border-color: #cbd5e0;
  }

  .content {
    flex-grow: 1;
    padding: 20px;
    margin-left: 220px;
    box-sizing: border-box;
    overflow-y: auto;
    min-height: calc(100vh - 50px);
  }
  </style>
  