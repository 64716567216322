import { createRouter, createWebHistory } from 'vue-router';
import { supabase } from '@/supabaseClient';

// Import views
import HomePage from '@/views/HomePage.vue';
import SignUp from '@/views/SignUp.vue';
import SignIn from '@/views/SignIn.vue';
import UserDashboard from '@/views/UserDashboard.vue';
import SelectOrganizationPage from '@/views/OrganizationSelection.vue';
import CreateOrganizationPage from '@/views/CreateOrganizationPage.vue';
import Emails from '@/views/EmailsPage.vue';
import KnowledgePage from '@/views/KnowledgePage.vue'; // Homepage Knowledge
import DashboardKnowledgePage from '@/views/DashboardKnowledgePage.vue'; // Dashboard Knowledge
import WorkflowSearch from '@/views/WorkflowSearch.vue';
import WorkflowEnrich from '@/views/WorkflowEnrich.vue';
import WorkflowEmployees from '@/views/WorkflowEmployees.vue';
import SettingsPage from '@/views/SettingsPage.vue';
import DashboardHome from '@/views/DashboardHome.vue';
import UserProfile from '@/views/UserProfile.vue';
import ProjectsManager from '@/views/ProjectsManager.vue';
import ServerSelect from '@/components/ServerSelect.vue';
import TableEditor from '@/views/TableEditor.vue';
import OrganisationsManager from '@/views/OrganisationsManager.vue';
import DocsPage from '@/views/DocsPage.vue';
import WorkflowSelect from '@/views/WorkflowSelect.vue';
import DatabaseView from '@/views/DatabaseView.vue';
import DocumentationPage from '@/views/DocumentationPage.vue';
import BlogPostLayout from '@/views/BlogPostLayout.vue';
import CompanyPage from '@/views/CompanyPage.vue';
import PublicDirectory from '@/views/PublicDirectory.vue';
import OnDemandCrawl from '@/views/OnDemandCrawl.vue';
import EmailGenerator from '@/views/EmailGenerator.vue';
import BlogPost from '@/posts/AproachClient.vue';
import Create from '@/views/NoteBoard.vue';


// Route guard function
const isAuthenticated = async () => {
  try {
    const { data: { session }, error } = await supabase.auth.getSession();
    if (error) {
      console.error('Error checking session:', error.message);
      return false;
    }
    return !!session;
  } catch (error) {
    console.error('Unexpected error:', error.message);
    return false;
  }
};

// Route guard for protected routes
const requireAuth = async (to, from, next) => {
  const auth = await isAuthenticated();
  if (auth) {
    const selectedOrg = sessionStorage.getItem('selectedOrgId');
    if (!selectedOrg) {
      next('/select-organization'); // Redirect to organization selection if no organization is selected
    } else {
      next();
    }
  } else {
    next('/signin'); // Redirect to sign-in page if not authenticated
  }
};

const routes = [
 
  { path: '/', name: 'Home', component: HomePage },
  { path: '/public-directory', name: 'PublicDirectory', component: PublicDirectory },
  { path: '/documentation', name: 'Documentation', component: DocumentationPage },
  { path: '/knowledge', name: 'Knowledge', component: KnowledgePage }, // Homepage Knowledge
  { path: '/knowledge/:postName', name: 'BlogPost', component: BlogPostLayout },
  { path: '/signup', name: 'SignUp', component: SignUp },
  { path: '/signin', name: 'SignIn', component: SignIn },
  { path: '/select-organization', name: 'SelectOrganization', component: SelectOrganizationPage },
  { path: '/create-organization', name: 'CreateOrganization', component: CreateOrganizationPage },
  { path: '/companies/:name', name: 'CompanyPage', component: CompanyPage }, // Company Page
  { path: '/on-demand-crawl', name: 'OnDemandCrawl', component: OnDemandCrawl }, // OnDemandCrawl
  { path: '/email-generator', name: 'EmailGenerator', component: EmailGenerator},
  { path: '/blog/:postName', name: 'BlogPost', component: BlogPost},
  {
    path: '/dashboard',
    name: 'UserDashboard',
    component: UserDashboard,
    beforeEnter: requireAuth,
    children: [
      { path: '', name: 'DashboardHome', component: DashboardHome },
      { path: '/emails', name: 'Emails', component: Emails },
      { path: '/dashboard/knowledge', name: 'DashboardKnowledge', component: DashboardKnowledgePage }, // Dashboard Knowledge
      { path: '/search', name: 'Search', component: WorkflowSearch },
      { path: '/create', name: 'Create', component: Create },
      { path: '/enrich', name: 'Enrich', component: WorkflowEnrich },
      { path: '/employees', name: 'Employees', component: WorkflowEmployees },
      { path: '/table-editor', name: 'TableEditor', component: TableEditor },
      { path: '/workflow', name: 'WorkflowSelect', component: WorkflowSelect },
      { path: '/docs', name: 'DocsPage', component: DocsPage },
      { path: '/database', name: 'Database', component: DatabaseView },
    
      {
        path: '/settings',
        component: SettingsPage,
        children: [
          { path: '', name: 'UserProfile', component: UserProfile },
          { path: '/notifications', name: 'NotificationPreferences', component: UserProfile },
          { path: '/account', name: 'AccountSettings', component: UserProfile },
          { path: '/organizations', name: 'Organizations', component: OrganisationsManager },
          { path: '/projects', name: 'Projects', component: ProjectsManager },
          { path: '/server', name: 'ServerSelect', component: ServerSelect },
        ],
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
