<template>
  <div class="email-generator-page">
    <NavBar />

    <div class="content-wrapper">
      <!-- Main Email Generator Section -->
      <div class="email-generator-form">
        <h1>AI-Powered Email Generator</h1>
        <form @submit.prevent="generateEmail">
          <div class="form-group">
            <label for="recipient">Recipient's Name:</label>
            <input type="text" v-model="recipient" placeholder="Enter recipient name" required />
          </div>

          <div class="form-group">
            <label for="emailPurpose">Purpose of Email:</label>
            <textarea v-model="emailPurpose" placeholder="Describe the purpose of the email" required></textarea>
          </div>

          <div class="form-group">
            <label for="companyInfo">Company Info (Optional):</label>
            <input type="text" v-model="companyInfo" placeholder="Enter recipient's company name" />
          </div>

          <div class="form-group">
            <label for="product">Product/Service (Optional):</label>
            <input type="text" v-model="product" placeholder="Enter product or service name" />
          </div>

          <div class="form-group">
            <label for="personalization">Additional Personalization:</label>
            <textarea v-model="personalization" placeholder="Add more details to personalize the email"></textarea>
          </div>

          <button type="submit" class="btn generate-btn">Generate Email</button>
        </form>

        <div v-if="loading" class="loading">
          <p>Generating email with AI...</p>
        </div>

        <div v-if="generatedEmail" class="generated-email">
          <h2>Generated Email:</h2>
          <p class="email-output">{{ displayedEmail }}</p>
          <div class="actions">
            <button @click="regenerateEmail" class="btn regenerate-btn">Regenerate Email</button>
            <button @click="copyToClipboard" class="btn copy-btn">
              <i class="fas fa-copy"></i> Copy to Clipboard
            </button>
          </div>
        </div>
      </div>
    </div>

    <FooterSection />
  </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue';
import FooterSection from '@/components/FooterSection.vue';

export default {
  name: 'EmailGenerator',
  components: {
    NavBar,
    FooterSection,
  },
  data() {
    return {
      recipient: '',
      emailPurpose: '',
      companyInfo: '',
      product: '',
      personalization: '',
      generatedEmail: '',
      displayedEmail: '', // To handle the typewriter effect
      loading: false,
      typeSpeed: 30, // Speed of typing animation
    };
  },
  methods: {
    async generateEmail() {
      this.loading = true;
      this.generatedEmail = '';
      this.displayedEmail = ''; // Reset for typewriter effect

      try {
        const response = await fetch('/api/generate-email', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            recipient: this.recipient,
            emailPurpose: this.emailPurpose,
            companyInfo: this.companyInfo,
            product: this.product,
            personalization: this.personalization,
          }),
        });

        if (response.ok) {
          const data = await response.json();
          if (data.email) {
            this.generatedEmail = data.email;
            this.typeEmail();
          }
        } else {
          const errorData = await response.json();
          alert(`Failed to generate email: ${errorData.error}.`);
        }
      } catch (error) {
        alert('Failed to generate email. Please try again.');
      } finally {
        this.loading = false;
      }
    },
    typeEmail() {
      let i = 0;
      const email = this.generatedEmail;
      const speed = this.typeSpeed;
      const typeWriter = () => {
        if (i < email.length) {
          this.displayedEmail += email.charAt(i);
          i++;
          setTimeout(typeWriter, speed);
        }
      };
      typeWriter();
    },
    regenerateEmail() {
      this.generateEmail();
    },
    copyToClipboard() {
      const el = document.createElement('textarea');
      el.value = this.generatedEmail;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      alert('Email copied to clipboard!');
    },
  },
};
</script>

<style scoped>
/* Main layout and content styling */
.email-generator-page {
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

.content-wrapper {
  display: flex;
  justify-content: center;
  padding: 0;
  width: 100%;
  max-width: 100%;
  margin: 0;
}

.email-generator-form {
  flex-grow: 1;
  padding: 20px;
  background-color: #ffffff;
  border-left: #cbd5e0 1px solid;
  border-right: #cbd5e0 1px solid;
  margin: 0 10px;
  min-height: calc(100vh - 160px);
  display: flex;
  flex-direction: column;
  max-width: 600px;
}

h1 {
  text-align: center;
  color: #129b59;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 1.5rem;
}

label {
  display: block;
  margin-bottom: 0.5rem;
  color: black;
}

input,
textarea {
  width: 100%;
  padding: 12px;
  border: #cbd5e0 1px solid;
  font-size: 1rem;
  color: black;
  box-sizing: border-box;
}

textarea {
  min-height: 100px;
  resize: vertical;
}

button.generate-btn {
  background-color: #129b59;
  color: white;
  padding: 12px 20px;
  border: none;
  cursor: pointer;
  display: block;
  width: 100%;
  margin-top: 20px;
  text-align: center;
}

button.generate-btn:hover {
  background-color: #0e7d47;
}

/* Generated Email Styling */
.generated-email {
  margin-top: 20px;
  padding: 20px;
  background-color: #f9f9f9;
  border: #cbd5e0 1px solid;
  white-space: pre-wrap;
  flex-grow: 1;
}

.email-output {
  font-family: 'Courier New', Courier, monospace;
  font-size: 1rem;
}

.loading {
  text-align: center;
  margin-top: 20px;
  color: black;
}

/* Action Buttons */
.actions {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.regenerate-btn {
  background-color: #ff9900;
  color: white;
  padding: 10px 15px;
  border: none;
  cursor: pointer;
  font-size: 0.9rem;
}

.copy-btn {
  background-color: #129b59;
  color: white;
  padding: 10px 15px;
  border: none;
  cursor: pointer;
  font-size: 0.9rem;
}

.copy-btn i {
  margin-right: 5px;
}

/* Smooth Transitions */
.email-output,
.actions {
  transition: all 0.3s ease;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
