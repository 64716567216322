<template>
    <div class="workflow-select-page">
      <div class="sidebar">
        <div class="menu-section">
          <h3 class="menu-section-title">Search</h3>
          <n-button block text @click="navigateTo('SearchCompanies')" class="menu-item">
            Companies
          </n-button>
          <n-button block text @click="navigateTo('SearchEmployees')" class="menu-item">
            Employees
          </n-button>
                 <n-divider class="divider" />
        </div>
  
        <div class="menu-section">
          <h3 class="menu-section-title">Enrich</h3>
          <n-button block text @click="navigateTo('EnrichCompanies')" class="menu-item">
            Companies
          </n-button>
          <n-button block text @click="navigateTo('EnrichEmployees')" class="menu-item">
            Employees
          </n-button>
        </div>

                 <n-divider class="divider" />

        <div class="menu-section">
          <h3 class="menu-section-title">Our Data</h3>
          <n-button block text @click="navigateTo('SearchIndustries')" class="menu-item">
            <span class="menu-item-text">Search Industries</span>
            <span class="free-badge">FREE</span>
          </n-button>
          <n-button block text @click="navigateTo('CompaniesCount')" class="menu-item">
            <span class="menu-item-text">Companies Count</span>
            <span class="free-badge">FREE</span>
          </n-button>
        </div>
      </div>
  
      <div class="content">
        <router-view />
      </div>
    </div>
  </template>
  
  <script>
  import { defineComponent } from 'vue';
  import { useRouter } from 'vue-router';
  import { NButton, NDivider } from 'naive-ui';
  
  export default defineComponent({
    name: 'WorkflowSelectPage',
    components: {
      NButton,
      NDivider,
    },
    setup() {
      const router = useRouter();
  
      const navigateTo = (viewName) => {
        router.push({ name: viewName });
      };
  
      return {
        navigateTo,
      };
    },
  });
  </script>
  
  <style scoped>
  .workflow-select-page {
    display: flex;
    min-height: 100vh; /* Ensure the container takes at least full viewport height */
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  .sidebar {
    width: 220px;
    background-color: white;
    border-right: 1px solid #cbd5e0;
    padding-top: 20px;
    position: fixed;
    top: 50px;
    bottom: 0;
    left: 60px;
    box-sizing: border-box;
    overflow-y: auto;
  }
  
  .menu-section {
    margin-bottom: 20px;
  }
  
  .menu-section-title {
    font-size: 12px;
    font-weight: 600;
    color: #6b7280;
    margin: 0 20px 10px 20px;
    text-transform: uppercase;
  }
  
  .menu-item {
    text-align: left;
    padding: 8px 20px;
    color: #1f2937;
    font-size: 14px;
    font-weight: 400;
    border-radius: 4px;
    margin: 2px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .menu-item-text {
    flex-grow: 1;
  }
  
  .menu-item:hover {
    background-color: #f3f4f6;
  }
  
  .menu-item.active {
    background-color: #f9fafb;
    font-weight: 600;
  }
  
  .divider {
    margin: 20px 0;
    border-color: #cbd5e0;
  }
  
  .free-badge {
    background-color: #def7ec;
    color: #065f46;
    font-size: 10px;
    font-weight: 600;
    padding: 2px 4px;
    border-radius: 4px;
    margin-left: 8px;
    white-space: nowrap;
  }
  
  .content {
    flex-grow: 1;
    padding: 20px;
    margin-left: 220px; /* Align with sidebar width */
    box-sizing: border-box;
    overflow-y: auto; /* Allow scrolling for content if needed */
    min-height: calc(100vh - 50px); /* Ensure it takes the available viewport height minus the navbar height */
  }
  </style>
  