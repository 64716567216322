<template>
    <div class="components-wrapper">
      <h3 class="section-title">Components</h3>
      <hr class="divider" />
      <div class="menu grid-container">
        <div
          v-for="component in availableComponents"
          :key="component.id"
          class="menu-item"
          @dragstart="(event) => startDraggingComponent(component, event)"
          draggable="true"
        >
          <span class="drag-handle">⋮⋮⋮</span>
          <span class="icon material-icons">{{ component.icon }}</span>
          <span class="label">{{ component.label }}</span>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'EmailComponents',
    setup() {
      const availableComponents = [
        { id: 'text', label: 'Text', icon: 'text_fields', component: 'TextComponent' },
        { id: 'image', label: 'Image', icon: 'image', component: 'ImageComponent' },
        // Add more components as needed
      ];
  
      const startDraggingComponent = (component, event) => {
        event.dataTransfer.setData('componentType', component.component); // Set the component type for dropping
      };
  
      return {
        availableComponents,
        startDraggingComponent,
      };
    },
  };
  </script>
  
  <style scoped>
  .components-wrapper {
    padding: 16px;
  }
  
  .section-title {
    font-weight: bold;
    margin-bottom: 8px;
    color: #333;
  }
  
  .divider {
    margin: 8px 0;
    border: 0;
    border-top: 1px solid #ccc;
  }
  
  .grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 8px;
  }
  
  .menu-item {
    background-color: #fff;
    padding: 12px;
    border: 1px solid #e0e0e0;
    text-align: center;
    cursor: grab;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 0px;
    position: relative;
  }
  
  .drag-handle {
    position: absolute;
    top: 4px;
    left: 4px;
    font-size: 16px;
    color: #aaa;
  }
  
  .icon {
    font-size: 22px;
  }
  
  .label {
    font-size: 10px;
    color: #555;
    margin-top: 8px;
  }
  </style>
  