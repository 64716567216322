<template>
  <div class="organizations-manager">
    <!-- Header Section -->
    <header class="header">
      <n-space align ="center">
        <n-button type="success" @click="createNewOrganization">New Organization</n-button>
        <n-input v-model="searchQuery" placeholder="Search for an organization" size="large" clearable>
          <template #suffix>
            <n-icon size="20">
              <i class="material-icons">search</i>
            </n-icon>
          </template>
        </n-input>
      </n-space>
    </header>

    <!-- Organizations List Section -->
    <div class="organizations-list">
      <h2>Organizations</h2>
      <div v-for="organization in filteredOrganizations" :key="organization.id" class="organization-card" @click="selectOrganization(organization)">
        <n-card>
          <div class="organization-info">
            <h3>{{ organization.name }}</h3>
            <p>{{ organization.description }}</p>
          </div>
          <n-icon size="20">
            <i class="material-icons">chevron_right</i>
          </n-icon>
        </n-card>
      </div>
    </div>

    <!-- Organization Details Section -->
    <div v-if="selectedOrganization" class="organization-details">
      <n-card title="Manage Organization" bordered>
        <n-space vertical>
          <n-form ref="formRef" label-placement="top">
            <n-form-item label="Organization Name">
              <n-input v-model="organizationForm.name" placeholder="Enter organization name" />
            </n-form-item>
            <n-form-item label="Description">
              <n-input v-model="organizationForm.description" placeholder="Enter description" type="textarea" />
            </n-form-item>
          </n-form>
          <n-button type="primary" @click="saveOrganization">Save Changes</n-button>
        </n-space>
      </n-card>

      <!-- Members Section -->
      <n-card title="Members" bordered>
        <n-table :columns="columns" :data="members" />
      </n-card>
    </div>

    <!-- Invisible NSelect to satisfy the Vue warning -->
    <n-select v-if="false" />
  </div>
</template>

<script>
import { defineComponent, ref, computed, onMounted, h } from 'vue';
import { useRouter } from 'vue-router';
import { NButton, NInput, NSpace, NCard, NIcon, NForm, NFormItem, NSelect, NTable } from 'naive-ui';
import { supabase } from '@/supabaseClient';

export default defineComponent({
  name: 'OrganisationsManager',
  components: {
    NButton,
    NInput,
    NSpace,
    NCard,
    NIcon,
    NForm,
    NFormItem,
    NSelect,
    NTable,
  },
  setup() {
    const router = useRouter();

    const searchQuery = ref('');
    const organizations = ref([]);
    const selectedOrganization = ref(null);
    const members = ref([]);
    const organizationForm = ref({ name: '', description: '' });
    const roleOptions = ref([
      { label: 'Admin', value: 'admin' },
      { label: 'Member', value: 'member' },
    ]);

    const filteredOrganizations = computed(() => {
      if (!searchQuery.value) {
        return organizations.value;
      }
      return organizations.value.filter(organization =>
        organization.name.toLowerCase().includes(searchQuery.value.toLowerCase())
      );
    });

    const columns = [
      { title: 'Name', key: 'name' },
      { title: 'Email', key: 'email' },
      {
        title: 'Role', key: 'role',
        render(row) {
          return h(NSelect, {
            modelValue: row.role,
            'onUpdate:modelValue': value => row.role = value,
            options: roleOptions.value
          });
        }
      },
      {
        title: 'Actions', key: 'actions',
        render(row) {
          return h(NButton, {
            type: 'error',
            ghost: true,
            onClick: () => removeMember(row)
          }, 'Remove');
        }
      }
    ];

    const fetchOrganizations = async () => {
      const { data, error } = await supabase.from('organizations').select('*');
      if (error) {
        console.error('Error fetching organizations:', error.message);
      } else {
        organizations.value = data;
      }
    };

    const selectOrganization = (organization) => {
      selectedOrganization.value = organization;
      organizationForm.value = { name: organization.name, description: organization.description };
      fetchMembers(organization.id);
    };

    const fetchMembers = async (orgId) => {
      const { data, error } = await supabase
        .from('organization_members')
        .select('user_id, role, users ( full_name, email )')
        .eq('organization_id', orgId);
      if (error) {
        console.error('Error fetching members:', error.message);
      } else {
        members.value = data.map(member => ({
          id: member.user_id,
          name: member.users.full_name,
          email: member.users.email,
          role: member.role,
        }));
      }
    };

    const saveOrganization = async () => {
      const { error } = await supabase
        .from('organizations')
        .update({
          name: organizationForm.value.name,
          description: organizationForm.value.description,
        })
        .eq('id', selectedOrganization.value.id);
      if (error) {
        console.error('Error saving organization:', error.message);
      } else {
        await fetchOrganizations();
      }
    };

    const removeMember = async (row) => {
      const { error } = await supabase
        .from('organization_members')
        .delete()
        .eq('user_id', row.id)
        .eq('organization_id', selectedOrganization.value.id);
      if (error) {
        console.error('Error removing member:', error.message);
      } else {
        fetchMembers(selectedOrganization.value.id);
      }
    };

    const createNewOrganization = () => {
      router.push('/create-organization');
    };

    onMounted(() => {
      fetchOrganizations();
    });

    return {
      searchQuery,
      filteredOrganizations,
      selectOrganization,
      organizationForm,
      saveOrganization,
      members,
      columns,
      removeMember,
      createNewOrganization,
    };
  },
});
</script>

<style scoped>
.organizations-manager {
  padding: 20px;
  background-color: #f9fafb;
  min-height: 100vh;
  box-sizing: border-box;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.organizations-list {
  margin-top: 20px;
}

h2 {
  font-size: 24px;
  font-weight: 500;
  color: #333;
  margin-bottom: 20px;
}

.organization-card {
  margin-bottom: 20px;
}

.n-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  cursor: pointer;
  transition: box-shadow 0.2s ease;
}

.n-card:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.organization-info h3 {
  font-size: 18px;
  font-weight: 500;
  margin: 0;
}

.organization-info p {
  font-size: 14px;
  color: #888;
  margin: 5px 0 0;
}

.organization-details {
  margin-top: 30px;
}

.material-icons {
  font-size: 24px;
  color: #888;
}
</style>
