<template>
    <div v-if="user" class="user-info-container" @click="toggleUserInfo">
      <div class="user-summary">
        <div>{{ truncatedUsername }}</div>
        <div class="user-email">{{ truncatedEmail }}</div>
      </div>
      <transition name="scale-fade">
        <n-card v-if="showUserInfo" class="user-dropdown" @clickoutside="closeAllCards">
          <n-space vertical>
            <n-button block class="user-card-button" @click="goToSettings">Manage User</n-button>
            <n-button block class="user-card-button" @click="logout">Logout</n-button>
          </n-space>
        </n-card>
      </transition>
    </div>
  </template>
  
  <script>
  import { ref, computed } from 'vue';
  import { NCard, NButton, NSpace } from 'naive-ui';
  
  export default {
    name: 'UserNavbarButton',
    components: {
      NCard,
      NButton,
      NSpace,
    },
    setup() {
      const showUserInfo = ref(false);
      const user = ref(null);
  
      const truncatedEmail = computed(() => {
        const email = user.value?.email || '';
        return email.length > 20 ? `${email.slice(0, 17)}...` : email;
      });
  
      const truncatedUsername = computed(() => {
        const username = user.value?.user_metadata.username || '';
        return username.length > 15 ? `${username.slice(0, 12)}...` : username;
      });
  
      const toggleUserInfo = () => {
        showUserInfo.value = !showUserInfo.value;
        closeOtherDropdowns();
      };
  
      const closeOtherDropdowns = () => {
        showUserInfo.value = false;
      };
  
      const goToSettings = () => {
        console.log('Navigate to settings');
      };
  
      const logout = () => {
        console.log('User logged out');
      };
  
      return {
        user,
        showUserInfo,
        toggleUserInfo,
        truncatedEmail,
        truncatedUsername,
        goToSettings,
        logout,
      };
    },
  };
  </script>
  
  <style scoped>
  .user-info-container {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
  }
  
  .user-summary {
    text-align: right;
    margin-right: 8px;
  }
  
  .user-dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    width: 250px;
    z-index: 1001;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    transition: opacity 0.3s ease, transform 0.3s ease;
  }
  
  .user-dropdown .n-button {
    border: 1px solid #cbd5e0;
    background-color: white;
  }
  </style>
  