<template>
    <div>
      <h1>WorkflowEnrich</h1>
      <p>This is where your saved lists will be displayed.</p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'WorkflowEnrich',
  };
  </script>
  