<template>
    <div class="docs-page">
      <!-- Sidebar -->
      <div class="sidebar">
        <div class="menu-section">
          <h3 class="menu-section-title">Documentation</h3>
          <n-button block text @click="scrollToSection('introduction')" class="menu-item">
            Introduction
          </n-button>
          <n-button block text @click="scrollToSection('getting-started')" class="menu-item">
            Getting Started
          </n-button>
          <n-button block text @click="scrollToSection('api-reference')" class="menu-item">
            API Reference
          </n-button>
          <n-button block text @click="scrollToSection('faqs')" class="menu-item">
            FAQs
          </n-button>
          <n-divider class="divider" />
        </div>
      </div>
  
      <!-- Content -->
      <div class="content">
        <div class="docs-section" id="introduction">
          <h2>Introduction</h2>
          <p>Welcome to the documentation. This section will introduce you to the key concepts and features of our platform.</p>
        </div>
  
        <div class="docs-section" id="getting-started">
          <h2>Getting Started</h2>
          <p>This section will guide you through the initial setup process, from creating an account to deploying your first project.</p>
        </div>
  
        <div class="docs-section" id="api-reference">
          <h2>API Reference</h2>
          <p>Here you'll find detailed information about our API, including endpoints, parameters, and examples.</p>
        </div>
  
        <div class="docs-section" id="faqs">
          <h2>FAQs</h2>
          <p>Common questions and answers about using our platform.</p>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { defineComponent } from 'vue';
  import { NButton, NDivider } from 'naive-ui';
  
  export default defineComponent({
    name: 'DocsPage',
    components: {
      NButton,
      NDivider,
    },
    setup() {
      const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
          section.scrollIntoView({ behavior: 'smooth' });
        }
      };
  
      return {
        scrollToSection,
      };
    },
  });
  </script>
  
  <style scoped>
  .docs-page {
    display: flex;
    min-height: 100vh;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  .sidebar {
    width: 220px;
    background-color: white;
    border-right: 1px solid #cbd5e0;
    padding-top: 20px;
    position: fixed;
    top: 50px;
    bottom: 0;
    left: 60px;
    box-sizing: border-box;
    overflow-y: auto;
  }
  
  .menu-section {
    margin-bottom: 20px;
  }
  
  .menu-section-title {
    font-size: 12px;
    font-weight: 600;
    color: #6b7280;
    margin: 0 20px 10px 20px;
    text-transform: uppercase;
  }
  
  .menu-item {
    text-align: left;
    padding: 8px 20px;
    color: #1f2937;
    font-size: 14px;
    font-weight: 400;
    border-radius: 4px;
    margin: 2px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .menu-item-text {
    flex-grow: 1;
  }
  
  .menu-item:hover {
    background-color: #f3f4f6;
  }
  
  .menu-item.active {
    background-color: #f9fafb;
    font-weight: 600;
  }
  
  .divider {
    margin: 20px 0;
    border-color: #cbd5e0;
  }
  
  .content {
    flex-grow: 1;
    padding: 20px;
    margin-left: 220px;
    box-sizing: border-box;
    overflow-y: auto;
    min-height: calc(100vh - 50px);
  }
  
  .docs-section {
    margin-bottom: 40px;
  }
  
  .docs-section h2 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  
  .docs-section p {
    font-size: 16px;
    line-height: 1.5;
    color: #4a5568;
  }
  </style>
  