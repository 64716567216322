<template>
    <div class="company-card-container">
      <div class="company-card" @mouseover="showInfo" @mouseleave="hideInfo">
        <transition name="fade">
          <div v-if="!hovered" class="skeleton-container">
            <div class="skeleton-logo"></div>
            <div class="skeleton-text"></div>
            <div class="skeleton-lines skeleton-line-1"></div>
            <div class="skeleton-lines skeleton-line-2"></div>
            <div class="skeleton-lines skeleton-line-3"></div>
            <div class="skeleton-lines skeleton-line-4"></div>
            <div class="skeleton-details skeleton-detail-1"></div>
            <div class="skeleton-details skeleton-detail-2"></div>
            <div class="skeleton-details skeleton-detail-3"></div>
          </div>
        </transition>
        <transition name="fade">
          <div v-if="hovered" class="card-content">
            <div class="tags">
              <span v-for="tag in company.tags" :key="tag" :class="getTagClass(tag)" class="badge">{{ tag }}</span>
            </div>
            <p class="company-description">{{ company.description }}</p>
            <div class="company-details">
              <p><strong>Founded:</strong> {{ company.founded }}</p>
              <p><strong>Location:</strong> {{ company.location }}</p>
              <p><strong>CEO:</strong> {{ company.ceo }}</p>
              <p><strong>Employees:</strong> {{ company.employees }}</p>
              <p><strong>Revenue:</strong> {{ company.revenue }}</p>
            </div>
            <div class="contact-details">
              <p><strong>Email:</strong> {{ company.contact.email }}</p>
              <p><strong>Phone:</strong> {{ company.contact.phone }}</p>
              <p><strong>Website:</strong> <a :href="company.contact.website" target="_blank">{{ company.contact.website }}</a></p>
            </div>
          </div>
        </transition>
      </div>
      <div class="card-info">
        <h2 class="card-header">Find Company Information Instantly</h2>
        <p class="card-description">Forget Google Searching for every peace of information about the company, all neccesary data is already in our database!</p>
      </div>
    </div>
  </template>
  
  
  
  
  
  <script>
export default {
  name: 'CompanyCard',
  data() {
    return {
      hovered: false,
      company: {
        tags: ['Technology', 'SaaS', 'Data Compression'],
        description: 'Pied Piper is a revolutionary compression company with a groundbreaking data compression algorithm that changes everything.',
        founded: '2015',
        location: 'Silicon Valley, CA',
        ceo: 'Richard Hendricks',
        employees: '200+',
        revenue: '$1.5M annually',
        contact: {
          email: 'info@piedpiper.com',
          phone: '+1 800 555 1234',
          website: 'https://www.piedpiper.com'
        }
      }
    };
  },
  methods: {
    showInfo() {
      this.hovered = true;
    },
    hideInfo() {
      this.hovered = false;
    },
    getTagClass(tag) {
      const tagClasses = {
        'Technology': 'tag-tech',
        'SaaS': 'tag-saas',
        'Data Compression': 'tag-compression',
      };
      return tagClasses[tag] || 'tag-default';
    },
  },
};
</script>

  
  

<style scoped>
.company-card-container {
  display: flex;
  justify-content: center;
  padding: 20px;
  gap: 200px;
}

.company-card {
  width: 400px;
  background-color: #ffffff;
  border: 1px solid #cbd5e0;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  transition: transform 0.3s ease;
}

.skeleton-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
}

.skeleton-logo {
  width: 50px;
  height: 50px;
  background-color: #e2e8f0;
  border-radius: 4px;
  margin-bottom: 10px;
}

.skeleton-text {
  height: 20px;
  width: 60%;
  background-color: #e2e8f0;
  border-radius: 4px;
  margin-bottom: 10px;
}

.skeleton-lines {
  height: 14px;
  background-color: #e2e8f0;
  border-radius: 4px;
  margin-bottom: 6px;
}

.skeleton-line-1 {
  width: 80%;
}

.skeleton-line-2 {
  width: 90%;
}

.skeleton-line-3 {
  width: 70%;
}

.skeleton-line-4 {
  width: 85%;
}

.skeleton-details {
  height: 14px;
  background-color: #e2e8f0;
  border-radius: 4px;
  margin-bottom: 6px;
}

.skeleton-detail-1 {
  width: 50%;
}

.skeleton-detail-2 {
  width: 60%;
}

.skeleton-detail-3 {
  width: 55%;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.0s ease-out;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.badge {
  background-color: #fde68a;
  color: #92400e;
  font-size: 10px;
  font-weight: 600;
  padding: 4px 6px;
  border-radius: 4px;
}

.company-description {
  font-size: 1rem;
  color: #666;
  line-height: 1.4;
}

.company-details, .contact-details {
  margin-top: 10px;
  font-size: 0.9rem;
}

.company-details p, .contact-details p {
  margin: 4px 0;
}

.contact-details a {
  color: #219f73;
  text-decoration: none;
}

.contact-details a:hover {
  text-decoration: underline;
}

.card-info {
  display: flex;
  flex-direction: column;
  max-width: 400px;
}

.card-header {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.card-description {
  font-size: 1rem;
  color: #666;
  line-height: 1.4;
}
.company-card-container{
    border-bottom: #949494 1px solid;
}
</style>
