<template>
  <div id="app">
    <div class="navbar-wrapper">
      <NavBar />
    </div>
    <HeroSection />
    <SearchEntity/>
    <DemoForLanding />
    <header>
      <h1></h1>
      <p></p>
    </header>
    <ToolFrontEnd />
    <CompanyCards/>
    <SearchIndustries />
    <EmployeeEnrichDemo />
    <FindEmployeeLanding />
    <section id="features" class="features">
      <div class="container">
        <div class="feature-grid">
          <transition-group name="fade" tag="div" class="feature-grid">
            <FeatureCard
              v-for="feature in features"
              :key="feature.id"
              :title="feature.title"
              :description="feature.description"
            />
          </transition-group>
        </div>
      </div>
    </section>
    <PricingSection />
    <div id="faqs"></div>
    <FAQ />
    <FooterSection />
  </div>
</template>




<script>
import NavBar from '@/components/NavBar.vue';
import HeroSection from '@/components/HeroSection.vue';
import DemoForLanding from '@/components/DemoForLanding.vue';
import ToolFrontEnd from '@/components/ToolFrontEnd.vue';
import FAQ from '@/components/FAQ.vue';
import FooterSection from '@/components/FooterSection.vue';
import PricingSection from '@/components/PricingSection.vue';
import FindEmployeeLanding from '@/components/FindEmployeeLanding.vue';
import SearchIndustries from '@/components/SearchIndustries.vue';
import EmployeeEnrichDemo from '@/components/EmployeeEnrichDemo.vue';
import CompanyCards from '@/components/CompanyCards.vue';
import SearchEntity from '@/components/SearchEntity.vue';

export default {
  components: {
    NavBar,
    HeroSection,
    DemoForLanding,
    ToolFrontEnd,
    FAQ,
    FooterSection,
    PricingSection,
    FindEmployeeLanding,
    SearchIndustries,
    EmployeeEnrichDemo,
    CompanyCards,
    SearchEntity,
  },
  mounted() {
    // No scroll listener needed for sticky navbar
  },
  data() {
    return {
      features: [
        { id: 1, title: 'Feature 1', description: 'Description for feature 1', icon: 'desktop-mac' },
        // Add more features as needed
      ]
    };
  }
};
</script>



<style lang="scss">


/* Reset and base styles */
html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#app {
  position: relative;
  min-height: 100vh;
  overflow-x: hidden;
}

/* Sticky Navbar */
.navbar-wrapper {
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: white; /* Adjust to your design */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: adds a subtle shadow */
}

/* Adding space between components */
.hero-section,
.demo-for-landing,
header,
.tool-front-end,
.search-industries,
.employee-enrich-demo,
.find-employee-landing,
.features,
.pricing-section,
.faq,
.footer-section {
  margin-bottom: 4rem; /* Adjust to add spacing between sections */
}

/* Optional: Adjust top margins */
.hero-section {
  margin-top: 4rem;
}

.features {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

/* Other styles */
header {
  text-align: center;
  margin: 2rem 0;
  color: white;
}

.hero-section {
  align-items: center;
  align-content: center;
  justify-content: center;
  text-align: center;
}

.features {
  margin: 2rem 0;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.feature-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

body {
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

</style>


