<template>
    <div class="menu-wrapper">
      <!-- Header with Icon and Text -->
      <div class="menu-header">
        <span class="icon material-icons">cable_filled</span>
        <span class="header-text">Add data flows</span>
      </div>
  
      <!-- Content Header -->
      <div class="content-header">
        <h3 class="section-title">Content</h3>
        <hr class="divider" />
      </div>
  
      <!-- Tabs for different categories -->
      <div class="tab-menu">
        <span v-for="tab in tabs" :key="tab" class="tab">{{ tab }}</span>
      </div>
  
      <hr class="divider" />
  
      <!-- Internal Data Section (Email Template and Other Tools) -->
      <div class="section">
        <h3 class="section-title">Internal Tools</h3>
        <hr class="divider" />
        <div class="menu grid-container">
          <div
            v-for="item in menuItems"
            :key="item.id"
            class="menu-item"
            @dragstart="startDragging(item)"
            draggable="true"
          >
            <span class="drag-handle">⋮⋮⋮</span>
            <span class="icon material-icons">{{ item.icon }}</span>
            <span class="label">{{ item.label }}</span>
          </div>
  
          <!-- Email Template Card -->
          <div
            class="menu-item"
            @dragstart="startDraggingTemplate"
            draggable="true"
          >
            <span class="drag-handle">⋮⋮⋮</span>
            <span class="icon material-icons">email</span>
            <span class="label">Email Template</span>
          </div>
        </div>
      </div>
  
      <!-- Uploaded Content Section -->
      <div class="section">
        <h3 class="section-title">Uploaded Content</h3>
        <hr class="divider" />
        <NoteContentManager />
      </div>
  
      <!-- Import and Use EmailComponents -->
      <EmailComponents />
  
      <!-- Your Tables Section -->
      <div class="section">
        <h3 class="section-title">Your Tables</h3>
        <hr class="divider" />
        <div class="table-area">
          <p>You don't have any tables yet. Create a table by dragging items from the Internal Data section.</p>
        </div>
      </div>
    </div>
  </template>
  
  <script>
import NoteContentManager from './NoteContentManager.vue';
import EmailComponents from './EmailComponents.vue'; // Importing the new EmailComponents component

export default {
  name: 'NoteBoardMenu',
  components: {
    NoteContentManager,
    EmailComponents, // Registering the new component
  },
  setup(_, { emit }) {
    const menuItems = [
      { id: '1', label: 'Vendor', icon: 'store' },
      { id: '2', label: 'Contracts', icon: 'description' },
      { id: '3', label: 'Invoices', icon: 'receipt' },
      { id: '4', label: 'Risk management', icon: 'report' },
      { id: '5', label: 'Savings tracking', icon: 'savings' },
    ];

    const tabs = ['Procurement', 'Risk', 'Client management', 'Price', 'Marketing', 'Sales', 'Savings'];

    // Emit event for dragging standard menu items
    const startDragging = (item, event) => {
      event.dataTransfer.setData('componentType', item.label); // Set the component type for drag
      emit('draggingItem', { ...item });
    };

    // Handle dragging the email template
    const startDraggingTemplate = (event) => {
      event.dataTransfer.setData('componentType', 'emailTemplate');
    };

    return {
      menuItems,
      tabs,
      startDragging,
      startDraggingTemplate,
    };
  },
};
</script>

  
  
  
  
  <style scoped>
  /* General Styling */
  .menu-wrapper {
    padding: 20px;
    background-color: #ffffff;
    font-family: Arial, sans-serif;
    width: 300px;
    overflow: hidden;
    border-left: 1px solid #cbd5e0;
    border-bottom: 1px solid #cbd5e0;
  }
  
  /* Header Styling */
  .menu-header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 12px;
    margin-bottom: 12px;
    gap: 6px;
  }
  
  .header-text {
    font-weight: bold;
    color: #333;
    font-size: 16px;
  }
  
  .icon {
    font-size: 18px;
    color: #3ecda0;
  }
  
  /* Content Header */
  .content-header {
    margin-bottom: 12px;
  }
  
  /* Tabs */
  .tab-menu {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
    margin-bottom: 12px;
  }
  
  .tab {
    background-color: #f0f0f0;
    padding: 6px 10px;
    font-size: 12px;
    color: #333;
    cursor: pointer;
  }
  
  .tab:hover {
    background-color: #e0e0e0;
  }
  
  /* Divider */
  .divider {
    margin: 8px 0;
    border: 0;
    border-top: 1px solid #ccc;
  }
  
  /* Sections */
  .section {
    margin-bottom: 16px;
  }
  
  .section-title {
    font-weight: bold;
    margin-bottom: 8px;
    color: #333;
    font-size: 14px;
  }
  
  /* Menu Items (Internal Data and Email Template) */
  .grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 8px;
  }
  
  .menu-item {
    background-color: #fff;
    padding: 12px;
    border: 1px solid #e0e0e0;
    text-align: center;
    cursor: grab;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 0px;
    position: relative;
  }
  
  /* Drag handle (6 dots in two columns) */
  .drag-handle {
    position: absolute;
    top: 4px;
    left: 4px;
    font-size: 16px;
    color: #aaa;
  }
  
  .icon {
    font-size: 22px;
  }
  
  .label {
    font-size: 10px;
    color: #555;
    margin-top: 8px;
  }
  
  /* Your Tables Section */
  .table-area {
    background-color: #f9f9f9;
    padding: 12px;
    border-radius: 4px;
    text-align: center;
    font-size: 12px;
    color: #555;
  }
  </style>
  