<template>
  <div class="notifications-wrapper" v-click-outside="closeAllCards">
    <n-icon size="24" class="navbar-icon" @click="toggleNotifications">
      <i class="material-icons-outlined">notifications</i>
    </n-icon>
    <transition name="fade">
      <n-card v-if="showNotifications" class="notifications-card">
        <n-space vertical>
          <!-- Display a message if there are no notifications -->
          <div v-if="notifications.length === 0" class="no-notifications">
            No recent notifications
          </div>
          <!-- Display notifications dynamically (currently empty) -->
          <div v-else>
            <div class="notification-item" v-for="notification in notifications" :key="notification.id">
              <div class="notification-header">
                <n-icon size="18" class="notification-icon">
                  <i :class="notification.icon"></i>
                </n-icon>
                <span class="notification-category">{{ notification.category }}</span>
                <span class="notification-timestamp">{{ formatTimestamp(notification.timestamp) }}</span>
              </div>
              <div class="notification-text">
                {{ notification.text }}
              </div>
            </div>
          </div>
        </n-space>
      </n-card>
    </transition>
  </div>
</template>

<script>
import { ref } from 'vue';
import { NIcon, NCard, NSpace } from 'naive-ui';
import clickOutside from '@/directives/clickOutside'; // Ensure to have this directive for click-off functionality

export default {
  name: 'NotificationsButton',
  components: {
    NIcon,
    NCard,
    NSpace,
  },
  directives: {
    clickOutside, // Register the directive for click-off functionality
  },
  setup() {
    const showNotifications = ref(false);
    const notifications = ref([]); // No notifications initially

    const toggleNotifications = () => {
      showNotifications.value = !showNotifications.value;
    };

    const closeAllCards = () => {
      showNotifications.value = false;
    };

    const formatTimestamp = (timestamp) => {
      const date = new Date(timestamp);
      return date.toLocaleString(); // Format timestamp into readable format
    };

    return {
      showNotifications,
      notifications,
      toggleNotifications,
      closeAllCards,
      formatTimestamp,
    };
  },
};
</script>

<style scoped>
.notifications-wrapper {
  position: relative;
  display: inline-block;
}

.navbar-icon {
  cursor: pointer;
  z-index: 1002;
}

.notifications-card {
  position: absolute;
  top: 100%;
  right: 0;
  width: 300px;
  z-index: 1001;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.notification-item {
  padding: 12px;
  font-size: 14px;
  border-bottom: 1px solid #f0f0f0;
}

.notification-item:last-child {
  border-bottom: none;
}

.notification-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;
}

.notification-text {
  color: #333;
}

.notification-icon {
  margin-right: 8px;
}

.notification-category {
  font-weight: bold;
  flex-grow: 1;
}

.notification-timestamp {
  font-size: 12px;
  color: #888;
}

.no-notifications {
  padding: 10px;
  font-size: 14px;
  text-align: center;
  color: #999;
}
</style>
