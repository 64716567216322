<template>
  <div class="header-container">
    <h1 class="greeting">Hello, {{ projectName }}!</h1>
    <p>This is your dashboard, here all important data will be displayed, check out other tabs.</p>

    <div class="quick-links">
      <n-button v-for="link in quickLinks" :key="link.label" class="quick-link" @click="handleClick(link)">
        {{ link.label }}
      </n-button>
    </div>
  </div>
</template>

<script>
import { NButton } from 'naive-ui';
import { supabase } from '@/supabaseClient.js';

export default {
  components: {
    NButton,
  },
  data() {
    return {
      projectName: '', // This will be populated with the project name
    };
  },
  props: {
    quickLinks: {
      type: Array,
      required: true,
    },
  },
  methods: {
    handleClick(link) {
      console.log(`Navigating to ${link.url}`);
      // Add your navigation logic here
    },
    async fetchProjectName() {
      try {
        // Fetch the selected project ID from sessionStorage
        const selectedProjectId = sessionStorage.getItem('selectedProjectId');

        if (selectedProjectId) {
          const { data, error } = await supabase
            .from('projects')
            .select('name')
            .eq('id', selectedProjectId)
            .single();

          if (error) {
            console.error('Error fetching project data:', error.message);
            return;
          }

          console.log('Fetched Project Data:', data); // Debug: Check the fetched data from the projects table

          if (data && data.name) {
            this.projectName = data.name;
            console.log('Project Name Set To:', this.projectName); // Debug: Confirm the projectName is set correctly
          } else {
            this.projectName = 'Unknown Project'; // Fallback if no project name is found
          }
        } else {
          console.error('No project is selected.');
          this.projectName = 'Unknown Project'; // Fallback if no project is selected
        }
      } catch (error) {
        console.error('Unexpected Error:', error.message);
      }
    },
  },
  mounted() {
    this.fetchProjectName(); // Fetch project name when the component is mounted
  },
};
</script>

<style scoped>
.header-container {
  padding: 1rem;
  background-color: #ffffff;
  border: #cbd5e0 1px solid;
  margin-bottom: 1.5rem; /* Adjust this to ensure proper spacing from components below */
}

.greeting {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.quick-links {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap; /* Allows items to wrap to the next line if needed */
}

.quick-link {
  background-color: #f7fafc;
  border: 1px solid #e2e8f0;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  color: #4a5568;
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.quick-link:hover {
  background-color: #edf2f7;
  border-color: #cbd5e0;
}
</style>
