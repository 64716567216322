<template>
  <div class="dashboard-container">
    <!-- Fake Navbar -->
    <div class="navbar-demo">
      <div class="navbar-content">
        <img src="@/assets/OutriaLogo.png" alt="Logo" class="navbar-logo" />
        <input type="text" placeholder="Search..." class="navbar-search" disabled />
        <span class="navbar-info" @click="toggleTip(1)">ℹ️</span>

        

      </div>
    </div>

    <!-- Main Content Area -->
    <div class="employee-enrich-demo">
      <div class="steps-section">
        <n-steps vertical :current="currentStep" :status="currentStatus">
          <n-step title="Step 1" description="Company Details" />
          <n-step title="Step 2" description="Employee Profile" />
          <n-step title="Step 3" description="Similar Employees" />
          <n-step title="Step 4" description="Similar Companies" />
        </n-steps>
        <div class="controls">
          <n-button @click="prevStep" :disabled="currentStep === 1" class="green-button">Previous</n-button>
          <n-button @click="nextStep" :disabled="currentStep === 4" class="green-button">Next</n-button>
        </div>
      </div>

      <div class="content-section">
        <!-- Step 1: Company Details -->
        <div v-if="currentStep === 1" class="company-details">
          <div class="company-info">
            <img :src="companyInfo.logo" alt="Pied Piper Logo" class="company-logo" />
            <div class="company-specs">
              <h2>{{ companyInfo.name }}</h2>
              <p>{{ companyInfo.description }}</p>
              <div class="tags">
                <n-tag v-for="industry in companyInfo.industries" :key="industry">{{ industry }}</n-tag>
              </div>
              <p><strong>Location:</strong> {{ companyInfo.location }}</p>
              <p><strong>Founded:</strong> {{ companyInfo.founded }}</p>
              <p><strong>Employees:</strong> {{ companyInfo.employees }}</p>
              <n-button type="primary" @click="nextStep" class="green-button">Find Employees</n-button>
            </div>
          </div>
        </div>

        <!-- Step 2: Employee Profile -->
        <div v-if="currentStep === 2" class="employee-profile">
          <div class="user-profile">
            <img :src="userProfile.image" alt="User Profile" class="profile-image" />
            <div class="profile-info">
              <h2>{{ userProfile.name }}</h2>
              <p><strong>Company:</strong> Pied Piper</p>
            </div>
          </div>

          <div v-if="!dataEnriched" class="profile-details skeleton-profile">
            <n-skeleton height="20px" width="70%" padding="5px" />
            <n-skeleton height="15px" width="50%" />
            <n-skeleton height="15px" width="90%" />
            <n-skeleton height="15px" width="60%" />
            <n-skeleton height="15px" width="40%" />
          </div>

          <div v-else class="profile-details">
            <p><strong>Email:</strong> {{ userProfile.email }}</p>
            <p><strong>Position:</strong> {{ userProfile.position }}</p>
            <p><strong>Address:</strong> {{ userProfile.address }}</p>
            <p><strong>Phone:</strong> {{ userProfile.phone }}</p>
            <p><strong>LinkedIn:</strong> {{ userProfile.linkedin }}</p>
          </div>

          <div class="button-group">
            <n-button type="primary" @click="enrichData" class="green-button">Enrich Data</n-button>
            <n-button type="info" @click="goToStep(3)" class="green-button">Find Similar Employees</n-button>
          </div>

          <!-- Drawer for Enriched Data -->
          <n-drawer v-model:show="showDrawer1" :width="480">
            <n-drawer-content title="Enriched Data" :native-scrollbar="false">
              <p>Name: {{ userProfile.name }}</p>
              <p>Email: {{ userProfile.email }}</p>
              <p>Position: {{ userProfile.position }}</p>
              <p>Address: {{ userProfile.address }}</p>
              <p>Phone: {{ userProfile.phone }}</p>
              <p>LinkedIn: {{ userProfile.linkedin }}</p>
            </n-drawer-content>
          </n-drawer>
        </div>

        <!-- Step 3: Similar Employees -->
        <div v-if="currentStep === 3" class="similar-employees">
          <h2>Similar Employees</h2>
          <ul>
            <n-flex justify="space-around" size="large">
            <li v-for="employee in similarEmployees" :key="employee.id" class="list-item">
              <n-avatar :src="employee.avatar" />
              <span>{{ employee.name }} - {{ employee.position }}</span>
            
            </li>
          </n-flex>
          </ul>
        
          <n-button type="info" class="green-button" @click="goToStep(4)">Find Similar Companies</n-button>
        </div>

        <!-- Step 4: Similar Companies -->
        <div v-if="currentStep === 4" class="similar-companies">
          <h2>Similar Companies</h2>
          <ul>
            <li v-for="company in similarCompanies" :key="company.id" class="list-item">
              <img :src="company.logo" alt="Company Logo" class="company-logo-small" />
              <div class="company-info">
                <h3>{{ company.name }}</h3>
                <p class="company-description">{{ company.description }}</p>
                <div class="tags">
                  <n-tag type="success" v-for="industry in company.industries" :key="industry">{{ industry }}</n-tag>
                 
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { NSteps, NStep, NButton, NDrawer, NTag, NSkeleton, NAvatar,} from 'naive-ui';

const currentStep = ref(1);
const currentStatus = ref('process');
const showTip = ref(false);

// Company Information for Step 1
const companyInfo = {
  logo: require('@/assets/pied-piper-logo.png'),
  name: 'Pied Piper',
  description: 'Pied Piper is a revolutionary decentralized internet company.',
  industries: ['Tech', 'Software', 'Networking'],
  location: 'Palo Alto, CA',
  founded: '2014',
  employees: '50-100'
};

// User profile for Step 2
const dataEnriched = ref(false);
const userProfile = ref({
  image: require('@/assets/user-profile.jpg'),
  name: 'Bertram Gilfoyle',
  email: '',
  position: '',
  address: '',
  phone: '',
  linkedin: ''
});

// Similar Employees for Step 3
const similarEmployees = ref([
  { id: 1, name: 'Dinesh Chugtai', position: 'Lead Developer', avatar: require('@/assets/dinesh.jpg') },
  { id: 2, name: 'Jared Dunn', position: 'Chief Operations Officer', avatar: require('@/assets/jared.jpg') },
  { id: 3, name: 'Richard Hendricks', position: 'CEO', avatar: require('@/assets/richard.jpg') }
]);

// Similar Companies for Step 4
const similarCompanies = ref([
  {
    id: 1,
    name: 'Hooli',
    description: 'A massive conglomerate focused on innovation.',
    industries: ['Tech', 'Software'],
    logo: require('@/assets/hooli-logo.png')
  },
  {
    id: 2,
    name: 'Endframe',
    description: 'Cloud-based storage solutions for enterprises.',
    industries: ['Cloud', 'Storage'],
    logo: require('@/assets/endframe-logo.png')
  },
  {
    id: 3,
    name: 'Aviato',
    description: 'A transportation app that connects people with rides.',
    industries: ['Tech', 'Transportation'],
    logo: require('@/assets/aviato-logo.png')
  }
]);

// Drawer visibility
const showDrawer1 = ref(false);

// Navigation Methods
function nextStep() {
  if (currentStep.value < 4) {
    currentStep.value++;
  }
}

function prevStep() {
  if (currentStep.value > 1) {
    currentStep.value--;
  }
}

function goToStep(step) {
  currentStep.value = step;
}

// Enrich Data Method
function enrichData() {
  userProfile.value.email = 'gilfoyle@piedpiper.com';
  userProfile.value.position = 'Chief Technical Officer';
  userProfile.value.address = '1234 Hacker Way, Silicon Valley, CA';
  userProfile.value.phone = '(555) 555-5555';
  userProfile.value.linkedin = 'linkedin.com/in/bertramgilfoyle';

  dataEnriched.value = true;
  showDrawer1.value = true;
}

// Tutorial Tip Toggle
function toggleTip(step) {
  currentStep.value = step;
  showTip.value = !showTip.value;
}
</script>

<style scoped>
.dashboard-container {
  background-color: #f5f7fa;
  padding: 40px;
  
  display: flex;
  flex-direction: column;
  align-items: center;
}

.navbar-demo {
  background-color: #ffffff;
  padding: 10px;
  width: 100%;
  max-width: 900px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.navbar-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.navbar-logo {
  width: 40px;
}

.navbar-search {
  flex: 1;
  margin: 0 10px;
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #ddd;
  background-color: #fafafa;
  width: 50px;
}

.info-container {
  position: relative;
}

.navbar-info {
  font-size: 20px;
  cursor: pointer;
}

.employee-enrich-demo {
  display: flex;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 900px;
  padding: 20px;
}

.steps-section {
  width: 250px;
  margin-right: 20px;
}

.controls {
  margin-top: 10px;
}

.content-section {
  width: 100%;
}

.company-details {
  display: flex 1;
}

.company-info {
  display: flex;
  align-items: flex-start;
}

.company-logo {
  width: 80px;
  margin-right: 20px;
  align-items: top;
}

.company-specs {
  flex: 1;
}

.profile-image {
  width: 100px;
}

.company-logo-small {
  width: 70px;
}

.button-group {
  margin-top: 20px;
}

.skeleton-profile {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

ul {
  list-style: none;
  padding: 0;
}

.list-item {
  display: flex;
  align-items: center;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  gap: 8px;
}

.list-item img {
  margin-right: 10px;
}

.company-description {
  margin-top: 8px;
  margin-left: 10px;
  color: #555;
}

.tags {
  margin-top: 5px;
  margin-left: 10px;
}

.green-button {
  background-color: white;
  color: rgb(15, 153, 82);
 
}

.pop-button {
  transition: transform 0.3s;
}

.pop-button:hover {
  transform: scale(1.05);
}



</style>
