<template>
    <div class="public-directory-wrapper">
      <!-- Navbar at the top -->
      <NavBar />
  
      <!-- Public Directory section with smaller left sidebar -->
      <div class="public-directory-container">
        <div class="empty-sidebar left-sidebar"></div> <!-- Left empty section -->
  
        <div class="public-directory">
          <n-card>
            <h2 class="directory-title">Public Directory</h2>
            <div class="search-filters">
              <!-- Left column: inputs in two rows -->
              <div class="filter-inputs">
                <n-input v-model="searchQuery" placeholder="Search by company name" clearable class="search-input" />
                <n-input v-model="companyCode" placeholder="Company code" clearable class="search-input" />
                <n-input v-model="searchKeyword" placeholder="Search by keyword" clearable class="search-input" />
                <n-select v-model="selectedIndustry" placeholder="Industry" clearable class="search-input" />
                <n-input v-model="selectedCity" placeholder="City" clearable class="search-input" />
              </div>
  
              <!-- Divider below the input fields -->
              <hr class="filter-divider" />
  
              <!-- Search Button below the divider -->
              <div class="filter-buttons">
                <n-button @click="searchCompanies" type="primary" class="search-button">Search</n-button>
              </div>
            </div>
          </n-card>
  
          <!-- Search Results -->
          <div class="results" v-if="paginatedCompanies.length">
            <div v-for="company in paginatedCompanies" :key="company.id" class="company-card">
              <div class="company-logo">
                <img :src="company.logo" alt="company logo" class="company-logo-img" />
              </div>
              <div class="company-info">
                <div class="company-header">
                  <!-- Company name with icons on the same line -->
                  <router-link :to="`/companies/${company.name}`" class="company-name-link">
                    <h3 class="company-name">{{ company.name }}</h3>
                  </router-link>
                  <!-- Icons aligned to the right -->
                  <div class="company-icons">
                    <div v-for="(icon, index) in companyIcons[company.id].displayIcons" :key="index" class="data-icon">
                      <i :class="icon.icon" :title="icon.title"></i>
                    </div>
                    <div v-if="companyIcons[company.id].hiddenCount > 0" class="more-icons">
                      +{{ companyIcons[company.id].hiddenCount }}
                    </div>
                  </div>
                </div>
  
                <!-- Domain under the name -->
                <p class="company-domain">{{ company.domain }}</p>
  
                <!-- Location with icon -->
                <p class="company-address">
                  <i class="fa fa-map-marker location-icon"></i> <!-- Location icon -->
                  {{ company.city?.name }}, {{ company.country?.name }}
                </p>
  
                <!-- Description -->
                <p class="company-description">{{ truncateDescription(company.description) }}</p>
              </div>
            </div>
          </div>
          <div v-else class="no-results">No companies found.</div>
  
          <!-- Pagination -->
          <n-pagination
            v-model:page="currentPage"
            :page-count="totalPages"
            :page-size="pageSize"
            show-size-picker
            :page-sizes="[10, 20, 50]"
            @update:page-size="changePageSize"
          />
        </div>
  
        <div class="empty-sidebar right-sidebar"></div> <!-- Right empty section -->
      </div>
  
      <!-- Footer at the bottom -->
      <FooterSection />
    </div>
  </template>
  
  
  
  
  
  <script>
import { ref, computed, onMounted } from 'vue';
import { supabase } from '@/supabaseClient';
import NavBar from '@/components/NavBar.vue';
import FooterSection from '@/components/FooterSection.vue';

export default {
  name: 'PublicDirectory',
  components: {
    NavBar,
    FooterSection,
  },
  setup() {
    const searchQuery = ref('');
    const companyCode = ref('');
    const selectedIndustry = ref('');
    const selectedCity = ref('');
    const companies = ref([]);
    const currentPage = ref(1);
    const pageSize = ref(10);
    const totalPages = ref(1);
    const maxIcons = 5; // Maximum icons to display in the company card

    // Truncate description for display purposes
    const truncateDescription = (description) => {
      const maxLength = 150;
      if (description && description.length > maxLength) {
        return description.substring(0, maxLength) + '...';
      }
      return description;
    };

    // Fetch companies from Supabase
    const fetchCompanies = async () => {
      let query = supabase
        .from('companies')
        .select('id, name, domain, description, city, country, revenue, total_employees_exact, phone_number, year_founded, monthly_visitors, logo'); // Fetch relevant fields

      // Apply filters to the Supabase query
      if (searchQuery.value) {
        query = query.ilike('name', `%${searchQuery.value}%`);
      }
      if (companyCode.value) {
        query = query.eq('company_code', companyCode.value);
      }
      if (selectedIndustry.value) {
        query = query.ilike('industry_main', `%${selectedIndustry.value}%`);
      }
      if (selectedCity.value) {
        query = query.ilike('city->>name', `%${selectedCity.value}%`);
      }

      // Paginate results
      const start = (currentPage.value - 1) * pageSize.value;
      query = query.range(start, start + pageSize.value - 1);

      const { data, error, count } = await query;

      if (error) {
        console.error('Error fetching companies:', error);
        companies.value = [];  // Empty companies if there's an error
      } else {
        companies.value = data || [];  // Populate companies
        totalPages.value = count ? Math.ceil(count / pageSize.value) : 1;  // Set total pages for pagination
      }
    };

    // Computed property to get icons for each company and handle icon limits
    const companyIcons = computed(() => {
      const iconsMap = {};
      companies.value.forEach((company) => {
        const icons = [];

        // Dynamically check if certain data exists and push corresponding icons
        if (company.revenue) icons.push({ icon: 'fa fa-chart-line', title: 'Revenue available' });
        if (company.domain) icons.push({ icon: 'fa fa-globe', title: 'Website available' });
        if (company.total_employees_exact) icons.push({ icon: 'fa fa-users', title: 'Employee count available' });
        if (company.phone_number) icons.push({ icon: 'fa fa-phone', title: 'Phone number available' });
        if (company.year_founded) icons.push({ icon: 'fa fa-calendar', title: 'Founded year available' });
        if (company.monthly_visitors) icons.push({ icon: 'fa fa-eye', title: 'Monthly visitors available' });

        // Determine how many icons are hidden
        const displayIcons = icons.slice(0, maxIcons);
        const hiddenCount = icons.length > maxIcons ? icons.length - maxIcons : 0;

        iconsMap[company.id] = {
          displayIcons,
          hiddenCount,
        };
      });
      return iconsMap;
    });

    const searchCompanies = () => {
      currentPage.value = 1;
      fetchCompanies();
    };

    const changePageSize = (newPageSize) => {
      pageSize.value = newPageSize;
      currentPage.value = 1;
      fetchCompanies();
    };

    onMounted(() => {
      fetchCompanies();
    });

    return {
      searchQuery,
      companyCode,
      selectedIndustry,
      selectedCity,
      companies,
      paginatedCompanies: computed(() => companies.value.slice((currentPage.value - 1) * pageSize.value, currentPage.value * pageSize.value)),
      companyIcons,
      searchCompanies,
      currentPage,
      pageSize,
      totalPages,
      changePageSize,
      truncateDescription,
      maxIcons,
    };
  },
};
</script>

  

<style scoped>
/* General layout */
.public-directory-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.public-directory-container {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 20px;
}

.empty-sidebar {
  border: 1px solid #ddd;
  height: 100%;
}

.left-sidebar {
  flex-basis: 15%; /* Smaller left section */
}

.right-sidebar {
  flex-basis: 30%; /* Original size for right section */
}

.public-directory {
  flex-basis: 700px; /* Reduced width for the main content */
  margin: 120px 20px;
  padding: 20px;
  background-color: #f5f5f5;
}

/* Search Filters Layout */
.search-filters {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  border-radius: 8px;
}

.filter-inputs {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  width: 100%;
}

.filter-divider {
  margin: 20px 0;
  border: 0;
  height: 1px;
  background: #ddd;
}

.filter-buttons {
  display: flex;
  justify-content: flex-start;
}

.search-button {
  padding: 8px 16px;
  font-size: 14px;
}

/* Company Card Styling */
.results {
  margin-top: 20px;
}

.company-card {
  display: flex;
  align-items: flex-start;
  padding: 10px;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  margin-bottom: 10px;
}

.company-logo {
  margin-right: 10px;
  flex-shrink: 0;
}

.company-logo-img {
  width: 50px; /* Smaller logo */
  height: 50px;
  object-fit: cover;
}

.company-info {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.company-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Company name link */
.company-name-link {
  text-decoration: none;
  color: inherit;
}

.company-name-link:hover {
  text-decoration: underline;
}

.company-name {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
}

.company-icons {
  display: flex;
  align-items: center;
  gap: 5px;
}

.data-icon {
  font-size: 16px;
  color: rgb(26, 202, 137); /* Default color for available data */
}

.more-icons {
  font-size: 14px;
  color: rgb(145, 145, 145);
}

/* Revenue */
.company-revenue {
  font-size: 14px;
  color: #666;
  margin: 5px 0;
}

/* Location icon */
.location-icon {
  color: #888;
  margin-right: 5px;
  font-size: 14px;
}

.company-address {
  font-size: 14px;
  color: #888;
  margin: 2px 0;
}

.company-description {
  font-size: 14px;
  color: #666;
  margin: 5px 0;
  line-height: 1.4;
}

/* No Results */
.no-results {
  text-align: center;
  margin-top: 20px;
  font-size: 14px;
}

/* Pagination */
.pagination {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

footer {
  margin-top: auto;
}
</style>
