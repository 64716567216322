<template>
  <section class="faq-contact-section">
    <div class="container">
      <!-- Contact Form on the Left -->
      <div class="contact-form">
        <h2 class="section-title">Contact Us</h2>
        <form @submit.prevent="handleSubmit">
          <div class="form-group">
            <label for="name">Name</label>
            <input type="text" id="name" v-model="name" required />
          </div>
          <div class="form-group">
            <label for="email">Email</label>
            <input type="email" id="email" v-model="email" required />
          </div>
          <div class="form-group">
            <label for="message">Message</label>
            <textarea id="message" v-model="message" required></textarea>
          </div>
          <button type="submit" class="submit-btn">Send Message</button>
        </form>
        <p class="privacy-note">
          Your information is safe with us. Read our <a href="#">Privacy Policy</a>.
        </p>
      </div>

      <!-- FAQ Section on the Right -->
      <div class="faq-list">
        <h2 class="section-title">Frequently Asked Questions</h2>
        <div
          v-for="(item, index) in faqs"
          :key="index"
          class="faq-item"
        >
          <div
            class="faq-question"
            @click="toggle(index)"
          >
            <span>{{ item.question }}</span>
            <i :class="['fas', activeIndex === index ? 'fa-chevron-up' : 'fa-chevron-down']"></i>
          </div>
          <div v-if="activeIndex === index" class="faq-answer">
            {{ item.answer }}
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'FAQContact',
  setup() {
    const activeIndex = ref(null);
    const name = ref('');
    const email = ref('');
    const message = ref('');

    const faqs = [
      { question: 'How quickly do I get a list of companies after I query?', answer: 'Immediately, thanks to our software. Recommendations come after 1-2 weeks of you working with the software.' },
      { question: 'Why is there no free trial?', answer: 'We understand that you want to try our software for free, but we value privacy of companies and their employees in our database.' },
      { question: 'How many companies are there in the database?', answer: 'We have 83 Million Companies in over 24 Thousand industries and 155 Thousand Locations.' },
      { question: 'Is there a limit of how many companies I can export?', answer: 'There is no limit on how many companies you can have in your saved list, when it comes to exporting data, we recommend having no more than 2000 Companies in 1 folder' },
      { question: 'Is your data legally obtained and safe to use commercially?', answer: 'We acquire our data legally, all of it is open to public, we use various methods to scrape that data at huge quantities for full workload efficiency.' },
    ];

    const toggle = (index) => {
      activeIndex.value = activeIndex.value === index ? null : index;
    };

    const handleSubmit = () => {
      // Handle form submission here
      alert('Message sent successfully!');
    };

    return {
      faqs,
      activeIndex,
      toggle,
      name,
      email,
      message,
      handleSubmit,
    };
  },
});
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap');

.faq-contact-section {
  padding: 4rem 2rem;
  background: linear-gradient(to right, #f9f9f9, #ffffff);
  font-family: 'Roboto', sans-serif;
}

.container {
  display: flex;
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  flex-wrap: wrap;
}

.contact-form {
  flex: 1;
  background-color: #ffffff;
  padding: 2rem;

  
  border: 1px solid #ddd;
}

.faq-list {
  flex: 1;
  background-color: #ffffff;
  padding: 2rem;
 
  
  border: 1px solid #ddd;
}

.section-title {
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  font-weight: 500;
  color: #333;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  font-weight: 400;
  margin-bottom: 0.5rem;
  color: #555;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  color: #333;
}

.form-group textarea {
  height: 120px;
}

.submit-btn {
  padding: 0.75rem 2rem;
  background-color: #18b67c;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 400;
}

.submit-btn:hover {
  background-color: #15a06a;
}

.privacy-note {
  margin-top: 1rem;
  font-size: 0.875rem;
  color: #666;
}

.privacy-note a {
  color: #18b67c;
  text-decoration: none;
}

.privacy-note a:hover {
  text-decoration: underline;
}

.faq-item {
  background-color: #f7f7f7;
  border: 1px solid #ddd;
  border-radius: 0px;
  
  overflow: hidden;
}

.faq-question {
  padding: 1rem;
  font-size: 1rem;
  font-weight: 400;
  color: #333;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  transition: background-color 0.3s;
}

.faq-question:hover {
  background-color: #f0f0f0;
}

.faq-answer {
  padding: 1rem;
  font-size: 0.85rem;
  color: #666;
  border-top: 1px solid #e0e0e0;
}

.fas {
  font-size: 1rem;
  transition: transform 0.3s;
}

.faq-question .fa-chevron-up {
  transform: rotate(180deg);
}
</style>
