<template>
  <div>
    <!-- Compose Email Row at the Bottom -->
    <div class="compose-email-row" @click="toggleDrawer">
      <span>Compose</span>
      <span style="margin-left: 20px;"> <!-- Increased gap -->
        <span v-if="!drawerOpen">▲</span>
        <span v-else>▼</span>
      </span>
    </div>

    <!-- Drawer for Email Composition -->
    <transition name="slide-up">
      <div v-if="drawerOpen" class="drawer">
        <!-- Header of the Opened Drawer -->
        <div class="drawer-header" @click="toggleDrawer">
          <span>Compose Email</span>
          <span>▼</span>
        </div>

        <div class="drawer-content">
          <!-- Sidebar with Templates -->
          <div class="drawer-sidebar">
            <h3>Email Templates</h3>
            <ul>
              <li v-for="template in templates" :key="template.id" @click="selectTemplate(template)">
                {{ template.name }}
              </li>
            </ul>
          </div>

          <!-- Email Compose Area -->
          <div class="drawer-compose">
            <form @submit.prevent="sendEmail">
              <div class="form-group">
                <label for="recipient">Recipient:</label>
                <input type="email" v-model="email.recipient" id="recipient" required />
              </div>

              <div class="form-group">
                <label for="subject">Subject:</label>
                <input type="text" v-model="email.subject" id="subject" required />
              </div>

              <div class="form-group">
                <label for="body">Body:</label>
                <textarea v-model="email.body" id="body" rows="6" required></textarea>
              </div>

              <button type="submit">Send Email</button>
            </form>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      drawerOpen: false,
      email: {
        recipient: '',
        subject: '',
        body: ''
      },
      templates: [
        { id: 1, name: 'Follow-up Template', content: 'This is a follow-up email...' },
        { id: 2, name: 'Welcome Template', content: 'Welcome to our service...' }
      ]
    };
  },
  methods: {
    toggleDrawer() {
      this.drawerOpen = !this.drawerOpen;
    },
    selectTemplate(template) {
      this.email.body = template.content;
    },
    sendEmail() {
      alert('Email sent!');
    }
  }
};
</script>

<style scoped>
/* The button row to open the drawer */
.compose-email-row {
  position: fixed;
  bottom: 0;
  left: 60px;
  right: 16px; /* Added right padding to avoid overlap with scrollbar */
  background-color: #f3f4f6;
  color: #1f2937;
  text-align: center;
  padding: 10px;
  cursor: pointer;
  z-index: 10;
  border-top: 1px solid #cbd5e0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.compose-email-row span {
  display: inline-flex;
  align-items: center;
}

/* Drawer styling */
.drawer {
  position: fixed;
  bottom: 0;
  left: 60px;
  right: 20px; /* Added right padding to avoid overlap with scrollbar */
  background-color: white;
  height: 60vh;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
  z-index: 20;
  border-top: 1px solid #cbd5e0;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}

/* The header inside the opened drawer */
.drawer-header {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  background-color: #f3f4f6;
  border-bottom: 1px solid #cbd5e0;
  cursor: pointer;
}

/* Drawer content styling */
.drawer-content {
  display: flex;
  height: 100%;
  overflow: hidden;
}

/* Sidebar for email templates */
.drawer-sidebar {
  width: 30%;
  background-color: #f9fafb;
  padding: 20px;
  overflow-y: auto;
}

.drawer-sidebar ul {
  list-style: none;
  padding: 0;
}

.drawer-sidebar li {
  padding: 8px;
  cursor: pointer;
  border-bottom: 1px solid #e2e8f0;
}

/* Email compose section */
.drawer-compose {
  width: 70%;
  padding: 20px;
  overflow-y: auto;
}

/* Form input styling */
.form-group {
  margin-bottom: 15px;
}

.drawer-compose input,
.drawer-compose textarea {
  width: calc(100% - 20px); /* Adjusted width to prevent horizontal scrollbar */
  padding: 10px;
  border: 1px solid #cbd5e0;
  border-radius: 4px;
}

/* Submit button styling */
button {
  background-color: #3ecda0;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

button:hover {
  background-color: #129b59;
}

/* Slide-up transition */
.slide-up-enter-active, .slide-up-leave-active {
  transition: transform 0.3s ease;
}

.slide-up-enter, .slide-up-leave-to {
  transform: translateY(100%);
}

/* Add padding to the page so the drawer doesn't cover the content */
body {
  padding-bottom: 80px; /* Adjust this value based on the drawer height */
}
</style>
