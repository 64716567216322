<template>
    <div>
      <n-tabs v-model:active="activeTab" type="card" class="tabs">
        <n-tab-pane name="dashboard" tab="Your Usage">
          <DashboardView />
        </n-tab-pane>
        <n-tab-pane name="yourmarket" tab="Your Market">
          <!-- Include your settings view component here -->
          <YourMarket />
        </n-tab-pane>
        <!-- Add more tabs as needed -->
      </n-tabs>
    </div>
  </template>
  
  <script>
  import { defineComponent, ref } from 'vue';
  import { NTabs, NTabPane } from 'naive-ui';
  import DashboardView from '@/components/DashboardView.vue';
  import YourMarket from '@/components/YourMarket.vue'; // Import other views as needed
  
  export default defineComponent({
    components: {
      NTabs,
      NTabPane,
      DashboardView,
      YourMarket,
    },
    setup() {
      const activeTab = ref('dashboard'); // Default tab
  
      return {
        activeTab
      };
    }
  });
  </script>
  
  <style scoped>
  .tabs {
    margin: 0;
    padding: 0;
    color: #e8e8e8;
  }
  
  .n-tabs {
    border-bottom: 1px solid #e8e8e8;
  }
  </style>
  