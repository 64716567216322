<template>
    <div v-if="!loading">
      <n-form ref="formRef" label-placement="top">
        <!-- Profile Information Section -->
        <n-card title="Profile Information" bordered>
          <n-space vertical>
            <n-form-item label="Username">
              <n-input v-model:value="userForm.username" disabled />
            </n-form-item>
            <n-form-item label="User ID">
              <n-input v-model:value="userForm.user_id" disabled />
              <n-button @click="copyUserId" ghost>Copy User ID</n-button>
            </n-form-item>
            <n-form-item label="Full Name">
              <n-input v-model:value="userForm.full_name" placeholder="Enter your full name" />
            </n-form-item>
            <n-form-item label="Company">
              <n-input v-model:value="userForm.company" placeholder="Enter your company name" />
            </n-form-item>
            <n-form-item label="Work Email">
              <n-input v-model:value="userForm.work_email" placeholder="Enter your work email" />
            </n-form-item>
          </n-space>
        </n-card>
  
        <!-- Notification Preferences Section -->
        <n-card title="Notification Preferences" bordered>
          <n-space vertical>
            <n-checkbox v-model="userForm.email_notifications">Email Notifications</n-checkbox>
            <n-checkbox v-model="userForm.app_notifications">App Notifications</n-checkbox>
          </n-space>
        </n-card>
  
        <!-- Save and Delete Account Section -->
        <n-space justify="space-between">
          <n-button @click="saveChanges" type="primary">Save Changes</n-button>
          <n-button @click="showDeleteConfirm" type="error" ghost>Delete Account</n-button>
        </n-space>
      </n-form>
  
      <!-- Confirm Delete Dialog -->
      <n-modal v-model:show="deleteModalVisible" title="Confirm Account Deletion">
        <p>Are you sure you want to delete your account? This action cannot be undone.</p>
        <n-space justify="end">
          <n-button @click="deleteModalVisible = false">Cancel</n-button>
          <n-button type="error" @click="deleteAccount">Delete</n-button>
        </n-space>
      </n-modal>
    </div>
    <div v-else>
      <p>Loading...</p>
    </div>
  </template>
  
  <script>
  import { defineComponent, ref } from 'vue';
  import { useRouter } from 'vue-router';
  import { NCard, NForm, NFormItem, NInput, NButton, NSpace, NCheckbox, NModal, useNotification } from 'naive-ui';
  import { supabase } from '@/supabaseClient';
  
  export default defineComponent({
    name: 'UserProfile',
    components: {
      NCard,
      NForm,
      NFormItem,
      NInput,
      NButton,
      NSpace,
      NCheckbox,
      NModal,
    },
    setup() {
      const router = useRouter();
      const formRef = ref(null);
      const loading = ref(true);
      const deleteModalVisible = ref(false);
      const notification = useNotification();
      const userForm = ref({
        username: '',
        user_id: '',
        full_name: '',
        company: '',
        work_email: '',
        email_notifications: false,
        app_notifications: false,
      });
  
      const fetchUserData = async () => {
        try {
          const { data: session, error: sessionError } = await supabase.auth.getSession();
          if (sessionError) {
            console.error('Session error:', sessionError);
            throw sessionError;
          }
  
          const loggedInUser = session?.session?.user;
          if (loggedInUser) {
            userForm.value.username = loggedInUser.user_metadata.username || 'No Username';
            userForm.value.user_id = loggedInUser.id || 'No ID';
  
            const { data: clientData, error: clientError } = await supabase
              .from('clients')
              .select('full_name, company, work_email, email_notifications, app_notifications')
              .eq('user_id', loggedInUser.id)
              .maybeSingle();
  
            if (clientError) {
              notification.error({
                content: 'Error fetching client data.',
                meta: clientError.message,
                duration: 2500,
              });
            } else if (clientData) {
              userForm.value.full_name = clientData.full_name || '';
              userForm.value.company = clientData.company || '';
              userForm.value.work_email = clientData.work_email || '';
              userForm.value.email_notifications = clientData.email_notifications || false;
              userForm.value.app_notifications = clientData.app_notifications || false;
            } else {
              notification.warning({
                content: 'No client data found for your account.',
                duration: 2500,
              });
            }
          } else {
            notification.error({
              content: 'No logged-in user found.',
              duration: 2500,
            });
            router.push('/signin');
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
          router.push('/signin');
        } finally {
          loading.value = false;
        }
      };
  
      const saveChanges = async () => {
        try {
          const { error: updateError } = await supabase
            .from('clients')
            .update({
              full_name: userForm.value.full_name,
              company: userForm.value.company,
              work_email: userForm.value.work_email,
              email_notifications: userForm.value.email_notifications,
              app_notifications: userForm.value.app_notifications,
            })
            .eq('user_id', userForm.value.user_id);
  
          if (updateError) {
            notification.error({
              content: 'Failed to update profile.',
              meta: updateError.message,
              duration: 2500,
            });
          } else {
            notification.success({
              content: 'Profile updated successfully.',
              duration: 2500,
            });
          }
        } catch (error) {
          console.error('Unexpected error:', error);
          notification.error({
            content: 'Unexpected error occurred.',
            meta: error.message,
            duration: 2500,
          });
        }
      };
  
      const deleteAccount = async () => {
        try {
          const { error } = await supabase.auth.signOut();
          if (!error) {
            notification.success({
              content: 'Account deleted successfully.',
              duration: 2500,
            });
            router.push('/signin');
          } else {
            notification.error({
              content: 'Failed to delete account.',
              meta: error.message,
              duration: 2500,
            });
          }
        } catch (error) {
          console.error('Unexpected Error:', error.message);
          notification.error({
            content: 'Unexpected error occurred.',
            meta: error.message,
            duration: 2500,
          });
        }
      };
  
      const copyUserId = () => {
        navigator.clipboard.writeText(userForm.value.user_id).then(
          () => {
            notification.success({
              content: 'User ID copied to clipboard!',
              duration: 1500,
            });
          },
          (err) => {
            console.error('Failed to copy user ID: ', err);
            notification.error({
              content: 'Failed to copy User ID.',
              meta: err.message,
              duration: 2500,
            });
          }
        );
      };
  
      fetchUserData();
  
      return {
        userForm,
        formRef,
        loading,
        deleteModalVisible,
        copyUserId,
        saveChanges,
        deleteAccount,
      };
    },
  });
  </script>
  
  <style scoped>
  .n-card {
    margin-bottom: 20px;
    width: 100%;
  }
  </style>
  