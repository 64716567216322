<template>
  <div>
    <!-- Ensure that chartData is correctly passed before rendering the chart -->
    <Bar v-if="chartData" :data="chartData" :options="chartOptions" />
  </div>
</template>

<script>
// Importing necessary components and modules from vue-chartjs and chart.js
import { Bar } from 'vue-chartjs';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from 'chart.js';

// Registering Chart.js components
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

export default {
  props: {
    chartData: {
      type: Object,
      required: true,
    },
    chartOptions: {
      type: Object,
      required: true,
    },
  },
  components: {
    Bar,
  },
};
</script>

<style scoped>
/* Add your styles here if necessary */
</style>
