<template>
    <div>
      <!-- Navbar -->
      <NavBar />
  
      <!-- Main Documentation Page -->
      <div class="docs-page">
        <!-- Sidebar -->
        <div class="sidebar">
          <div class="menu-section">
            <h3 class="menu-section-title">Documentation</h3>
            <n-button block text @click="scrollToSection('introduction')" class="menu-item">
              Introduction
            </n-button>
            <n-button block text @click="scrollToSection('getting-started')" class="menu-item">
              Getting Started
            </n-button>
            <n-button block text @click="scrollToSection('api-reference')" class="menu-item">
              API Reference
            </n-button>
            <n-button block text @click="scrollToSection('faqs')" class="menu-item">
              FAQs
            </n-button>
            <n-button block text @click="scrollToSection('servers')" class="menu-item">
              Servers
            </n-button>
            <n-button block text @click="scrollToSection('projects')" class="menu-item">
              Projects
            </n-button>
            <n-button block text @click="scrollToSection('organizations')" class="menu-item">
              Organizations
            </n-button>
            <n-divider class="divider" />
          </div>
        </div>
    
        <!-- Content -->
        <div class="content">
          <div class="docs-section" id="introduction">
            <h2>Introduction</h2>
            <p>Welcome to the documentation. This section will introduce you to the key concepts and features of our platform.</p>
          </div>
    
          <div class="docs-section" id="getting-started">
            <h2>Getting Started</h2>
            <p>Create and account and verify your email. Then create an organization, we recommend using your company name or your personal name. After this you will be taken to the dashboard. A project file will already be created for you automatically. For example if your organization name is "Outria", your default project name will likely be "Outria's project".</p>
          </div>
    
          <div class="docs-section" id="projects">
            <h2>Projects</h2>
            <p>All data you save will be stored under projects name, therefore it is very important to check if you have the right project selected before using our tools.</p>
          </div>

          <div class="docs-section" id="organzations">
            <h2>Projects</h2>
            <p>The whole point of organizations is for easier member management. If you want your employees or team members to work on the same project, all members have to be in the same organization. </p>
          </div>

          <div class="docs-section" id="api-reference">
            <h2>API Reference</h2>
            <p>Here you'll find detailed information about our API, including endpoints, parameters, and examples.</p>
          </div>
    
          <div class="docs-section" id="faqs">
            <h2>FAQs</h2>
            <p>Common questions and answers about using our platform.</p>
          </div>
  
          <div class="docs-section" id="servers">
            <h2>Servers</h2>
            <p>Choose your database by going to the Settings/Servers tab and picking your desired server side. The recommended database is already connected to your account so this option is only for experienced users who know what they are doing.</p>
            <img src="@/assets/serverside.png" alt="Server Side" class="server-image" />
          </div>
  
        </div>
      </div>
    
      <!-- Footer -->
      <FooterSection />
    </div>
  </template>
  
  <script>
  import { defineComponent } from 'vue';
  import { NButton, NDivider } from 'naive-ui';
  import NavBar from '@/components/NavBar.vue';
  import FooterSection from '@/components/FooterSection.vue';
  
  export default defineComponent({
    name: 'DocumentationPage',
    components: {
      NButton,
      NDivider,
      NavBar,
      FooterSection,
    },
    setup() {
      const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
          section.scrollIntoView({ behavior: 'smooth' });
        }
      };
  
      return {
        scrollToSection,
      };
    },
  });
  </script>
  
  <style scoped>
  .docs-page {
    display: flex;
    min-height: 100vh;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    margin-top: 50px;
  }
  
  .sidebar {
    width: 220px;
    background-color: white;
    border-right: 1px solid #cbd5e0;
    padding-top: 20px;
    position: fixed;
    top: 60px; /* Adjusted for Navbar */
    bottom: 0;
    left: 0;
    box-sizing: border-box;
    overflow-y: auto;
  }
  
  .menu-section {
    margin-bottom: 20px;
  }
  
  .menu-section-title {
    font-size: 12px;
    font-weight: 600;
    color: #6b7280;
    margin: 0 20px 10px 20px;
    text-transform: uppercase;
  }
  
  .menu-item {
    text-align: left;
    padding: 8px 20px;
    color: #1f2937;
    font-size: 14px;
    font-weight: 400;
    border-radius: 4px;
    margin: 2px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .menu-item:hover {
    background-color: #f3f4f6;
  }
  
  .menu-item.active {
    background-color: #f9fafb;
    font-weight: 600;
  }
  
  .divider {
    margin: 20px 0;
    border-color: #cbd5e0;
  }
  
  .content {
    flex-grow: 1;
    padding: 20px;
    margin-left: 220px;
    box-sizing: border-box;
    overflow-y: auto;
    min-height: calc(100vh - 60px); /* Adjusted for Navbar */
  }
  
  .docs-section {
    margin-bottom: 40px;
  }
  
  .docs-section h2 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  
  .docs-section p {
    font-size: 16px;
    line-height: 1.5;
    color: #4a5568;
  }
  
  .server-image {
    margin-top: 20px;
    max-width: 100%;
    height: auto;
    border: 1px solid #cbd5e0;
    border-radius: 8px;
  }
  </style>
  