<template>
    <div class="campaign-view-container">
      <div class="header-box">
        <h2 v-if="campaign" class="campaign-title">{{ campaign.name }}</h2>
        <n-button class="export-button" type="success" @click="onBtnExport">
          Export to CSV
        </n-button>
      </div>
      <div class="table-container">
        <ag-grid-vue
          class="ag-theme-balham"
          style="width: 100%; height: 100%;"  
          :columnDefs="columnDefs"
          :rowData="companyData"
          :defaultColDef="defaultColDef"
          :gridOptions="gridOptions"
          :sideBar="sideBar"
          :statusBar="statusBar"
          @grid-ready="onGridReady"
        ></ag-grid-vue>
      </div>
      <p v-if="!companyData.length && debugInfo">{{ debugInfo }}</p>
    </div>
  </template>
  
  <script>
  import { defineComponent, ref, onMounted, watch } from 'vue';
  import { AgGridVue } from 'ag-grid-vue3'; 
  import 'ag-grid-community/styles/ag-grid.css';
  import 'ag-grid-community/styles/ag-theme-balham.css';
  import { supabase } from '@/supabaseClient';
  
  export default defineComponent({
    name: 'CampaignView',
    components: {
      AgGridVue,
    },
    props: {
      campaign: {
        type: Object,
        required: true,
      },
    },
    setup(props) {
      const companyData = ref([]);
      const debugInfo = ref('No companies found.');
      const gridApi = ref(null);  // Reference to the grid API
  
      const columnDefs = ref([
        {
          headerName: 'Company Name',
          field: 'name',
          sortable: true,
          filter: 'agTextColumnFilter',
          floatingFilter: true,
          checkboxSelection: true,
          headerCheckboxSelection: true,
        },
        {
          headerName: 'Logo',
          field: 'logo',
          sortable: true,
          filter: false,
          cellRenderer: (params) =>
            `<div style="display: flex; justify-content: center; align-items: center; height: 100%;">
              <img src="${params.value}" style="max-width: 50px; max-height: 50px; object-fit: contain;" />
            </div>`,
          autoHeight: true,
        },
        {
          headerName: 'City',
          field: 'city',
          sortable: true,
          filter: 'agTextColumnFilter',
          floatingFilter: true,
        },
        {
          headerName: 'State',
          field: 'state',
          sortable: true,
          filter: 'agTextColumnFilter',
          floatingFilter: true,
        },
        {
          headerName: 'Domain',
          field: 'domain',
          sortable: true,
          filter: 'agTextColumnFilter',
          floatingFilter: true,
        },
        {
          headerName: 'Total Employees',
          field: 'totalEmployees',
          sortable: true,
          filter: 'agNumberColumnFilter',
        },
        {
          headerName: 'Revenue',
          field: 'revenue',
          sortable: true,
          filter: 'agTextColumnFilter',
          floatingFilter: true,
        },
      ]);
  
      const defaultColDef = {
        resizable: true,
        sortable: true,
        filter: true,
        flex: 1,
        minWidth: 100,
      };
  
      const gridOptions = {
        pagination: true,
        paginationPageSize: 10,
        rowSelection: 'multiple',
        animateRows: true,
        enableRangeSelection: true,
        suppressRowClickSelection: true,
      };
  
      const sideBar = {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
        defaultToolPanel: 'columns',
      };
  
      const statusBar = {
        statusPanels: [
          { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
          { statusPanel: 'agTotalRowCountComponent', align: 'center' },
          { statusPanel: 'agFilteredRowCountComponent' },
          { statusPanel: 'agSelectedRowCountComponent' },
          { statusPanel: 'agAggregationComponent' },
        ],
      };
  
      const onGridReady = (params) => {
        gridApi.value = params.api;  // Set the grid API
      };
  
      const onBtnExport = () => {
        gridApi.value.exportDataAsCsv();  // Use the grid API to export data as CSV
      };
  
      const fetchCompanyData = async () => {
        if (!props.campaign || !props.campaign.id) {
          debugInfo.value = 'No campaign data provided.';
          return;
        }
  
        try {
          const { data, error } = await supabase
            .from('campaign_data')
            .select('data')
            .eq('campaign_id', props.campaign.id);
  
          if (error) {
            console.error('Error fetching campaign data:', error);
            debugInfo.value = `Error fetching data: ${error.message}`;
            return;
          }
  
          companyData.value = data.flatMap((item) => {
            return item.data.companies.map((company) => ({
              name: company.name || 'Unknown',
              logo: company.logo || '',
              city: company.city?.name || 'Unknown',
              state: company.state?.name || 'Unknown',
              domain: company.domain || 'Unknown',
              totalEmployees: company.totalEmployees || 'Unknown',
              revenue: company.revenue || 'Unknown',
            }));
          });
  
          if (companyData.value.length === 0) {
            debugInfo.value = 'No companies found.';
          } else {
            debugInfo.value = '';
          }
        } catch (error) {
          console.error('Unexpected error:', error);
          debugInfo.value = `Unexpected error: ${error.message}`;
        }
      };
  
      onMounted(fetchCompanyData);
      watch(() => props.campaign, fetchCompanyData);
  
      return {
        companyData,
        columnDefs,
        defaultColDef,
        gridOptions,
        sideBar,
        statusBar,
        debugInfo,
        onBtnExport,
        onGridReady,
      };
    },
  });
  </script>
  
  <style scoped>
  .campaign-view-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  .header-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    padding: 20px;
    border-bottom: 1px solid #dee2e6;
    
    position: fixed;
    top: 50px; /* Adjust this based on your navbar height */
    left: 280px; /* Left margin */
    right: 20px; /* Right margin */
    z-index: 10; /* Ensure the header stays on top of the grid */
  }
  
  .table-container {
    flex: 1;
    margin-top: 39px; /* Adjust based on header height */
    margin-left: -50px; /* Same as the header left margin */
    margin-right: -50px; /* Same as the header right margin */
    padding-bottom: 0px; /* Optional: padding for the bottom */
    overflow: hidden;
    box-sizing: border-box; /* Include padding and borders in element's width/height */
    z-index: 100;
  }
  
  .campaign-title {
    margin: 0;
    font-size: 24px;
    font-weight: 600;
    color: #343a40;
  }
  
  .export-button {
    margin-right: 20px;
  }
  
  .ag-theme-balham {
    width: 100%;
    height: 100%;
    border-radius: 0;
    overflow: hidden;
    box-sizing: border-box;
  }
  
  .ag-header-cell {
    background-color: #004085 !important; /* Adjusted header color */
    color: white !important;
    font-weight: bold;
    text-align: center;
  }
  
  .ag-row-hover {
    background-color: #f1f1f1 !important;
  }
  
  .ag-cell {
    font-family: 'Roboto', sans-serif;
    text-align: left;
  }
  
  .ag-paging-panel {
    background-color: #004085 !important;
    color: white !important;
    padding: 10px;
    border-top: 1px solid #003366;
  }
  
  .ag-paging-panel button {
    background-color: white;
    color: #004085;
    border: 1px solid #004085;
    padding: 5px 10px;
    border-radius: 3px;
    cursor: pointer;
  }
  
  .ag-paging-panel button:hover {
    background-color: #003366;
    color: white;
  }
  </style>
  