<template>
  <div class="emails-page">
    <!-- Show GoogleAuth component if not authorized -->
    <GoogleAuth v-if="!accessToken" @authorize="authorizeWithGoogle" />

    <!-- Show other components if user is authorized -->
    <div v-else>
      <SelectContact />
      <EmailView :emails="emails" />
      <WriteEmail />
    </div>
  </div>
</template>

<script>
import { supabase } from '../supabaseClient';
import WriteEmail from '/src/components/WriteEmail.vue';
import EmailView from '/src/components/EmailView.vue';
import GoogleAuth from '/src/components/GoogleAuth.vue';
import SelectContact from '@/components/SelectContact.vue';

export default {
  components: {
    WriteEmail,
    EmailView,
    GoogleAuth,
    SelectContact,
  },
  data() {
    return {
      emails: [],
      accessToken: null, // Google OAuth access token
      userId: null,      // The user's ID (from Supabase session)
    };
  },
  methods: {
    async authorizeWithGoogle() {
      // Get the session data from Supabase
      const { data: session, error } = await supabase.auth.getSession();
      if (error || !session) {
        console.error("Supabase session not found, user needs to log in.");
        alert("Please log in first to proceed with authentication.");
        return;
      }

      // Google OAuth redirection
      const supabaseToken = session.session?.access_token;
      if (!supabaseToken) {
        console.error('Supabase token not found.');
        return;
      }

      const redirectUrl = process.env.NODE_ENV === 'production'
        ? `https://www.outria.com/auth/google?supabase_token=${supabaseToken}`
        : `http://localhost:3000/auth/google?supabase_token=${supabaseToken}`;

      window.location.href = redirectUrl;
    },

    async fetchEmails() {
      if (!this.accessToken) return;

      try {
        const response = await fetch('https://gmail.googleapis.com/gmail/v1/users/me/messages', {
          headers: {
            Authorization: `Bearer ${this.accessToken}`,
          },
        });

        const data = await response.json();
        if (data.error) {
          console.error('Error fetching emails:', data.error);
          return;
        }

        if (!data.messages || data.messages.length === 0) {
          console.log('No emails found.');
          return;
        }

        const emails = await Promise.all(
          data.messages.slice(0, 10).map(async (message) => {
            const details = await this.fetchEmailDetails(message.id);
            const from = details?.payload?.headers?.find((header) => header.name === 'From')?.value || 'No sender';
            const subject = details?.payload?.headers?.find((header) => header.name === 'Subject')?.value || 'No subject';

            return {
              id: details.id,
              snippet: details.snippet || 'No snippet available',
              from,
              subject,
              time: new Date(details.internalDate).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
            };
          })
        );

        this.emails = emails;
      } catch (error) {
        console.error('Error fetching emails:', error);
      }
    },

    async fetchEmailDetails(messageId) {
      try {
        const response = await fetch(
          `https://gmail.googleapis.com/gmail/v1/users/me/messages/${messageId}?format=metadata&metadataHeaders=From&metadataHeaders=Subject`,
          {
            headers: {
              Authorization: `Bearer ${this.accessToken}`,
            },
          }
        );

        const details = await response.json();
        return details;
      } catch (error) {
        console.error('Error fetching email details:', error);
      }
    },

    async getAccessToken() {
      try {
        // Fetch the access token from Supabase
        const { data, error } = await supabase
          .from('user_tokens')
          .select('access_token, refresh_token, expires_in, updated_at')
          .eq('user_id', this.userId)
          .single();

        // Handle missing token (likely due to deletion)
        if (error || !data) {
          console.error('Access token not found. Re-authentication is required.');
          this.accessToken = null;
          return null;
        }

        // Check if access token is expired and refresh it if needed
        const tokenAge = (Date.now() - new Date(data.updated_at).getTime()) / 1000;
        if (tokenAge > data.expires_in) {
          console.log('Access token expired, refreshing...');
          return this.refreshAccessToken(data.refresh_token);
        }

        return data.access_token;
      } catch (error) {
        console.error('Error retrieving access token:', error);
        return null;
      }
    },

    async refreshAccessToken(refreshToken) {
      try {
        const response = await fetch('https://oauth2.googleapis.com/token', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: new URLSearchParams({
            client_id: process.env.GOOGLE_CLIENT_ID,
            client_secret: process.env.GOOGLE_CLIENT_SECRET,
            refresh_token: refreshToken,
            grant_type: 'refresh_token',
          }),
        });

        const tokenData = await response.json();

        if (tokenData.access_token) {
          // Update the access token in Supabase
          await supabase
            .from('user_tokens')
            .update({ access_token: tokenData.access_token, expires_in: tokenData.expires_in })
            .eq('user_id', this.userId);

          return tokenData.access_token;
        } else {
          console.error('Error refreshing access token:', tokenData);
          return null;
        }
      } catch (error) {
        console.error('Error refreshing access token:', error);
        return null;
      }
    },
  },

  async created() {
    try {
      // Check if the user is authenticated
      const { data: session, error: sessionError } = await supabase.auth.getSession();
      if (sessionError || !session?.user) {
        console.error('User not authenticated', sessionError);
        return;
      }

      this.userId = session.user.id;

      // Fetch access token
      this.accessToken = await this.getAccessToken();

      // If access token is present, fetch emails
      if (this.accessToken) {
        await this.fetchEmails();
      } else {
        console.log('No access token found. Please authorize your Google account.');
      }
    } catch (error) {
      console.error('Error initializing component:', error);
    }
  },
};
</script>

<style scoped>
.emails-page {
  display: flex;
  flex-direction: column;
  height: 100vh;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.email-view {
  flex-grow: 1;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.write-email {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}
</style>
