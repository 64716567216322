<template>
  <div class="on-demand-crawl">
    <!-- NavBar Component -->
    <NavBar />

    <!-- Main Content Area -->
    <div class="content">
      <h2>On-Demand Company Data Scraper</h2>
      <form @submit.prevent="startScraping">
        <div class="form-group">
          <label for="company">Enter Company Name:</label>
          <input
            v-model="companyName"
            id="company"
            type="text"
            class="form-control"
            placeholder="e.g., Audena"
          />
        </div>
        <button type="submit" class="btn" :disabled="isLoading">
          Start Scraping
        </button>
      </form>

      <!-- Loading state -->
      <div v-if="isLoading" class="loading">
        Scraping data, please wait...
      </div>

      <!-- Display Results After Scraping -->
      <div v-if="scrapedData && !isLoading" class="results">
        <h3>Scraped Data</h3>
        <ul>
          <li><strong>Company Name:</strong> {{ scrapedData.name }}</li>
          <li><strong>Registration Code:</strong> {{ scrapedData.registrationCode }}</li>
          <li><strong>Address:</strong> {{ scrapedData.address }}</li>
          <li><strong>Phone:</strong> {{ scrapedData.phone }}</li>
          <li><strong>Email:</strong> {{ scrapedData.email }}</li>
          <li><strong>Website:</strong> <a :href="scrapedData.website" target="_blank">{{ scrapedData.website }}</a></li>
        </ul>
      </div>

      <!-- Display Error Message -->
      <div v-if="errorMessage" class="error">
        <p>{{ errorMessage }}</p>
      </div>
    </div>

    <!-- Footer Component -->
    <FooterSection />
  </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue';
import FooterSection from '@/components/FooterSection.vue';

export default {
  name: 'OnDemandCrawl',
  components: {
    NavBar,
    FooterSection
  },
  data() {
    return {
      companyName: '',
      scrapedData: null,
      isLoading: false,
      errorMessage: null
    };
  },
  methods: {
    async startScraping() {
      if (!this.companyName.trim()) {
        alert('Please enter a company name.');
        return;
      }

      this.isLoading = true;
      this.scrapedData = null;
      this.errorMessage = null;

      try {
        const response = await fetch('/api/scrape', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ companyName: this.companyName })
        });

        if (!response.ok) {
          throw new Error('Error occurred while scraping the data.');
        }

        const data = await response.json();
        this.scrapedData = data.companyData;
      } catch (error) {
        console.error('Error fetching data:', error);
        this.errorMessage = 'Failed to scrape data. Please try again.';
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>

<style scoped>
.content {
  padding: 2rem;
  margin-top: 4rem;
}
.form-group {
  margin-bottom: 1rem;
}
.form-control {
  width: 100%;
  padding: 0.5rem;
  margin-top: 0.5rem;
}
.btn {
  padding: 0.7rem 1.5rem;
  background-color: #333;
  color: white;
  border: none;
  cursor: pointer;
}
.results {
  margin-top: 2rem;
}
.loading {
  margin-top: 2rem;
  color: #666;
  font-style: italic;
}
.error {
  margin-top: 2rem;
  color: red;
  font-weight: bold;
}
</style>
