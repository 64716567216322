<template>
  <div>
    
    <!-- Use the DataDash component -->
    <DataDash />
  </div>
</template>

<script>
import DataDash from '@/components/DataDash.vue';

export default {
  name: 'DashboardHome',
  components: {
    DataDash
  }
};
</script>

<style scoped>
/* Add any component-specific styles here */
</style>
