<template>
    <div class="map-container">
      <!-- SVG World Map -->
      <div class="map-wrapper">
        <!-- Directly include the SVG content here -->
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 512" class="world-map">
          <!-- Include your SVG paths here -->
          <!-- Example paths; replace these with actual content from your world.svg -->
          <path id="US" class="country" d="M10 10 H 90 V 90 H 10 Z" />
          <path id="CA" class="country" d="M110 10 H 190 V 90 H 110 Z" />
          <!-- Add more paths as needed -->
        </svg>
      </div>
  
      <!-- Country List -->
      <div class="country-list">
        <h3>Country List</h3>
        <ul>
          <li v-for="(country, index) in countries" 
              :key="index"
              :class="{ active: selectedCountry === country.code }"
              @mouseover="highlightCountry(country.code)"
              @mouseout="resetHighlight"
          >
            {{ country.name }}: {{ country.value }}
          </li>
        </ul>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'YourMarketMap',
    data() {
      return {
        selectedCountry: null,
        countries: [
          { name: 'United States', code: 'US', value: 100 },
          { name: 'Canada', code: 'CA', value: 80 },
          // Add more countries as needed
        ]
      };
    },
    methods: {
      highlightCountry(code) {
        this.selectedCountry = code;
        const path = document.querySelector(`#${code}`);
        if (path) {
          path.classList.add('highlight');
        }
      },
      resetHighlight() {
        this.selectedCountry = null;
        document.querySelectorAll('.country').forEach(path => path.classList.remove('highlight'));
      }
    }
  };
  </script>
  
  <style scoped>
  .map-container {
    display: flex;
    align-items: flex-start;
    gap: 20px;
  }
  
  .map-wrapper {
    width: 70%; /* Adjust size as needed */
    overflow: hidden;
  }
  
  .world-map {
    width: 100%;
    height: auto;
    max-height: 500px; /* Limit the height */
  }
  
  .country-list {
    width: 30%;
    padding: 10px;
    border-left: 1px solid #ccc;
    overflow-y: auto;
  }
  
  .country-list h3 {
    margin-top: 0;
  }
  
  .country-list ul {
    list-style-type: none;
    padding: 0;
  }
  
  .country-list li {
    margin-bottom: 5px;
    cursor: pointer;
  }
  
  .country-list li.active {
    color: green;
    font-weight: bold;
  }
  
  .country.highlight {
    fill: lightgreen; /* Highlight color */
    stroke: darkgreen; /* Optional border for better visibility */
    stroke-width: 2px; /* Optional border width */
  }
  </style>
  