<template>
  <div class="dropdown-container" v-click-outside="closeDropdown">
    <n-button type="default" class="dropdown-button" @click="toggleDropdown">
      <div class="button-content">
        <div class="icon-text-container">
          <n-icon class="dropdown-icon">
            <i class="material-icons custom-icon">grid_view_round</i>
          </n-icon>
          <div class="text-container">
            <div class="project-name">{{ selectedProjectName || 'Select Project' }}</div>
          </div>
        </div>
      </div>
      <n-icon class="dropdown-icon">
        <i class="material-icons custom-arrow-icon">unfold_more</i>
      </n-icon>
    </n-button>

    <transition name="fade-fast">
      <div v-if="dropdownVisible" class="dropdown-card">
        <n-input v-model="searchQuery" placeholder="Find project..." clearable class="search-input" />
        <n-space vertical>
          <div
            v-for="project in filteredProjects"
            :key="project.id"
            class="dropdown-item"
            @click="selectProject(project)"
          >
            <n-icon class="check-icon" v-if="selectedProjectName === project.name">
              <i class="material-icons">check</i>
            </n-icon>
            {{ project.name }}
          </div>
          <div class="dropdown-item new-item" @click="createNewProject">
            <n-icon class="add-icon">
              <i class="material-icons">add_circle</i>
            </n-icon>
            New project
          </div>
        </n-space>
      </div>
    </transition>
  </div>
</template>

<script>
import { defineComponent, ref, computed, onMounted } from 'vue';
import { NButton, NIcon, NInput, NSpace } from 'naive-ui';
import { supabase } from '@/supabaseClient';
import clickOutside from '@/directives/clickOutside';

export default defineComponent({
  name: 'MyProjectButton',
  components: {
    NButton,
    NIcon,
    NInput,
    NSpace,
  },
  directives: {
    clickOutside,
  },
  setup() {
    const selectedProjectName = ref('');
    const projects = ref([]);
    const searchQuery = ref('');
    const dropdownVisible = ref(false);

    const fetchProjects = async () => {
      try {
        const { data, error } = await supabase.from('projects').select('id, name');
        if (error) {
          console.error('Error fetching projects:', error.message);
        } else {
          projects.value = data;
          const selectedProjectId = sessionStorage.getItem('selectedProjectId');
          if (selectedProjectId) {
            const selectedProject = projects.value.find((project) => project.id === selectedProjectId);
            if (selectedProject) {
              selectedProjectName.value = selectedProject.name;
            }
          }
        }
      } catch (error) {
        console.error('Failed to fetch projects:', error);
      }
    };

    const filteredProjects = computed(() => {
      return projects.value.filter((project) =>
        project.name.toLowerCase().includes(searchQuery.value.toLowerCase())
      );
    });

    const toggleDropdown = () => {
      dropdownVisible.value = !dropdownVisible.value;
    };

    const closeDropdown = () => {
      dropdownVisible.value = false;
    };

    const selectProject = (project) => {
      selectedProjectName.value = project.name;
      sessionStorage.setItem('selectedProjectId', project.id);
      dropdownVisible.value = false;
    };

    const createNewProject = () => {
      // Implement logic to create a new project
    };

    onMounted(() => {
      fetchProjects();
    });

    return {
      selectedProjectName,
      searchQuery,
      filteredProjects,
      dropdownVisible,
      toggleDropdown,
      closeDropdown,
      selectProject,
      createNewProject,
    };
  },
});
</script>

<style scoped>
.dropdown-container {
  position: relative;
}

.dropdown-button {
  background-color: white;
  color: #333;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px;
  width: auto;
  max-width: 180px;
  height: 36px;
  border-radius: 4px;
  box-sizing: border-box;
}

.button-content {
  display: flex;
  align-items: left;
}

.icon-text-container {
  display: flex;
  align-items: center;
}

.text-container {
  margin-left: 8px;
}

.project-name {
  font-weight: 500;
  color: #333;
}

.dropdown-icon {
  margin-left: 8px;
  display: flex;
  align-items: center;
}

.custom-arrow-icon,
.custom-icon {
  font-size: 20px;
}

/* Dropdown card with shadow */
.dropdown-card {
  position: absolute;
  top: 100%;
  left: 0;
  width: 250px;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-top: 8px;
  z-index: 1001;
  padding: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Added shadow */
  transition: all 0.3s ease-in-out; /* Smooth transition */
}

.dropdown-item {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-bottom: 1px solid #e0e0e0; /* Added border */
}

.dropdown-item:last-child {
  border-bottom: none; /* Remove the border from the last item */
}

.dropdown-item:hover {
  background-color: #f0f0f0;
}

.new-item {
  color: #18b67c;
}

.search-input {
  margin-bottom: 8px;
  text-align: left;
}

.check-icon,
.add-icon {
  margin-right: 8px;
  font-size: 18px;
  display: flex; /* Ensure icons are centered vertically */
  align-items: center;
}

.fade-fast-enter-active, .fade-fast-leave-active {
  transition: opacity 0.1s ease;
}

.fade-fast-enter, .fade-fast-leave-to {
  opacity: 0;
}
</style>
