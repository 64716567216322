<template>
  <div class="organization-selection-wrapper">
    <div class="organization-selection-container">
      <h1 class="title">Choose an Organization</h1>
      <p class="subtitle">to continue to your app</p>

      <div v-for="org in organizations" :key="org.value" class="organization-item" @click="selectOrganization(org)">
        <div class="organization-details">
          <div class="organization-icon">
            <i class="material-icons">{{ org.icon }}</i>
          </div>
          <div class="organization-info">
            <div class="organization-name">{{ org.label }}</div>
            <div class="organization-email">{{ org.email }}</div>
          </div>
        </div>
        <div v-if="selectedOrg === org.value" class="selected-indicator">
          <n-icon size="20"><i class="material-icons">check_circle</i></n-icon>
        </div>
      </div>

      <div class="add-organization" @click="createNewOrganization">
        <n-icon size="20"><i class="material-icons">add_circle</i></n-icon>
        <span>Add Organization</span>
      </div>

      <div v-if="selectionError" class="error-message">{{ selectionError }}</div>

      <div class="actions">
        <n-button @click="confirmSelection" class="submit-button" type="primary">
          Confirm
        </n-button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { NButton, NIcon } from 'naive-ui';
import { supabase } from '@/supabaseClient';

export default defineComponent({
  components: {
    NButton,
    NIcon,
  },
  setup() {
    const selectedOrg = ref(null);
    const organizations = ref([]);
    const selectionError = ref('');
    const router = useRouter();

    const fetchOrganizations = async () => {
      const { data, error } = await supabase.from('organizations').select('*');
      if (error) {
        console.error('Error fetching organizations:', error.message);
      } else {
        organizations.value = data.map(org => ({
          label: org.name,
          value: org.id,
          email: org.email, // Assuming you store the organization's email or another identifier
          icon: org.icon || 'business', // Assuming you have an icon or fallback to a default
        }));
      }
    };

    const selectOrganization = async (org) => {
      selectedOrg.value = org.value;
      selectionError.value = ''; // Clear any previous errors

      // Auto-select the project related to the organization
      const { data: projectData, error: projectError } = await supabase
        .from('projects')
        .select('id')
        .eq('organization_id', org.value)
        .single();

      if (projectError || !projectData) {
        console.error('Error fetching project for selected organization:', projectError?.message);
        selectionError.value = 'No project found for this organization';
      } else {
        sessionStorage.setItem('selectedProjectId', projectData.id);
      }
    };

    const confirmSelection = () => {
      if (!selectedOrg.value) {
        selectionError.value = 'Please select an organization';
      } else if (!sessionStorage.getItem('selectedProjectId')) {
        selectionError.value = 'No project is selected or associated with this organization';
      } else {
        sessionStorage.setItem('selectedOrgId', selectedOrg.value);
        router.push('/dashboard');
      }
    };

    const createNewOrganization = () => {
      router.push('/create-organization');
    };

    onMounted(() => {
      fetchOrganizations();
    });

    return {
      selectedOrg,
      organizations,
      selectionError,
      selectOrganization,
      confirmSelection,
      createNewOrganization,
    };
  },
});
</script>

<style scoped>
.organization-selection-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f9f9f9;
  flex-direction: column;
}

.organization-selection-container {
  width: 100%;
  max-width: 450px;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.title {
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 8px;
}

.subtitle {
  font-size: 14px;
  color: #888;
  text-align: center;
  margin-bottom: 20px;
}

.organization-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 12px;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s ease;
  border: 1px solid transparent;
  margin-bottom: 12px;
}

.organization-item:hover {
  background-color: #f0f0f0;
  border-color: #ddd;
}

.organization-details {
  display: flex;
  align-items: center;
}

.organization-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: #ececec;
  border-radius: 50%;
  margin-right: 12px;
  font-size: 20px;
}

.organization-info {
  display: flex;
  flex-direction: column;
}

.organization-name {
  font-size: 16px;
  font-weight: 500;
}

.organization-email {
  font-size: 12px;
  color: #888;
}

.selected-indicator {
  color: #18b67c;
}

.add-organization {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 16px;
  color: #18b67c;
}

.add-organization:hover {
  color: #159055;
}

.add-organization span {
  margin-left: 8px;
}

.error-message {
  color: red;
  font-size: 12px;
  text-align: center;
  margin-top: 10px;
}

.actions {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.submit-button {
  width: 100%;
}
</style>
