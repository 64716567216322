<template>
    <div class="text-component">
      <!-- Textarea to allow editing of the text content -->
      <textarea v-model="localText" @input="updateText" />
    </div>
  </template>
  
  <script>
  export default {
    name: 'TextComponent',
    props: {
      text: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        localText: this.text, // Local copy of the text prop for editing
      };
    },
    methods: {
      updateText() {
        // Emit the updated text value to the parent component
        this.$emit('update:text', this.localText);
      },
    },
  };
  </script>
  
  <style scoped>
  .text-component {
    padding: 10px;
    border: 1px solid #ccc;
  }
  textarea {
    width: 100%;
    height: 100px;
  }
  </style>
  