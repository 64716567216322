<template>
    <div class="server-select-container">
      <div class="header">
        <h2 class="title">Infrastructure</h2>
        <p class="subtitle">General information regarding your server instance</p>
      </div>
  
      <div class="server-select-board-container" @mousedown="onBoardMouseDown">
        <div class="server-select-board">
          <div class="server-card" ref="serverCard" @mousedown.stop="onMouseDown">
            <n-card class="server-info-card">
              <div class="card-content">
                <img src="@/assets/germany-flag.png" alt="Germany Flag" class="server-flag"/>
                <div class="server-details">
                  <div class="server-title">Primary Database</div>
                  <div class="server-location">East EU (Kaunas)</div>
                  <div class="server-specs">dbs • 1.0.1.outria</div>
                </div>
              </div>
            </n-card>
          </div>
        </div>
      </div>
  
      <!-- Section 1: Service Versions -->
      <div class="section service-versions">
        <h3>Service Versions</h3>
        <p>Information on your provisioned instance</p>
        <div class="version-info">
          <div>Auth version</div>
          <n-input value="2.158.1" disabled />
          <div>PostgREST version</div>
          <n-input value="12.2.2" disabled />
          <div>Postgres version</div>
          <n-input value="15.1.1.82" disabled />
        </div>
      </div>
  
      <!-- Section 2: CPU Utilization -->
      <div class="section cpu-utilization">
        <div class="cpu-info">
          <h3>CPU</h3>
          <p>Max CPU usage of your server</p>
          <p>Your server's CPU usage statistics and performance details.</p>
        </div>
        <div class="cpu-chart">
          <canvas id="cpuChart"></canvas>
        </div>
      </div>
  
      <!-- Section 3: Disk IO Bandwidth -->
      <div class="section disk-io">
        <div class="disk-info">
          <h3>Disk IO Bandwidth</h3>
          <p>The disk performance of your workload is determined by the Disk IO bandwidth.</p>
          <p>Your server's disk IO performance statistics and bandwidth usage details.</p>
        </div>
        <div class="disk-chart">
          <canvas id="diskChart"></canvas>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { Chart, registerables } from 'chart.js';
  
  Chart.register(...registerables);
  
  export default {
    name: "ServerSelect",
    mounted() {
      this.centerCard();
      this.renderCharts();
    },
    methods: {
      centerCard() {
        const card = this.$refs.serverCard;
        const board = this.$el.querySelector('.server-select-board');
  
        const boardRect = board.getBoundingClientRect();
        const cardRect = card.getBoundingClientRect();
  
        card.style.left = `${(boardRect.width - cardRect.width) / 2}px`;
        card.style.top = `${(boardRect.height - cardRect.height) / 2}px`;
      },
      onMouseDown(event) {
        const element = event.target.closest('.server-card');
        const offsetX = event.clientX - element.getBoundingClientRect().left;
        const offsetY = event.clientY - element.getBoundingClientRect().top;
  
        document.body.style.cursor = 'grabbing';
  
        const onMouseMove = (moveEvent) => {
          element.style.left = `${moveEvent.clientX - offsetX}px`;
          element.style.top = `${moveEvent.clientY - offsetY}px`;
        };
  
        const onMouseUp = () => {
          document.removeEventListener('mousemove', onMouseMove);
          document.removeEventListener('mouseup', onMouseUp);
          document.body.style.cursor = 'default';
        };
  
        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('mouseup', onMouseUp);
      },
      onBoardMouseDown(event) {
        let startX = event.clientX;
        let startY = event.clientY;
  
        document.body.style.cursor = 'grabbing';
  
        const onMouseMove = (moveEvent) => {
          const diffX = moveEvent.clientX - startX;
          const diffY = moveEvent.clientY - startY;
  
          this.$el.querySelector('.server-select-board-container').scrollLeft -= diffX;
          this.$el.querySelector('.server-select-board-container').scrollTop -= diffY;
  
          startX = moveEvent.clientX;
          startY = moveEvent.clientY;
        };
  
        const onMouseUp = () => {
          document.removeEventListener('mousemove', onMouseMove);
          document.removeEventListener('mouseup', onMouseUp);
          document.body.style.cursor = 'default';
        };
  
        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('mouseup', onMouseUp);
      },
      renderCharts() {
        const cpuCtx = document.getElementById('cpuChart').getContext('2d');
        const diskCtx = document.getElementById('diskChart').getContext('2d');
  
        new Chart(cpuCtx, {
          type: 'bar',
          data: {
            labels: ['16 Aug', '17 Aug', '18 Aug', '19 Aug', '20 Aug', '21 Aug', '22 Aug'],
            datasets: [{
              label: 'CPU Utilization (%)',
              data: [10, 15, 20, 5, 10, 25, 30],
              backgroundColor: '#000000'  // Changed to black
            }]
          },
          options: {
            scales: {
              y: {
                beginAtZero: true
              }
            }
          }
        });
  
        new Chart(diskCtx, {
          type: 'line',
          data: {
            labels: ['16 Aug', '17 Aug', '18 Aug', '19 Aug', '20 Aug', '21 Aug', '22 Aug'],
            datasets: [{
              label: 'Disk IO (Mbps)',
              data: [50, 60, 55, 70, 65, 75, 80],
              borderColor: '#000000',  // Changed to black
              fill: false
            }]
          },
          options: {
            scales: {
              y: {
                beginAtZero: true
              }
            }
          }
        });
      }
    }
  };
  </script>
  
  <style scoped>
  .server-select-container {
    padding: 20px;
    overflow-y: auto;
  }
  
  .header {
    margin-bottom: 20px;
  }
  
  .title {
    font-size: 24px;
    font-weight: 600;
    margin: 0;
  }
  
  .subtitle {
    font-size: 14px;
    color: #6b7280;
    margin: 0;
    padding-top: 4px;
  }
  
  .server-select-board-container {
    position: relative;
    height: 400px;
    border: 1px solid #cbd5e0;
    border-radius: 8px;
    background-color: #f8f9fa;
    overflow: hidden;
    cursor: grab;
    margin-bottom: 40px;
  }
  
  .server-select-board {
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: radial-gradient(circle, #d3d3d3 1px, transparent 1px);
    background-size: 20px 20px;
  }
  
  .server-card {
    position: absolute;
    width: 320px;
    cursor: grab;
    background: white;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 12px;
    transition: box-shadow 0.2s ease;
  }
  
  .server-card:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }
  
  .server-info-card {
    display: flex;
    align-items: center;
    padding: 12px;
  }
  
  .card-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  
  .server-flag {
    width: 36px; /* Adjusted size for better balance */
    height: auto;
    margin-right: 12px;
  }
  
  .server-details {
    flex-grow: 1;
  }
  
  .server-title {
    font-size: 18px;
    font-weight: 600;
  }
  
  .server-location,
  .server-specs {
    font-size: 14px;
    color: #6b7280;
  }
  
  .section {
    margin-bottom: 60px; /* Increased space between sections */
  }
  
  .service-versions,
  .cpu-utilization,
  .disk-io {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
  }
  
  .version-info {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 10px;
    width: 60%;
  }
  
  .cpu-info,
  .disk-info {
    width: 45%;
  }
  
  .cpu-chart,
  .disk-chart {
    width: 50%;
  }
  </style>
  