<template>
    <div class="emails-page">
      <!-- Show GoogleAuth component if not authorized -->
      <GoogleAuth v-if="!accessToken" @authorize="authorizeWithGoogle" />
    
      <!-- Other components go here, like showing emails once authorized -->
    </div>
  </template>
  
  <script>
  import GoogleAuth from '/src/components/GoogleAuth.vue'; // Import the GoogleAuth component
  import { supabase } from '../supabaseClient'; // Ensure correct import path for supabase client
  
  export default {
    components: {
      GoogleAuth,
    },
    data() {
      return {
        accessToken: null, // Access token
      };
    },
    methods: {
      async authorizeWithGoogle() {
        // Retrieve the session from Supabase
        const { data: session, error } = await supabase.auth.getSession();
        if (error || !session) {
          console.error("Supabase session not found, user needs to log in.");
          alert("Please log in first to proceed with authentication.");
          return;
        }
  
        // Extract the access token from the session
        const supabaseToken = session.access_token;
        if (!supabaseToken) {
          console.error("Supabase token not found.");
          return;
        }
  
        // Redirect to the backend's OAuth flow with the Supabase token
        const redirectUrl = process.env.NODE_ENV === 'production'
          ? `https://www.outria.com/auth/google?supabase_token=${supabaseToken}`
          : `http://localhost:3000/auth/google?supabase_token=${supabaseToken}`;
        
        // Redirect to initiate the OAuth flow
        window.location.href = redirectUrl;
      }
    },
    async created() {
      // You can try retrieving the session on component load if needed
      const { data: session, error } = await supabase.auth.getSession();
      if (error || !session) {
        console.error("Error retrieving session: ", error);
        return;
      }
  
      this.accessToken = session?.access_token || null;
    }
  };
  </script>
  
  <style scoped>
  .google-auth {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 60px;
    background-color: white;
    border-top: 1px solid #cbd5e0;
    border-bottom: 1px solid #cbd5e0;
    padding: 10px 20px;
    box-sizing: border-box;
  }
  
  .auth-button {
    display: flex;
    align-items: center;
    background-color: #4285F4;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 15px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .auth-button:hover {
    background-color: #357ae8;
  }
  
  .google-logo {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  </style>
  