<template>
  <div class="search-industries-container">
    <div class="search-industries" @scroll="handleScroll">
      <div class="advanced-search-filter">
        <input
          type="text"
          v-model="searchQuery"
          @input="handleInput"
          class="industry-input"
          placeholder="Search for an industry..."
        />

        <!-- Naive UI Loading Spinner -->
        <n-spin v-if="loading" size="large" class="loading-container" />

        <div v-if="error" class="error">{{ error }}</div>

        <transition-group name="fade" tag="ul" class="industry-list">
          <li v-for="industry in industries" :key="industry.id" class="industry-item">
            <div class="industry-icon">
              <span class="material-icons">business</span>
            </div>
            <div class="industry-info">
              <span class="industry-name">{{ capitalize(industry.name) }}</span>
              <span class="company-count">{{ industry.companiesCount }} Companies</span>
            </div>
          </li>
        </transition-group>

        <div v-if="!industries.length && !loading && !error" class="no-results">
          <img src="@/assets/no-folders-vector.jpg" alt="No Results" class="no-results-image" />
          <p class="no-results-text">No industries found.</p>
        </div>
      </div>
    </div>

    <div class="info-panel">
      <h1 class="info-header">Discover Industries</h1>
      <n-divider />
      <p class="info-description">
        <span class="material-icons description-icon">search</span>
        Use the search tool on the left to find industries by name from our comprehensive database. As you type, relevant industries will appear. Click on any industry to see the number of companies associated with it.
      </p>
      <p class="info-description">
        <span class="material-icons description-icon">keyboard</span>
        Try typing in your industry. Companies count apearing on the right side of industry are correct, they come directly from our database.
      </p>
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash';
import { NSpin } from 'naive-ui';
import NoResultsImage from '@/assets/no-folders-vector.jpg';

export default {
  components: {
    NSpin,
  },
  data() {
    return {
      searchQuery: 'Data', // Set the default value to "data"
      industries: [],
      loading: false,
      error: null,
      noResultsImage: NoResultsImage,
      page: 1,
      hasMore: true,
    };
  },
  methods: {
    handleInput() {
      this.page = 1;
      this.industries = [];
      this.hasMore = true;
      this.debouncedFetchIndustries();
    },
    async fetchIndustries() {
      if (!this.searchQuery || !this.hasMore) return;

      this.loading = true;
      this.error = null;

      try {
        const response = await fetch(
          `https://api.thecompaniesapi.com/v1/industries?token=x40fp1F4&search=${encodeURIComponent(this.searchQuery)}&page=${this.page}`
        );

        if (!response.ok) {
          throw new Error('Failed to fetch industries.');
        }

        const data = await response.json();

        if (data.industries.length) {
          this.industries = [...this.industries, ...data.industries];
          this.page += 1;
        } else {
          this.hasMore = false;
        }
      } catch (error) {
        this.error = error.message;
      } finally {
        this.loading = false;
      }
    },
    handleScroll() {
      const bottomReached = this.$el.scrollTop + this.$el.clientHeight >= this.$el.scrollHeight;

      if (bottomReached && !this.loading && this.hasMore) {
        this.fetchIndustries();
      }
    },
    capitalize(str) {
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    },
  },
  created() {
    this.debouncedFetchIndustries = debounce(this.fetchIndustries, 800);
    this.fetchIndustries(); // Trigger initial data fetch
  },
};
</script>

<style>
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

.pricing-page {
  font-family: 'Roboto', sans-serif;
}
.search-industries-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 100px;
}

.search-industries {
  width: 35%;
  height: 70vh;
  overflow-y: auto;
  background-color: #fff;
  padding: 15px;
  border-radius: 8px;
  border: 1px solid #ccc;
}

/* Apply custom scrollbar styles to search-industries */
.search-industries::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
}

.search-industries::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background of the scrollbar track */
  border-radius: 10px;
}

.search-industries::-webkit-scrollbar-thumb {
  background-color: #08956b; /* Scrollbar color */
  border-radius: 10px; /* Rounded edges for the scrollbar */
  border: 3px solid #f1f1f1; /* Space around the scrollbar */
}

.search-industries::-webkit-scrollbar-thumb:hover {
  background-color: #06644a; /* Darker scrollbar color on hover */
}

/* Firefox */
.search-industries {
  scrollbar-width: thin;
  scrollbar-color: #08956b #f1f1f1; /* thumb color, track color */
}

/* Edge and IE */
.search-industries {
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

/* Other existing styles remain unchanged */
.advanced-search-filter {
  display: flex;
  flex-direction: column;
}

.industry-input {
  width: 90%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 2px;
  margin-bottom: 15px;
  font-size: 0.9em;
}

.loading-container {
  display: flex;
  justify-content: center;
  margin: 10px 0;
}

.error {
  color: red;
  font-size: 0.9em;
  text-align: center;
}

.industry-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.industry-item {
  padding: 10px;
  display: flex;
  align-items: center;
  background-color: #f9f9f9;
  border-radius: 6px;
  margin-bottom: 10px;
  transition: opacity 0.5s, transform 0.5s;
}

.industry-icon {
  background-color: #08956b;
  padding: 8px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
}

.industry-icon .material-icons {
  color: #fff;
  font-size: 20px;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s, transform 0.5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
  transform: translateY(20px);
}

.industry-info {
  display: flex;
  flex-direction: column;
}

.industry-name {
  font-size: 1em;
  font-weight: bold;
  color: #333;
}

.company-count {
  font-size: 0.8em;
  color: #777;
}

.no-results {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
}

.no-results-image {
  width: 100px;
  height: auto;
  margin-bottom: 5px;
}

.no-results-text {
  font-size: 0.9em;
  color: #666;
  text-align: center;
}

.info-panel {
  width: 60%;
  padding-left: 20px;
}

.info-header {
  font-size: 3em;
  font-weight: bold;
  color: #08956b;
  margin-bottom: 20px;
}

.info-description {
  font-size: 1.2em;
  color: #666;
  line-height: 1.5em;
  display: flex;
  align-items: center;
}

.description-icon {
  margin-right: 8px;
  align-self: top;
  color: #08956b;
  font-size: 24px;
}
</style>
