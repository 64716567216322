<template>
  <section class="hero-section">
    <div class="grid-overlay"></div>
    <div class="hero-content">
      <p class="Tag"></p>
      <h1 class="hero-title">
        Reach <span class="changing-text">{{ currentText }}</span> Efficiently
      </h1>
      <h2 class="sub-title">
        with <span class="highlight">Outria</span>
      </h2>
      <p class="hero-description">
        Whether you are a business looking for clients or exploring competition.
      </p>
      <div class="cta-buttons">
        <a href="/signup" class="cta-button get-started">
          Join for Free!
        </a>
        <button @click="scrollToSection('ToolFrontEnd')" class="cta-button demo">
          Demo
        </button>
      </div>
    </div>

    <!-- Randomly Positioned Company Logos -->
    <div class="logo-boxes">
      <div class="logo-box" style="top: 20%; left: 23%; width: 40px; height: 40px; opacity: 60%">
        <img src="@/assets/logo_1.png" alt="Company Logo 1" />
      </div>
      <div class="logo-box" style="top: 45%; left: 30%;">
        <img src="@/assets/logo_2.png" alt="Company Logo 2" />
      </div>
      <div class="logo-box" style="top: 85%; left: 70%;width: 60px; height: 60px; opacity: 80%">
        <img src="@/assets/logo_3.png" alt="Company Logo 3" />
      </div>
      <div class="logo-box" style="top: 30%; right: 26%; width: 50px; height: 50px; opacity: 100%">
        <img src="@/assets/logo_4.png" alt="Company Logo 4" />
      </div>
      <div class="logo-box" style="top: 80%; right: 70%; width: 40px; height: 40px; opacity: 90%">
        <img src="@/assets/logo_5.png" alt="Company Logo 5" />
      </div>
    </div>
    <div class="statistics-container">
      <StatisticsSection />
      <TrustBadge />
    </div>
  </section>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue';
import StatisticsSection from './StatisticsSection.vue';
import TrustBadge from './TrustBadge.vue';

export default defineComponent({
  name: 'HeroSection',
  components: {
    StatisticsSection,
    TrustBadge,
  },
  setup() {
    const words = ['Companies', 'Clients', 'Competitors'];
    const currentText = ref('');
    let wordIndex = 0;
    let charIndex = 0;
    let isDeleting = false;

    const type = () => {
      const currentWord = words[wordIndex];
      if (isDeleting) {
        charIndex--;
        currentText.value = currentWord.substring(0, charIndex);
        if (charIndex === 0) {
          isDeleting = false;
          wordIndex = (wordIndex + 1) % words.length;
        }
      } else {
        charIndex++;
        currentText.value = currentWord.substring(0, charIndex);
        if (charIndex === currentWord.length) {
          setTimeout(() => { isDeleting = true; }, 1000);
        }
      }
      setTimeout(type, isDeleting ? 100 : 200);
    };

    onMounted(() => {
      type();
    });

    const scrollToSection = (id) => {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    };

    return {
      currentText,
      scrollToSection
    };
  },
});
</script>

<style scoped>
.hero-section {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 80vh;
  text-align: center;
  padding: 1rem;
  overflow: hidden;
  background: radial-gradient(circle at center, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 30%, rgba(255, 255, 255, 0) 100%);
  z-index: 1;
}

.grid-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: 40px 40px;
  background-image: linear-gradient(to right, #eeeeee 1px, transparent 1px),
                    linear-gradient(to bottom, #eeeeee 1px, transparent 1px);
  z-index: 0;
  mask-image: radial-gradient(circle at center, rgba(255, 255, 255, 0) 30%, rgba(255, 255, 255, 1) 70%);
}

.hero-content {
  position: relative;
  z-index: 1;
  max-width: 800px;
}

.hero-title {
  font-size: 3.2rem;
  color: #333;
  font-weight: bold;
  margin-bottom: 0.2rem;
  line-height: 1.2;
}

.sub-title {
  font-size: 3.2rem;
  color: #333;
  font-weight: bold;
  margin-top: 0.2rem;
  line-height: 1.2;
}

.highlight {
  background: linear-gradient(to right, #3ecda0, #129b59);
  -webkit-background-clip: text;
  color: transparent;
  font-weight: bold;
}

.changing-text {
  display: inline-block;
  border-right: 2px solid #3ecda0;
  white-space: nowrap;
  overflow: hidden;
  animation: blink 1s step-end infinite;
  font-weight: bold;
  background: linear-gradient(to right, #3ecda0, #047865);
  -webkit-background-clip: text;
  color: transparent;
  vertical-align: bottom;
}

@keyframes blink {
  0%, 100% {
    border-color: transparent;
  }
  50% {
    border-color: #3ecda0;
  }
}

.hero-description {
  color: #666;
  margin-bottom: 1.5rem;
  justify-content: center;
}

.cta-buttons {
  margin-top: 1rem;
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.cta-button {
  padding: 0.5rem 1rem;
  border-radius: 0.4rem;
  font-weight: medium;
  text-decoration: none;
  display: inline-block;
  transition: transform 0.3s ease;
  z-index: 10;
  cursor: pointer;
  pointer-events: auto;
}

.cta-button:hover {
  transform: scale(1.05);
}

.get-started {
  background-color: #219f73;
  color: white;
}

.demo {
  border: 2px solid #2e2e2e;
  color: #2c2c2c;
}

.Tag {
  color: #222222;
  font-weight: bold;
}

.logo-boxes {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
  pointer-events: none; /* Prevent logos from interfering with other interactions */
}

.logo-box {
  position: absolute;
  width: 60px;
  height: 60px;
  background-color: #ffffff;
  border:#cbd5e0 1px solid;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.logo-box img {
  max-width: 50%;
  max-height: 50%;
}

.statistics-container {
  position: relative;
  z-index: 2;
  margin-top: 0.0rem;
  padding: 2rem;
  width: 100%;
  max-width: 1200px;
}
</style>
