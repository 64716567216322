<template>
  <NavBar/>
    <HomeLayout>
      <div class="blog-post-layout">
        <component :is="postComponent" />
      </div>
    </HomeLayout>
    <FooterSection/>
  </template>
  
  <script>
 import NavBar from '@/components/NavBar.vue';
 import FooterSection from '@/components/FooterSection.vue';
  
  export default {
    components: {
    NavBar,
    FooterSection,
  },
    data() {
      return {
        postComponent: null,
      };
    },
    async created() {
      const postName = this.$route.params.postName;
      try {
        // Dynamically import the blog post component
        this.postComponent = (await import(`@/posts/${postName}.vue`)).default;
      } catch (e) {
        console.error('Failed to load blog post:', e);
        this.$router.push('/knowledge'); // Redirect to knowledge page if post not found
      }
    },
  };
  </script>
  
  <style scoped>
  .blog-post-layout {
    padding: 80px;
  }
  </style>
  