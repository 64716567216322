<template>
  <div class="feedback-wrapper" v-click-outside="closeFeedback">
    <!-- Feedback Button -->
    <n-button @click="toggleFeedback" size="small" class="feedback-button">Feedback</n-button>
    <transition name="fade">
      <n-card v-if="showFeedback" class="feedback-card">
        <n-space vertical class="feedback-space">
          <n-input
            type="textarea"
            placeholder="Your feedback"
            v-model.trim="feedbackText" 
            class="feedback-input"
          />
          <n-button
            type="default"
            block
            :loading="loading"
            @click="submitFeedback"
          >Send Feedback</n-button>
          <p v-if="errorMessage" class="error-text">{{ errorMessage }}</p>
          <p v-if="successMessage" class="success-text">{{ successMessage }}</p>
          <p class="support-text">Have a technical issue? Contact support or browse our docs.</p>
        </n-space>
      </n-card>
    </transition>
  </div>
</template>

<script>
import { ref } from 'vue';
import { NButton, NCard, NSpace, NInput } from 'naive-ui';
import clickOutside from '@/directives/clickOutside';
import { supabase } from '@/supabaseClient';

export default {
  name: 'FeedbackButton',
  components: {
    NButton,
    NCard,
    NSpace,
    NInput,
  },
  directives: {
    clickOutside,
  },
  setup() {
    const showFeedback = ref(false);
    const feedbackText = ref(''); // Store feedback text
    const loading = ref(false); // Show loading state
    const errorMessage = ref('');
    const successMessage = ref('');

    const toggleFeedback = () => {
      showFeedback.value = !showFeedback.value;
      resetForm(); // Clear error/success messages when toggling
    };

    const closeFeedback = () => {
      showFeedback.value = false;
      resetForm();
    };

    const resetForm = () => {
      feedbackText.value = ''; // Reset feedback text
      errorMessage.value = '';
      successMessage.value = '';
    };

    const submitFeedback = async () => {
      // Validate feedback text
      if (!feedbackText.value || feedbackText.value.trim() === '') {
        errorMessage.value = 'Feedback cannot be empty.';
        return;
      }

      try {
        loading.value = true;
        errorMessage.value = '';
        successMessage.value = '';
        
        // Get the current user session
        const { data: session } = await supabase.auth.getSession();
        const user = session?.session?.user;

        if (!user) {
          errorMessage.value = 'You need to be logged in to submit feedback.';
          loading.value = false;
          return;
        }

        // Insert feedback into Supabase
        const { error } = await supabase.from('feedback').insert([
          {
            user_id: user.id, // User ID from the Supabase session (UUID)
            feedback_text: feedbackText.value.trim(), // Trim feedback text
          },
        ]);

        if (error) {
          errorMessage.value = 'Failed to submit feedback. Please try again.';
        } else {
          successMessage.value = 'Feedback submitted successfully!';
          resetForm(); // Reset form after successful submission
        }
      } catch (error) {
        errorMessage.value = 'An unexpected error occurred. Please try again.';
      } finally {
        loading.value = false;
      }
    };

    return {
      showFeedback,
      feedbackText,
      loading,
      errorMessage,
      successMessage,
      toggleFeedback,
      closeFeedback,
      submitFeedback,
    };
  },
};
</script>

<style scoped>
.feedback-wrapper {
  display: inline-block;
  position: relative;
  margin-left: 16px;
}

.feedback-button {
  cursor: pointer;
  z-index: 1002;
}

.feedback-card {
  position: absolute;
  top: 100%;
  right: 0;
  width: 250px;
  z-index: 1001;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 8px;
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.feedback-space {
  padding: 0;
}

.feedback-input {
  margin-bottom: 8px;
}

.support-text {
  font-size: 12px;
  color: #666;
  margin-top: 4px;
  text-align: center;
}

.error-text {
  color: red;
  font-size: 12px;
  text-align: center;
}

.success-text {
  color: green;
  font-size: 12px;
  text-align: center;
}
</style>
