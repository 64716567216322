<template>
    <div>
      <YourMarketMap />
    </div>
  </template>
  
  <script>
  import YourMarketMap from '@/components/YourMarketMap.vue';
  
  export default {
    components: {
      YourMarketMap
    }
  };
  </script>
  