<template>
    <div
      class="note-card"
      :class="{ selected: isSelected, editing: isEditing }"
      :style="{ width: width + 'px', height: height + 'px', top: position.top + 'px', left: position.left + 'px' }"
      @mousedown="startDragging"
      @dblclick="toggleEditing"
      @click.stop="selectCard"
    >
      <!-- Editable content -->
      <div
        v-if="isEditing"
        class="note-content-editable"
        contenteditable="true"
        @mousedown.stop
        @blur="disableEditing"
        @input="updateContent"
      >
        {{ content }}
      </div>
  
      <!-- Non-editable content -->
      <div v-else class="note-content">{{ content }}</div>
  
      <!-- Resize handle -->
      <div class="resize-handle" @mousedown.stop="startResizing"></div>
    </div>
  </template>
  
  <script>
  import { ref, onMounted, onBeforeUnmount } from 'vue';
  
  export default {
    name: 'NoteDrag',
    props: {
      initialTop: {
        type: Number,
        default: 100,
      },
      initialLeft: {
        type: Number,
        default: 100,
      },
      initialWidth: {
        type: Number,
        default: 200,
      },
      initialHeight: {
        type: Number,
        default: 100,
      },
    },
    setup(props) {
      const isDragging = ref(false);
      const isResizing = ref(false);
      const isSelected = ref(false);
      const isEditing = ref(false);
      const position = ref({ top: props.initialTop, left: props.initialLeft });
      const width = ref(props.initialWidth);
      const height = ref(props.initialHeight);
      const content = ref('Double click to edit...');
  
      const lastMousePosition = ref({ x: 0, y: 0 });
  
      // Global event listener to deselect when clicking outside
      const handleClickOutside = (event) => {
        if (!event.target.closest('.note-card')) {
          isSelected.value = false;
          isEditing.value = false;
        }
      };
  
      onMounted(() => {
        window.addEventListener('click', handleClickOutside);
      });
  
      onBeforeUnmount(() => {
        window.removeEventListener('click', handleClickOutside);
      });
  
      // Dragging logic
      const startDragging = (event) => {
        if (!isEditing.value) {
          isDragging.value = true;
          isSelected.value = true; // Automatically select the card when dragging starts
          lastMousePosition.value = { x: event.clientX, y: event.clientY };
          window.addEventListener('mousemove', onDrag);
          window.addEventListener('mouseup', stopDragging);
        }
      };
  
      const onDrag = (event) => {
        if (isDragging.value && !isEditing.value) {
          const deltaX = event.clientX - lastMousePosition.value.x;
          const deltaY = event.clientY - lastMousePosition.value.y;
  
          position.value.top += deltaY;
          position.value.left += deltaX;
  
          lastMousePosition.value = { x: event.clientX, y: event.clientY };
        }
      };
  
      const stopDragging = () => {
        isDragging.value = false;
        window.removeEventListener('mousemove', onDrag);
        window.removeEventListener('mouseup', stopDragging);
      };
  
      // Resizing logic
      const startResizing = (event) => {
        isResizing.value = true;
        lastMousePosition.value = { x: event.clientX, y: event.clientY };
        window.addEventListener('mousemove', onResize);
        window.addEventListener('mouseup', stopResizing);
      };
  
      const onResize = (event) => {
        if (isResizing.value) {
          const deltaX = event.clientX - lastMousePosition.value.x;
          const deltaY = event.clientY - lastMousePosition.value.y;
  
          width.value = Math.max(100, width.value + deltaX); // Set minimum width to 100px
          height.value = Math.max(50, height.value + deltaY); // Set minimum height to 50px
  
          lastMousePosition.value = { x: event.clientX, y: event.clientY };
        }
      };
  
      const stopResizing = () => {
        isResizing.value = false;
        window.removeEventListener('mousemove', onResize);
        window.removeEventListener('mouseup', stopResizing);
      };
  
      // Editing logic
      const toggleEditing = () => {
        if (!isEditing.value) {
          enableEditing();
        } else {
          disableEditing();
        }
      };
  
      const enableEditing = () => {
        isEditing.value = true;
      };
  
      const disableEditing = () => {
        isEditing.value = false;
      };
  
      const updateContent = (event) => {
        content.value = event.target.innerText;
      };
  
      // Select the card when clicked
      const selectCard = () => {
        isSelected.value = true;
      };
  
      return {
        position,
        width,
        height,
        isSelected,
        isEditing,
        content,
        startDragging,
        startResizing,
        enableEditing,
        disableEditing,
        toggleEditing,
        selectCard,
        updateContent,
      };
    },
  };
  </script>
  
  <style scoped>
  .note-card {
    position: absolute;
    background-color: white;
    border: 1px solid #ddd;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 10px;
    cursor: grab; /* Hand cursor to indicate moveability */
    transition: border 0.2s ease;
    overflow: hidden;
    user-select: none; /* Prevent text selection while not editing */
  }
  
  .note-card.selected {
    border: 2px solid #3ecda0; /* Green border when selected */
  }
  
  .note-card.editing {
    cursor: text;
    user-select: text; /* Allow text selection when editing */
  }
  
  .note-content {
    width: 100%;
    height: 100%;
    white-space: pre-wrap;
    word-wrap: break-word;
  }
  
  .note-content-editable {
    width: 100%;
    height: 100%;
    outline: none; /* Remove black border when editing */
    white-space: pre-wrap;
    word-wrap: break-word;
  }
  
  .resize-handle {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 15px;
    height: 15px;
    background-color: #ddd;
    cursor: nwse-resize;
    border-bottom-right-radius: 3px;
  }
  </style>
  