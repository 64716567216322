<template>
    <div class="company-page">
      <!-- Navbar at the top -->
      <NavBar />
  
      <!-- Main page layout with sidebar and content -->
      <div class="page-layout">
        <!-- Sidebar -->
        <div class="sidebar">
          <div class="menu-section">
            <h3 class="menu-section-title">Company Information</h3>
            <n-button block text @click="scrollToSection('header')" class="menu-item">
              Overview
            </n-button>
            <n-button block text @click="scrollToSection('company-details')" class="menu-item">
              Company Details
            </n-button>
            <n-button block text @click="scrollToSection('location')" class="menu-item">
              Location
            </n-button>
            <n-button block text @click="scrollToSection('technologies')" class="menu-item">
              Technologies
            </n-button>
            <n-divider class="divider" />
          </div>
        </div>
  
        <!-- Main content -->
        <div class="main-content">
          <n-card v-if="company" class="company-card" bordered>
            <!-- Company Header with Logo and Social Media -->
            <div id="header" class="company-header">
              <div class="company-logo-section">
                <div class="logo-background">
                  <img :src="company.logo" alt="Company Logo" class="company-logo" />
                </div>
                <div class="company-info">
                  <h1>{{ company.name }}</h1>
                  <p class="company-description">
                    {{ shortDescription }}
                    <span v-if="showReadMore" class="read-more" @click="toggleDescription">Read more</span>
                    <span v-else>{{ company.description }}</span>
                  </p>

                  <n-card id="social-media" title="Connect with us" class="social-media-card" size="large">
  <div class="social-icons">
    <a v-if="company.social_networks.facebook" :href="company.social_networks.facebook" target="_blank">
      <i class="fab fa-facebook-f"></i>
    </a>
    <a v-if="company.social_networks.twitter" :href="company.social_networks.twitter" target="_blank">
      <i class="fab fa-twitter"></i>
    </a>
    <a v-if="company.social_networks.linkedin" :href="company.social_networks.linkedin" target="_blank">
      <i class="fab fa-linkedin-in"></i>
    </a>
    <a v-if="company.social_networks.instagram" :href="company.social_networks.instagram" target="_blank">
      <i class="fab fa-instagram"></i>
    </a>
    <a v-if="company.social_networks.github" :href="company.social_networks.github" target="_blank">
      <i class="fab fa-github"></i>
    </a>
    <a v-if="company.social_networks.youtube" :href="company.social_networks.youtube" target="_blank">
      <i class="fab fa-youtube"></i>
    </a>
    <a v-if="company.social_networks.pinterest" :href="company.social_networks.pinterest" target="_blank">
      <i class="fab fa-pinterest"></i>
    </a>
  </div>
</n-card>




                </div>
              </div>
            </div>
  
            <!-- Company Details Section -->
            <n-card id="company-details" title="Company Details" class="company-details-card" size="large">
              <div class="company-stats">
                <div class="company-stat"><strong>Website:</strong> <a :href="'https://' + company.domain" target="_blank">{{ company.domain }}</a></div>
                <div class="company-stat"><strong>Employees:</strong> {{ company.total_employees_exact || 'N/A' }}</div>
                <div class="company-stat"><strong>Founded:</strong> {{ company.year_founded || 'N/A' }}</div>
                <div class="company-stat"><strong>Phone:</strong> <a :href="'tel:' + company.phone_number">{{ company.phone_number || 'N/A' }}</a></div>
                <div class="company-stat"><strong>Revenue:</strong> {{ company.revenue || 'N/A' }}</div>
                <div class="company-stat"><strong>Industry:</strong> {{ company.industry_main || 'N/A' }}</div>
                <div class="company-stat"><strong>Monthly Visitors:</strong> {{ company.monthly_visitors || 'N/A' }}</div>
              </div>
            </n-card>
  
            <!-- Location Section with Map -->
            <n-card id="location" title="Location" class="location-card" size="large">
              <div class="company-stats">
                <div class="company-stat"><strong>City:</strong> {{ company.city?.name || 'N/A' }}, {{ company.city?.postcode || '' }}</div>
                <div class="company-stat"><strong>Country:</strong> {{ company.country?.name || 'N/A' }}</div>
                <div class="company-stat"><strong>State:</strong> {{ company.state?.name || 'N/A' }}</div>
              </div>
              <div id="map" class="map-container"></div>
            </n-card>
  
            <!-- Technology Stack Section -->
            <n-card id="technologies" title="Technologies" class="tech-stack-card" size="large">
              <div class="company-stats tech-stack">
                <n-tag v-for="tech in company.technologies" :key="tech" type="success">{{ tech }}</n-tag>
              </div>
            </n-card>
          </n-card>
  
          <div v-else-if="loading">Loading company data...</div>
          <div v-else-if="error">{{ error }}</div>
        </div>
  
        <!-- Right-side container for future ads -->
        <div class="ads-container"></div>
      </div>
  
      <!-- Footer at the bottom -->
      <FooterSection />
    </div>
  </template>
  
  <script>
  import { supabase } from '@/supabaseClient';
  import NavBar from '@/components/NavBar.vue';
  import FooterSection from '@/components/FooterSection.vue';
  import 'leaflet/dist/leaflet.css';
  import L from 'leaflet';
  import { NCard, NTag, NButton, NDivider } from 'naive-ui';
  
  export default {
    components: {
      NavBar,
      FooterSection,
      NCard,
      NTag,
      NButton,
      NDivider,
    },
    data() {
      return {
        company: null,
        error: '',
        loading: true,
        showReadMore: true,
      };
    },
    computed: {
      shortDescription() {
        return this.company?.description?.substring(0, 100) || '';
      }
    },
    methods: {
      toggleDescription() {
        this.showReadMore = !this.showReadMore;
      },
      scrollToSection(id) {
        const section = document.getElementById(id);
        if (section) {
          section.scrollIntoView({ behavior: 'smooth' });
        }
      },
      initMap() {
        if (this.company.city && this.company.city.latitude && this.company.city.longitude) {
          const map = L.map('map').setView([this.company.city.latitude, this.company.city.longitude], 13);
          L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '&copy; OpenStreetMap contributors'
          }).addTo(map);
          L.marker([this.company.city.latitude, this.company.city.longitude]).addTo(map)
            .bindPopup(`${this.company.city.name}`)
            .openPopup();
        }
      }
    },
    async mounted() {
      const companyName = this.$route.params.name;
  
      if (!companyName) {
        this.error = 'No company name provided.';
        this.loading = false;
        return;
      }
  
      try {
        const { data: companyFromSupabase, error: fetchError } = await supabase
          .from('companies')
          .select('*')
          .ilike('name', `%${companyName}%`)
          .single();
  
        if (fetchError || !companyFromSupabase) {
          this.error = `Company data for ${companyName} not found in Supabase.`;
        } else {
          this.company = companyFromSupabase;
          this.$nextTick(() => {
            this.initMap();
          });
        }
      } catch (error) {
        this.error = 'An error occurred while fetching company data.';
      } finally {
        this.loading = false;
      }
    },
    head() {
      return {
        title: this.company 
          ? `${this.company.name} - Company Profile, Funding, Contact` 
          : 'Company Information',
        meta: [
          {
            name: 'description',
            content: this.company
              ? `Learn more about ${this.company.name}, including their technologies, revenue, and contact information.`
              : 'Company information page',
          },
          {
            property: 'og:title',
            content: this.company 
              ? `${this.company.name} - Company Profile, Funding, Contact`
              : 'Company Information',
          },
          {
            property: 'og:description',
            content: this.company
              ? `Detailed information on ${this.company.name}, including revenue, employees, and contact details.`
              : 'Company information page.',
          },
        ],
      };
    }
  };
</script>

  
<style scoped>
  .page-layout {
    display: flex;
    justify-content: space-between;
    min-height: 100vh;
    margin-top: 50px;
  }

  .sidebar {
    width: 220px;
    background-color: white;
    border-right: 1px solid #cbd5e0;
    padding-top: 20px;
    position: fixed;
    top: 60px;
    bottom: 0;
    left: 0;
    box-sizing: border-box;
    overflow-y: auto;
  }

  .menu-section-title {
    font-size: 12px;
    font-weight: 600;
    color: #6b7280;
    margin: 0 20px 10px 20px;
    text-transform: uppercase;
  }

  .menu-item {
    text-align: left;
    padding: 8px 20px;
    color: #1f2937;
    font-size: 14px;
    font-weight: 400;
    border-radius: 4px;
    margin: 2px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .menu-item:hover {
    background-color: #f3f4f6;
  }

  .divider {
    margin: 20px 0;
    border-color: #cbd5e0;
  }

  .main-content {
    width: 60%;
    margin-left: 240px; /* Adjusted to leave space for sidebar */
  }

  .company-header {
    display: flex;
    align-items: center;
    padding: 20px;
    border-radius: 8px;
    background-color: white;
    border-bottom: 1px solid #cbd5e0;
  }

  .company-logo {
    width: 80px;
    height: 80px;
    object-fit: contain;
    border-radius: 8px;
  }

  .company-info {
    margin-left: 20px;
  }

  .company-info h1 {
    font-size: 24px;
    margin: 0;
    color: #1f2937;
  }

  .company-description {
    color: #4a5568;
    margin-top: 10px;
  }

  .read-more {
    color: #08956b;
    text-decoration: underline;
    cursor: pointer;
  }

  .social-media-card {
  margin-top: 20px;
  padding: 15px;
  text-align: center;
  background-color: #f9f9f9;
  border-radius: 8px;
}

.social-icons {
  display: flex;
  justify-content: center;
  gap: 15px;
  flex-wrap: wrap;
}

.social-icons a {
  font-size: 1.5rem;
  color: #08956b;
  transition: color 0.3s;
  padding: 10px;
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid #e5e5e5;
}

.social-icons a:hover {
  color: #005f45;
  background-color: #e6f7f5;
}


  /* Card Sections */
  .company-details-card,
  .location-card,
  .tech-stack-card {
    margin-top: 20px;
    background-color: white;
    border: 1px solid #e5e7eb;
    border-radius: 8px;
  }

  .company-stat {
    padding: 10px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e5e7eb;
  }

  /* Map Styling */
  .map-container {
    height: 400px;
    width: 100%;
    margin-top: 10px;
  }

  /* Ads Container */
  .ads-container {
    width: 220px;
    margin-left: 20px;
  }
</style>

  