<template>
  <div class="pricing-page">
    <div class="section-container">
      <div class="content-container">
        <!-- Left Column with Header -->
        <div class="left-column">
          <h3 class="pricing-header">Pay as you go</h3>
          <p class="start-free-text">You can start for free</p>
        </div>
        <!-- Right Column with Text Content -->
        <div class="right-column">
          <ul>
            <li>Control your budget on your own terms.</li>
            <li>Didn't get the results you were expecting? Appeal your campaign and under the right circumstances get refunded in 24 hours.</li>
            <li>Create your own Organization and manage your team's budget.</li>
            <li>See the cost of the campaign before you even query.</li>
          </ul>
          <div class="pricing-details">
            <p><strong>Custom pricing is available for companies with large billing volume or unique business models.</strong></p>
            <a href="#" class="cta-button">Start for free &gt;</a>
          </div>
        </div>
      </div>

      <!-- Floating Notifications on Right -->
      <div class="notifications-container right">
        <div class="notification">
          <span class="notification-text">233 new companies found</span>
          <span class="notification-price">$2.23</span>
        </div>
        <div class="notification">
          <span class="notification-text">12 new employees found</span>
          <span class="notification-price">$0.38</span>
        </div>
        <div class="notification">
          <span class="notification-text">9 similar companies found</span>
          <span class="notification-price">$0.49</span>
        </div>
      </div>
      <!-- Floating Notifications on Left -->
      <div class="notifications-container left">
        <div class="notification">
          <span class="notification-text">5 new prospects added</span>
          <span class="notification-price">$0.15</span>
        </div>
        <div class="notification">
          <span class="notification-text">7 new leads generated</span>
          <span class="notification-price">$0.21</span>
        </div>
      </div>
      <!-- Randomly Positioned Company Logos -->
      <div class="logo-boxes">
        <div class="logo-box" style="top: 20%; left: 23%; width: 40px; height: 40px; opacity: 60%">
          <img src="@/assets/logo_1.png" alt="Company Logo 1" />
        </div>
        <div class="logo-box" style="top: 45%; left: 25%; width: 60px; height: 60px; opacity: 80%">
          <img src="@/assets/logo_2.png" alt="Company Logo 2" />
        </div>
        <div class="logo-box" style="top: 85%; left: 70%; width: 60px; height: 60px; opacity: 80%">
          <img src="@/assets/logo_3.png" alt="Company Logo 3" />
        </div>
        <div class="logo-box" style="top: 30%; right: 26%; width: 50px; height: 50px; opacity: 100%">
          <img src="@/assets/logo_4.png" alt="Company Logo 4" />
        </div>
        <div class="logo-box" style="top: 80%; right: 10%; width: 40px; height: 40px; opacity: 90%">
          <img src="@/assets/logo_5.png" alt="Company Logo 5" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PricingPage',
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

.pricing-page {
  font-family: 'Roboto', sans-serif;
}

.section-container {
  display: flex;
  padding: 40px 0;
  border-top: 1px solid #d5d5d5; /* Green top border */
  border-bottom: 1px solid #d5d5d5; /* Green bottom border */
  position: relative;
  overflow: hidden;
}

.section-container::before, .section-container::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100vw;
  border-right: 0px solid #219f73; /* Green right border */
  border-left: 0px solid #219f73; /* Green left border */
}

.section-container::before {
  left: -100vw; /* Extend to the left */
}

.section-container::after {
  right: -100vw; /* Extend to the right */
}

.content-container {
  display: flex;
  flex-direction: column; /* Make text content stack vertically */
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  align-items: center; /* Center the content */
  text-align: center; /* Center align the text */
}

.left-column {
  flex: 1;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center; 
}

.pricing-header {
  font-size: 2rem;
  font-weight: 700;
  color: #333;
  text-align: center;
  margin-bottom: 0.5rem;
}

.start-free-text {
  color: #219f73; /* Green color for "You can start for free" */
  font-weight: 500;
  text-align: center;
  margin-bottom: 1rem;
}

.right-column {
  flex: 2;
  padding-left: 20px;
  width: 100%; /* Ensure the content takes full width */
}

.right-column ul {
  padding: 0;
  list-style: none;
  text-align: left; /* Keep the bullet points aligned left */
  display: inline-block; /* Ensure ul takes content width */
  max-width: 600px; /* Limit the width for better readability */
}

.right-column ul li {
  margin-bottom: 10px;
  padding-left: 20px;
  position: relative;
}

.right-column ul li::before {
  content: '✔';
  position: absolute;
  left: 0;
  color: #219f73;
}

.pricing-details {
  margin-top: 20px;
  text-align: center;
}

.cta-button {
  display: inline-block;
  padding: 10px 15px;
  color: #ffffff;
  background-color: #219f73;
  border-radius: 4px;
  text-decoration: none;
  font-weight: 500;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #1e7a60;
}

/* Notifications and Logos */
.notifications-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 10;
}

.notifications-container.right {
  top: 100px;
  right: 20px;
}

.notifications-container.left {
  top: 200px;
  left: 20px;
}

.notification {
  background-color: #fff;
  border:#cbd5e0 1px solid;
  border-radius: 5px;
  padding: 10px 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 250px;
  font-size: 14px;
}

.notification-text {
  font-weight: 500;
  color: #333;
}

.notification-price {
  font-weight: 600;
  color: #219f73;
  font-size: 12px;
}

.logo-boxes {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
  pointer-events: none; /* Prevent logos from interfering with other interactions */
}

.logo-box {
  position: absolute;
  width: 60px;
  height: 60px;
  background-color: #ffffff;
  border:#cbd5e0 1px solid;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.logo-box img {
  max-width: 50%;
  max-height: 50%;
}
</style>
