<template>
  <HomeLayout>
    <div class="knowledge-page">
      <!-- Content Area -->
      <div class="content-area">
        <!-- Banner Section -->
        <div class="knowledge-banner">
          <div class="search-container">
            <h1 class="banner-title">How can we help?</h1>
            <div class="search-bar">
              <n-input class="search-input" placeholder="Search Beacon, Docs, Reports, etc." clearable>
                <template #suffix>
                  <n-icon>
                    <i class="material-icons">search</i>
                  </n-icon>
                </template>
              </n-input>
              <n-button type="primary" class="search-button">Search</n-button>
            </div>
          </div>
        </div>

        <!-- Blog Posts Section -->
        <div class="blog-posts-section">
          <h2 class="section-title">Latest Blog Posts</h2>
          <div class="blog-grid">
            <n-card
              v-for="post in posts"
              :key="post.slug"
              class="blog-card"
              hoverable
              @click="navigateTo(post.slug)"
            >
              <img v-if="post.image" :src="post.image" alt="Post Image" class="blog-image" />
              <div class="blog-card-content">
                <h3 class="blog-title">{{ post.title }}</h3>
                <p class="blog-date">{{ post.date }}</p>
                <p class="blog-description">{{ post.description }}</p>
              </div>
            </n-card>
          </div>
        </div>
      </div>
    </div>
  </HomeLayout>
</template>

<script>
import { ref } from 'vue';
import { NButton, NInput, NIcon, NCard } from 'naive-ui';
import { useRouter } from 'vue-router';
import HomeLayout from '@/layouts/HomeLayout.vue';

export default {
  components: {
    NButton,
    NInput,
    NIcon,
    NCard,
    HomeLayout,
  },
  setup() {
    const router = useRouter();
    const posts = ref([]);

    // Dynamically load all posts from the 'posts' folder
    const requirePost = require.context('@/posts', false, /\.vue$/);

    posts.value = requirePost.keys().map(file => {
      const postModule = requirePost(file).default;

      return {
        title: postModule.title || 'Untitled Post',
        description: postModule.description || 'No description available.',
        date: postModule.date || 'No date available.',
        image: postModule.image || null,
        slug: file.replace('./', '').replace('.vue', ''), // Create slug from the filename
      };
    });

    const navigateTo = (slug) => {
      router.push({ name: 'BlogPost', params: { postName: slug } });
    };

    return {
      posts,
      navigateTo,
    };
  },
};
</script>

<style scoped>
.knowledge-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #f9fafb;
}

/* Content Area */
.content-area {
  flex-grow: 1;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Banner Section */
.knowledge-banner {
  width: 100%;
  height: 200px;
  background-image: url('@/assets/KnowledgeBackground.png');
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}

.search-container {
  text-align: center;
}

.banner-title {
  color: white;
  font-size: 32px;
  margin-bottom: 20px;
}

.search-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.search-input {
  width: 400px;
  border-radius: 4px;
  padding: 10px;
}

.search-button {
  padding: 10px 20px;
}

/* Blog Posts Section */
.blog-posts-section {
  padding: 40px;
  background-color: #ffffff;
}

.section-title {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
}

/* Blog Post Cards Grid */
.blog-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

/* Blog Card */
.blog-card {
  padding: 0;
  cursor: pointer;
  border-radius: 8px;
  transition: transform 0.3s ease;
}

.blog-card:hover {
  transform: scale(1.02);
}

.blog-image {
  width: 100%;
  height: 180px;
  object-fit: cover;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.blog-card-content {
  padding: 20px;
  text-align: left;
}

.blog-title {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.blog-date {
  color: #888;
  font-size: 0.9rem;
  margin-bottom: 10px;
}

.blog-description {
  font-size: 1rem;
  color: #666;
}
</style>
