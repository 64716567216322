<template>
  <n-layout-header class="navbar">
    <div class="navbar-content" ref="navbar">
      <!-- Logo on the left -->
      <div class="navbar-logo" @click="navigate('/')">
        <img src="@/assets/OutriaLogo.png" alt="Outria" class="logo-image" />
      </div>

      <!-- Centered Links -->
      <div class="navbar-links">
        <!-- Free Tools Menu -->
        <div class="menu-item-container" @click="toggleDropdown('freeTools')">
          <n-button text class="tools-btn">
            <img src="@/assets/magic-icon.png" alt="Magic Icon" class="magic-icon" />
            Free Tools
            <n-icon size="16" class="chevron-icon">
              <ChevronDownOutline />
            </n-icon>
          </n-button>
        </div>

        <!-- Products Menu -->
        <div class="menu-item-container" @click="toggleDropdown('products')">
          <n-button text class="products-btn">
            Products
            <n-icon size="16" class="chevron-icon">
              <ChevronDownOutline />
            </n-icon>
          </n-button>
        </div>

        <!-- Other Links -->
        <n-button
          text
          v-for="link in links"
          :key="link.text"
          @click="navigate(link.url)"
        >
          {{ link.text }}
        </n-button>
      </div>

      <!-- Right-Side Buttons -->
      <div class="navbar-auth">
        <n-button type="primary" @click="signUp">Sign Up</n-button>
        <n-button @click="signIn">Sign In</n-button>
      </div>

      <!-- Hamburger icon for mobile -->
      <div class="navbar-hamburger" @click="toggleMenu">
        <n-icon size="24">
          <MenuOutline />
        </n-icon>
      </div>
    </div>

    <!-- Dropdown Cards (Fixed Position) -->
    <transition name="fade">
      <div
        v-if="activeDropdown === 'freeTools'"
        class="dropdown-card"
        @click.stop
      >
        <div class="dropdown-content">
          <div
            v-for="tool in freeTools"
            :key="tool.title"
            class="dropdown-item"
            @click="navigate(tool.url)"
          >
            <!-- Image on the left -->
            <img :src="tool.image" alt="" class="dropdown-item-image" />
            <!-- Content on the right -->
            <div class="dropdown-item-content">
              <h3>{{ tool.title }}</h3>
              <p>{{ tool.description }}</p>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <transition name="fade">
      <div
        v-if="activeDropdown === 'products'"
        class="dropdown-card"
        @click.stop
      >
        <div class="dropdown-content">
          <div
            v-for="product in products"
            :key="product.title"
            class="dropdown-item"
            @click="navigate(product.url)"
          >
            <!-- Image on the left -->
            <img :src="product.image" alt="" class="dropdown-item-image" />
            <!-- Content on the right -->
            <div class="dropdown-item-content">
              <h3>{{ product.title }}</h3>
              <p>{{ product.description }}</p>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <!-- Overlay -->
    <div v-if="isMenuOpen" class="overlay" @click="toggleMenu"></div>

    <!-- Mobile Menu -->
    <transition name="slide">
      <!-- Root div with v-if applied directly -->
      <div v-if="isMenuOpen">
        <div class="mobile-menu">
          <!-- Free Tools Mobile Links -->
          <div class="mobile-menu-section">
            <h4>Free Tools</h4>
            <n-button
              text
              v-for="tool in freeTools"
              :key="tool.title"
              @click="navigate(tool.url)"
            >
              {{ tool.title }}
            </n-button>
          </div>

          <!-- Products Mobile Links -->
          <div class="mobile-menu-section">
            <h4>Products</h4>
            <n-button
              text
              v-for="product in products"
              :key="product.title"
              @click="navigate(product.url)"
            >
              {{ product.title }}
            </n-button>
          </div>

          <!-- Other Links -->
          <div class="mobile-menu-section">
            <h4>Links</h4>
            <n-button
              text
              v-for="link in links"
              :key="link.text"
              @click="navigate(link.url)"
            >
              {{ link.text }}
            </n-button>
          </div>

          <!-- Auth Buttons -->
          <div class="mobile-menu-section">
            <n-button type="primary" @click="signUp">Sign Up</n-button>
            <n-button @click="signIn">Sign In</n-button>
          </div>
        </div>

        <!-- X Button to close the menu -->
        <div class="mobile-menu-header">
          <n-icon size="24" @click="toggleMenu" class="close-icon">
            <CloseOutline />
          </n-icon>
        </div>
      </div>
    </transition>
  </n-layout-header>
</template>





<script>
import { defineComponent, ref, onMounted, onBeforeUnmount } from 'vue';
import { useRouter } from 'vue-router';
import { NIcon } from 'naive-ui';
import { MenuOutline, ChevronDownOutline } from '@vicons/ionicons5'; // Icons

export default defineComponent({
  name: 'NavBar',
  components: {
    NIcon,
    MenuOutline,
    ChevronDownOutline,
  },
  setup() {
    const router = useRouter();
    const isMenuOpen = ref(false);
    const activeDropdown = ref(null); // Track the active dropdown ('freeTools' or 'products')
    const navbar = ref(null); // Reference to the navbar-content DOM element

    const links = [
      { text: 'Documentation', url: '/documentation' },
      { text: 'Knowledge', url: '/knowledge' },
      { text: 'Contact', url: '/#faqs' },
    ];

    const freeTools = [
      {
        image: require('@/assets/email-writer.jpeg'),
        title: 'AI-Powered Email Writer',
        description:
          'Generate professional emails in seconds using our AI-powered email writer.',
        url: '/email-generator',
      },
      {
        image: require('@/assets/email-writer.jpeg'), // Placeholder image
        title: 'On Demand Crawl',
        description:
          'Perform web crawls on-demand with our powerful crawling tool.',
        url: '/on-demand-crawl',
      },
      // Add more tools if needed
    ];

    const products = [
      {
        image: require('@/assets/email-writer.jpeg'),
        title: 'Product 1',
        description: 'Description of product 1.',
        url: '/product1',
      },
      {
        image: require('@/assets/email-writer.jpeg'), // Placeholder image
        title: 'Public Directory',
        description:
          'Access and manage your public directories with ease.',
        url: '/public-directory',
      },
      // Add more products if needed
    ];

    const navigate = (url) => {
      router.push(url);
      closeAll();
    };

    const signUp = () => {
  router.push('/signup');
};

const signIn = () => {
  router.push('/signin');
};


    const toggleMenu = () => {
      isMenuOpen.value = !isMenuOpen.value;
      if (!isMenuOpen.value) {
        activeDropdown.value = null;
      }
    };

    const toggleDropdown = (menu) => {
      if (activeDropdown.value === menu) {
        activeDropdown.value = null;
      } else {
        activeDropdown.value = menu;
      }
    };

    const closeAll = () => {
      isMenuOpen.value = false;
      activeDropdown.value = null;
    };

    const handleClickOutside = (event) => {
      if (navbar.value && !navbar.value.contains(event.target)) {
        closeAll();
      }
    };

    onMounted(() => {
      document.addEventListener('click', handleClickOutside);
    });

    onBeforeUnmount(() => {
      document.removeEventListener('click', handleClickOutside);
    });

    return {
      links,
      freeTools,
      products,
      navigate,
      signUp,
      signIn,
      isMenuOpen,
      toggleMenu,
      activeDropdown,
      toggleDropdown,
      closeAll,
      navbar,
    };
  },
});
</script>

<style scoped>
/* Main Navbar */
.navbar {
  background-color: white;
  padding: 0 16px; /* Adjusted padding for better spacing */
  position: fixed;
  top: 0;
  left: 0;
  right: 16px; /* Stretch to full viewport width */
  height: 60px;
  z-index: 1000;
  box-sizing: border-box; /* Include padding within width */
  border-bottom: 1px solid #ebebeb;
  display: flex;
  align-items: center; /* Vertically center items */
  justify-content: center; /* Center navbar-content horizontally */
  overflow: hidden; /* Prevent horizontal overflow */
 
}

/* Navbar Content */
.navbar-content {
  display: flex;
  align-items: center; /* Vertically center items */
  justify-content: space-between;
  width: 100%;
  max-width: 1200px; /* Adjust as needed for responsiveness */
  box-sizing: border-box;
  margin: 0 auto; /* Center the navbar content */
  padding-right: 16px; /* Add space from the right to avoid overlapping scrollbar */
}

/* Logo */
.navbar-logo {
  display: flex;
  align-items: center; /* Vertically center logo */
}

.logo-image {
  height: 40px;
  cursor: pointer;
}

/* Navbar Links */
.navbar-links {
  display: flex;
  align-items: center; /* Vertically center items */
  gap: 20px;
}

/* Navbar Links Buttons */
.navbar-links n-button {
  font-size: 16px;
  line-height: normal;
  display: flex;
  align-items: center;
}

/* Navbar Auth Buttons */
.navbar-auth {
  display: flex;
  align-items: center; /* Vertically center items */
  gap: 10px;
}

.navbar-auth n-button {
  font-size: 16px;
  line-height: normal;
}

/* Hamburger Icon for Mobile */
.navbar-hamburger {
  display: none;
  font-size: 1.5rem;
  cursor: pointer;
}

/* Dropdown Card */
.dropdown-card {
  position: fixed; /* Fixed to span the entire viewport */
  top: 60px; /* Align dropdown below the navbar */
  left: 0;
  right: 16px; /* Leave space for scrollbar */
  background-color: #fff;
  padding: 20px 16px; /* Match horizontal padding with navbars */
  box-sizing: border-box; /* Include padding within width */
  border-bottom: 1px solid #ebebeb;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  z-index: 999;
  animation: fadeIn 0.3s ease-in-out;
}

/* Dropdown Content */
.dropdown-content {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

/* Dropdown Item */
.dropdown-item {
  display: flex;
  align-items: center; /* Vertically center items */
  gap: 15px;
  cursor: pointer;
  padding: 15px;
  background-color: #f9f9f9;
  border-radius: 8px;
  transition: background-color 0.2s;
  width: calc(33.333% - 40px); /* Three items per row */
  box-sizing: border-box;
}

.dropdown-item:hover {
  background-color: #eaeaea;
}

/* Dropdown Item Image */
.dropdown-item-image {
  width: 60px;
  height: 60px;
  border-radius: 8px;
  object-fit: cover;
}

/* Dropdown Item Content */
.dropdown-item-content h3 {
  font-size: 18px;
  margin: 0;
}

.dropdown-item-content p {
  font-size: 14px;
  color: #666;
  margin: 5px 0 0 0;
}

/* Menu Item Container */
.menu-item-container {
  position: relative;
  cursor: pointer;
}

/* Menu Buttons */
.tools-btn,
.products-btn {
  display: flex;
  align-items: center; /* Vertically center items */
}

/* Icons */
.chevron-icon {
  margin-left: 4px;
}

.magic-icon {
  width: 18px;
  height: 18px;
  margin-right: 8px;
}

/* Transition for Dropdown */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s, transform 0.3s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}
.fade-enter-to,
.fade-leave-from {
  opacity: 1;
  transform: translateY(0);
}

/* Overlay */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 997;
}

/* Mobile Menu */
.mobile-menu {
  position: fixed;
  top: 0; /* Cover from the top */
  left: 0;
  right: 0;
  bottom: 0; /* Cover the full screen vertically */
  background-color: white;
  padding: 20px 16px;
  box-sizing: border-box;
  z-index: 998;
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: flex-start; /* Ensure content starts from the top */
  animation: slideIn 0.3s ease-in-out;
}


/* Mobile Menu Sections */
.mobile-menu-section {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* Mobile Menu Section Headers */
.mobile-menu-section h4 {
  font-size: 16px;
  margin-bottom: 10px;
  color: #333;
}

/* Mobile Menu Buttons */
.mobile-menu-section n-button {
  font-size: 16px;
  align-self: flex-start; /* Align buttons to the left */
}
.mobile-menu-section:last-child {
  margin-top: auto; /* Pushes the last section (auth buttons) to the bottom */
}


/* Responsive Styles */
@media (max-width: 768px) {
  /* Show hamburger icon */
  .navbar-hamburger {
    display: block;
  }

  /* Hide navbar-links and navbar-auth on mobile */
  .navbar-links,
  .navbar-auth {
    display: none;
  }

  /* Adjust logo margin on mobile */
  .navbar-logo {
    margin-left: 10px;
  }

  /* Hide dropdown cards on mobile */
  .dropdown-card {
    display: none;
  }

  /* Adjust mobile-menu width to account for scrollbar */
  .mobile-menu {
    width: calc(100% - 32px); /* 16px padding on each side */
    margin: 0 auto;
  }
}

/* Keyframes for Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
