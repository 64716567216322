<template>
    <div
      class="upload-area"
      :class="{ 'glow': isDraggingOver }"
      @drop.prevent="handleDrop"
      @dragover.prevent="onDragOver"
      @dragleave="onDragLeave"
    >
      <p>Drag and drop your files here or click to upload</p>
      <div class="dropzone" @click="triggerFileInput">
        <input
          type="file"
          ref="fileInput"
          @change="handleFileUpload"
          style="display: none;"
          multiple
          accept="*/*"
        />
        <p v-if="uploadedFiles.length === 0">No files uploaded yet</p>
        <div v-if="uploadedFiles.length > 0" class="file-grid">
          <div
            v-for="(file, index) in uploadedFiles"
            :key="index"
            class="file-item"
            :class="{ 'image-thumbnail': isImage(file.type) }"
            :style="isImage(file.type) ? { backgroundImage: 'url(' + file.dataUrl + ')' } : {}"
          >
            <!-- Show file name or icon for non-image files -->
            <span v-if="!isImage(file.type)" class="file-icon">{{ getFileIcon(file.type) }}</span>
            <p class="file-name">{{ file.name }}</p>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'NoteContentManager',
    data() {
      return {
        uploadedFiles: [],
        isDraggingOver: false, // Track if the user is dragging something over the page
      };
    },
    mounted() {
      window.addEventListener('dragenter', this.onGlobalDragEnter);
      window.addEventListener('dragleave', this.onGlobalDragLeave);
      window.addEventListener('drop', this.onGlobalDrop);
    },
    beforeUnmount() {
      window.removeEventListener('dragenter', this.onGlobalDragEnter);
      window.removeEventListener('dragleave', this.onGlobalDragLeave);
      window.removeEventListener('drop', this.onGlobalDrop);
    },
    methods: {
      triggerFileInput() {
        this.$refs.fileInput.click();
      },
      handleFileUpload(event) {
        const files = event.target.files;
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          const reader = new FileReader();
          
          // For images, use FileReader to display a thumbnail
          if (this.isImage(file.type)) {
            reader.onload = (e) => {
              this.uploadedFiles.push({
                name: file.name,
                type: file.type,
                dataUrl: e.target.result // Store base64 data URL for image
              });
            };
            reader.readAsDataURL(file);
          } else {
            // For non-image files, no need for FileReader
            this.uploadedFiles.push({
              name: file.name,
              type: file.type,
              dataUrl: null // No data URL needed for non-image files
            });
          }
        }
        this.isDraggingOver = false; // Remove glow after files are uploaded
      },
      handleDrop(event) {
        const files = event.dataTransfer.files;
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          const reader = new FileReader();
          
          // Handle images differently (use base64 URL for preview)
          if (this.isImage(file.type)) {
            reader.onload = (e) => {
              this.uploadedFiles.push({
                name: file.name,
                type: file.type,
                dataUrl: e.target.result // Store base64 data URL for image
              });
            };
            reader.readAsDataURL(file);
          } else {
            // For non-image files, directly store file info
            this.uploadedFiles.push({
              name: file.name,
              type: file.type,
              dataUrl: null // No data URL for non-images
            });
          }
        }
        this.isDraggingOver = false; // Remove glow after files are dropped
      },
      onDragOver() {
        this.isDraggingOver = true; // Glow when file is dragged over
      },
      onDragLeave() {
        this.isDraggingOver = false; // Remove glow when dragging away
      },
      onGlobalDragEnter() {
        this.isDraggingOver = true; // Glow when file is dragged anywhere on the screen
      },
      onGlobalDragLeave() {
        this.isDraggingOver = false; // Remove glow when no file is being dragged
      },
      onGlobalDrop() {
        this.isDraggingOver = false; // Remove glow once dropped
      },
      isImage(fileType) {
        return fileType.startsWith('image/');
      },
      getFileIcon(fileType) {
        // Return icons or text representing different file types
        if (fileType.includes('pdf')) return '📄';
        if (fileType.includes('excel') || fileType.includes('spreadsheet')) return '📊';
        if (fileType.includes('csv')) return '🗃️';
        if (fileType.includes('video')) return '🎥';
        if (fileType.includes('audio')) return '🎵';
        return '📁'; // Default for other file types
      },
    },
  };
  </script>
  
  <style scoped>
  .upload-area {
    background-color: #f9f9f9;
    border: 2px dashed #ccc;
    padding: 12px;
    text-align: center;
    border-radius: 4px;
    cursor: pointer;
    transition: border-color 0.3s ease;
  }
  
  .upload-area.glow {
    border-color: #3ecda0;
    box-shadow: 0 0 10px #aad8ca, 0 0 20px #c7c7c7, 0 0 30px #4bebb8; /* Neon glow effect */
  }
  
  .dropzone {
    width: 100%;
    min-height: 150px;
    border: 2px dashed #aaa;
    border-radius: 4px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #aaa;
    font-size: 12px;
  }
  
  .file-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
    gap: 10px;
    margin-top: 15px;
  }
  
  .file-item {
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    border-radius: 4px;
    text-align: center;
    font-size: 16px;
  }
  
  .file-item.image-thumbnail {
    background-size: cover;
    background-position: center;
  }
  
  .file-icon {
    font-size: 24px;
  }
  
  .file-name {
    font-size: 10px;
    margin-top: 5px;
    word-wrap: break-word;
    text-align: center;
  }
  </style>
  