<template>
    <section class="trusted-by-section">
      <div class="container">
        <p class="text">Our data is trusted by over 10,000+ marketers and researchers</p>
        <div class="logos">
          <div
            v-for="logo in logos"
            :key="logo.alt"
            class="logo-wrapper"
          >
            <img :src="logo.src" :alt="logo.alt" class="logo" />
          </div>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  export default {
    name: 'TrustedBySection',
    data() {
      return {
        logos: [
          { src: require('@/assets/dropbox.png'), alt: 'Dropbox' },
          { src: require('@/assets/redhat.png'), alt: 'Redhat' },
          { src: require('@/assets/hubspot.png'), alt: 'Hubspot' },
          { src: require('@/assets/dailymotion.png'), alt: 'Dailymotion' },
          { src: require('@/assets/squarespace.png'), alt: 'Squarespace' },
          // Add more logos as needed
        ]
      };
    }
  };
  </script>
  
  <style scoped>
  .trusted-by-section {
    
    
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
  }
  
  .text {
    font-size: 16px;
    color: #616161;
    margin-bottom: 10px;
  }
  
  .logos {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 30px;
  }
  
  .logo-wrapper {
    position: relative;
    display: inline-block;
  }
  
  .logo {
    height: 30px; /* Adjust size as needed */
    object-fit: contain;
    pointer-events: none; /* Disables mouse interactions */
    filter: grayscale(10%) brightness(1.5);
  }
  
  /* Optionally add an overlay to further obscure the image */
  .logo-wrapper::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0); /* Transparent overlay */
    z-index: 1;
  }

  
  </style>
  