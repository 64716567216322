<template>
  <div>
    <!-- Navbar -->
    <header class="navbar">
      <!-- Logo -->
      <router-link to="/dashboard">
      <img src="@/assets/OutriaLogo.png" alt="Logo" class="navbar-logo" />
    </router-link>


      <!-- Organization and Project Buttons -->
      <div class="navbar-left">
        <MyOrganisationButton />
        <MyProjectButton />
      </div>

      <!-- Right Side of Navbar -->
      <div class="navbar-right">
        <HelpButton />
        <NotificationsButton />
        <FeedbackButton />
        <UserNavbarButton />
        <UserProfileButton />
      </div>
    </header>

    <!-- Main Content -->
    <div class="dashboard-container">
      <aside
        class="sidebar"
        :class="{ expanded: sidebarExpanded }"
        @mouseover="expandSidebar"
        @mouseleave="collapseSidebar"
      >
        <!-- Sidebar Items -->
        <div
            class="menu-item"
            :class="{ 'selected': menuSelected === 'search' }"
            @click="navigate('search')"
          >
            <n-icon size="24" class="menu-icon">
              <i class="material-icons-outlined">search</i>
            </n-icon>
            <span v-if="sidebarExpanded" class="menu-label">Search</span>
          </div>
          <n-divider class="divider" />

        <div v-for="item in sidebarItems" :key="item.key || 'divider'" class="menu-item-container">
          <n-divider v-if="item.divider" class="divider" />
          <div
            v-else
            class="menu-item"
            :class="{ 'selected': menuSelected === item.key }"
            @click="navigate(item.key)"
          >
            <n-icon size="24" class="menu-icon">
              <i :class="`material-icons-outlined`">{{ item.icon }}</i>
            </n-icon>
            <span v-if="sidebarExpanded" class="menu-label">{{ item.label }}</span>
          </div>
        </div>

        <!-- Settings, Search, and Divider -->
        <div class="menu-item-container settings-search-container">
          <n-divider class="divider" />
          
          <div
            class="menu-item"
            :class="{ 'selected': menuSelected === 'settings' }"
            @click="navigate('settings')"
          >
            <n-icon size="24" class="menu-icon">
              <i class="material-icons-outlined">settings</i>
            </n-icon>
            <span v-if="sidebarExpanded" class="menu-label">Settings</span>
          </div>
        </div>

       
      </aside>

      <main class="content">
        <router-view v-if="isAuthenticated"></router-view>
        <div v-else>
          <p>Please sign in to access the dashboard.</p>
        </div>
      </main>
    </div>
  </div>
</template>



<script>
import { defineComponent, ref, computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { NIcon, NDivider } from 'naive-ui';
import { supabase } from '@/supabaseClient';

import MyOrganisationButton from '@/components/MyOrganisationButton.vue';
import MyProjectButton from '@/components/MyProjectButton.vue';
import HelpButton from '@/components/HelpButton.vue';
import NotificationsButton from '@/components/NotificationsButton.vue';
import FeedbackButton from '@/components/FeedbackButton.vue';
import UserNavbarButton from '@/components/UserNavbarButton.vue';
import UserProfileButton from '@/components/UserProfileButton.vue';



export default defineComponent({
  name: 'UserDashboard',
  components: {
    NIcon,
    NDivider,
    MyOrganisationButton,
    MyProjectButton,
    HelpButton,
    NotificationsButton,
    FeedbackButton,
    UserNavbarButton,
    UserProfileButton,
   
  },
  setup() {
    const router = useRouter();
    const isAuthenticated = ref(false);
    const user = ref(null);
    const sidebarExpanded = ref(false);
    const menuSelected = ref('search');

    const selectedOrgName = ref('');
    const selectedProjectName = ref('');

    const sidebarItems = ref([
      { key: 'workflow', icon: 'apps', label: 'Outria Suite' },
      { key: 'home', icon: 'analytics_outlined', label: 'Analytics' },
      { key: 'create', icon: 'draw_outlined', label: 'Workshop'},
      { divider: true },
      { key: 'emails', icon: 'inbox', label: 'Inbox' },
      { divider: true },
      { key: 'database', icon: 'table_rows_outlined', label: 'Tables' },
      { key: 'table-editor', icon: 'editor', label: 'Table Editor' },
      { divider: true },
      { key: 'knowledge', icon: 'school', label: 'Knowledge' },
      { key: 'docs', icon: 'article', label: 'Docs' },
    ]);

    const expandSidebar = () => {
      sidebarExpanded.value = true;
    };

    const collapseSidebar = () => {
      sidebarExpanded.value = false;
    };

    const navigate = (key) => {
      menuSelected.value = key;
      let route = '';
      switch (key) {
        case 'home': route = '/dashboard'; break;
        case 'table-editor': route = '/table-editor'; break;
        case 'create': route = '/create'; break;
        case 'database': route = '/database'; break;
        case 'workflow': route = '/workflow'; break;
        case 'emails': route = '/emails'; break;
        case 'knowledge': route = '/dashboard/knowledge'; break;
        case 'docs': route = '/docs'; break;
        case 'settings': route = '/settings'; break;
        case 'search': route = '/search'; break;
      }
      if (route) {
        router.push(route);
      }
    };

    const truncatedEmail = computed(() => {
      const email = user.value?.email || '';
      return email.length > 20 ? `${email.slice(0, 17)}...` : email;
    });

    const truncatedUsername = computed(() => {
      const username = user.value?.user_metadata.username || '';
      return username.length > 15 ? `${username.slice(0, 12)}...` : username;
    });

    const fetchUserData = async () => {
      try {
        const { data: session, error: sessionError } = await supabase.auth.getSession();
        if (sessionError) throw sessionError;

        const loggedInUser = session?.session?.user;
        if (loggedInUser) {
          user.value = loggedInUser;
          isAuthenticated.value = true;
        } else {
          router.push('/signin');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        router.push('/signin');
      }
    };

    const confirmSelection = async () => {
      const selectedOrgId = sessionStorage.getItem('selectedOrgId');
      const selectedProjectId = sessionStorage.getItem('selectedProjectId');

      if (selectedOrgId && selectedProjectId) {
        await fetchOrgProjectNames();
        router.push('/search');
      }
    };

    const fetchOrgProjectNames = async () => {
      const selectedOrgId = sessionStorage.getItem('selectedOrgId');
      const selectedProjectId = sessionStorage.getItem('selectedProjectId');

      if (selectedOrgId) {
        const { data: org } = await supabase
          .from('organizations')
          .select('name')
          .eq('id', selectedOrgId)
          .single();

        selectedOrgName.value = org?.name || 'Unknown Organization';
      }

      if (selectedProjectId) {
        const { data: project } = await supabase
          .from('projects')
          .select('name')
          .eq('id', selectedProjectId)
          .single();

        selectedProjectName.value = project?.name || 'Unknown Project';
      }
    };

    onMounted(async () => {
      await fetchUserData();
      await confirmSelection();
    });

    return {
      isAuthenticated,
      user,
      sidebarItems,
      sidebarExpanded,
      menuSelected,
      selectedOrgName,
      selectedProjectName,
      expandSidebar,
      collapseSidebar,
      navigate,
      truncatedEmail,
      truncatedUsername,
    };
  },
});
</script>

<style scoped>
/* Navbar Styles */
.navbar {
  background-color: #ffffff;
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  color: rgb(62, 62, 62);
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  box-sizing: border-box;
  margin: 0;
  border-bottom: #cbd5e0 1px solid;
  justify-content: space-between;
}

.navbar-logo {
  height: 35px;
  width: auto;
  margin-right: 30px;
}

.navbar-left {
  display: flex;
  align-items: center;
  flex: 1;
}

.navbar-left > * {
  margin-right: 20px; /* Space between organization and project buttons */
}

.navbar-right {
  display: flex;
  align-items: center;
}

.navbar-right > * {
  margin-left: 10px; /* Space between right-side buttons */
}

.dashboard-container {
  display: flex;
  height: calc(100vh - 50px);
  margin-top: 50px;
}

.sidebar {
  width: 60px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-right: 1px solid #cbd5e0;
  position: fixed;
  top: 50px;
  bottom: 0;
  transition: width 0.3s ease;
  z-index: 999;
  padding-top: 20px;
  padding-bottom: 20px;
}

.sidebar.expanded {
  width: 200px;
  box-shadow: 2px 0 10px rgba(39, 39, 39, 0.1);
}

.menu-item-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.settings-search-container {
  margin-top: auto;
}

.menu-item {
  display: flex;
  align-items: center;
  padding: 10px 16px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  width: 100%;
  border-radius: 4px;
  box-sizing: border-box;
  text-align: center;
  justify-content: center;
}

.sidebar.expanded .menu-item {
  justify-content: flex-start;
}

.menu-item:hover {
  background-color: #f6f6f6;
}

.menu-item.selected {
  color: white;
}

.menu-icon {
  font-size: 24px;
  color: #3b3b3b;
}

.menu-item.selected .menu-icon,
.menu-item:hover .menu-icon {
  color: #18b67c;
}

.menu-label {
  margin-left: 12px;
  color: #262626;
  font-size: 14px;
  white-space: nowrap;
}

.menu-item.selected .menu-label,
.menu-item:hover .menu-label {
  color: #18b67c;
}

.divider {
  width: 100%;
  margin: 10px 0;
}

.content {
  flex: 1;
  background-color: #fbfbfb;
  padding: 20px;
  overflow-y: auto;
  margin-left: 60px; /* Ensure content is not hidden behind sidebar */
}
</style>

