<template>
    <div class="noteboard-page">
      <!-- Sidebar Menu (NoteBoardMenu) -->
      <div class="sidebar">
        <NoteBoardMenu @draggingItem="handleDraggingItem" />
      </div>
  
      <!-- Note Board Content (Grabbable and Zoomable) -->
      <div
        class="board"
        @mousedown="startPanning"
        @mouseup="stopPanning"
        @mouseleave="stopPanning"
        @mousemove="panBoard"
        @wheel="handleZoom"
        @drop="handleDrop"
        @dragover.prevent
      >
        <div class="board-content" :style="boardTransform">
          <!-- Dotted background with black border -->
          <div class="dotted-background"></div>
  
          <!-- Render Dropped Notes or Components -->
          <component
            v-for="(note, index) in notes"
            :is="note.component"
            :key="index"
            :style="{ top: note.top + 'px', left: note.left + 'px', position: 'absolute' }"
            :initialTop="note.top"
            :initialLeft="note.left"
            @mousedown.stop="startCardDragging(note, $event)"
          />
        </div>
  
        <!-- Drag Preview (appears while dragging) -->
        <component
          v-if="draggedItem && isDragging"
          :is="draggedItem.component"
          class="drag-preview"
          :style="{ top: previewPosition.top + 'px', left: previewPosition.left + 'px', position: 'absolute' }"
        />
      </div>
  
      <!-- Fixed Zoom Controls -->
      <div class="zoom-controls">
        <button @click="zoomIn">+</button>
        <button @click="zoomOut">-</button>
      </div>
    </div>
  </template>
  
  <script>
  import { ref, onMounted } from 'vue';
  import NoteBoardMenu from '@/components/NoteBoardMenu';
  import NoteDrag from '@/components/Boards/NoteDrag.vue';
  import EmailTemplateBasic from '@/components/EmailTemplateBasic.vue'; // Import your email template component
  import TextComponent from '@/components/TextComponent.vue'; // Import the text component
  import ImageComponent from '@/components/ImageComponent.vue'; // Import the image component
  import { supabase } from '@/supabaseClient'; // Import Supabase
  
  export default {
    name: 'NoteBoard',
    components: {
      NoteBoardMenu,
      NoteDrag,
      EmailTemplateBasic, // Register the email template
      TextComponent,
      ImageComponent,
    },
    setup() {
      const isPanning = ref(false);
      const isDragging = ref(false); // Track if an item is being dragged
      const draggedItem = ref(null); // Store the item being dragged
      const boardOffset = ref({ x: 0, y: 0 });
      const lastMousePosition = ref({ x: 0, y: 0 });
      const scale = ref(1); // For zooming
      const notes = ref([]); // Store dropped components
      const previewPosition = ref({ top: 0, left: 0 }); // Position of the preview component
      const selectedCard = ref(null); // To track the selected component for movement

  
      const boardTransform = ref({
        transform: `translate(0px, 0px) scale(1)`,
      });
  
      // Fetch the user's existing notes/templates from Supabase
      const fetchUserNotes = async () => {
        const { data: session } = await supabase.auth.getSession();
        if (session?.session?.user) {
          const { data: userNotes } = await supabase
            .from('user_templates')
            .select('*')
            .eq('user_id', session.session.user.id);
          
          if (userNotes) {
            notes.value = userNotes.map((note) => ({
              ...note,
              component: note.template_name, // Based on how you store the type of note/template
            }));
          }
        }
      };
  
      onMounted(() => {
        fetchUserNotes(); // Fetch notes when the component mounts
      });
  
      // Handle dragging an item from the menu
      const handleDraggingItem = (item) => {
        draggedItem.value = { ...item }; // Assign the component
        isDragging.value = true; // Start dragging
      };
  
      // Handle moving the preview component during drag
      const handleMouseMove = (event) => {
        if (isDragging.value) {
          previewPosition.value = {
            top: event.clientY,
            left: event.clientX,
          };
        }
      };
  
      // Handle dropping an item on the board
      const handleDrop = async (event) => {
        const componentType = event.dataTransfer.getData('componentType');
        const rect = event.target.getBoundingClientRect();
        const dropX = (event.clientX - rect.left) / scale.value;
        const dropY = (event.clientY - rect.top) / scale.value;
  
        const note = {
          component: '',
          top: dropY,
          left: dropX,
          position: { x: dropX, y: dropY },
          content: '',
        };
  
        switch (componentType) {
          case 'emailTemplate':
            note.component = 'EmailTemplateBasic';
            break;
          case 'text':
            note.component = 'TextComponent';
            break;
          case 'image':
            note.component = 'ImageComponent';
            break;
          default:
            break;
        }
  
        // Save the dropped component in the store and Supabase
        notes.value.push(note);
  
        // Save the note to Supabase
        const { data: session } = await supabase.auth.getSession();
        if (session?.session?.user) {
          await supabase.from('user_templates').insert({
            user_id: session.session.user.id,
            template_name: componentType,
            template_content: '',
            position: note.position,
          });
        }
  
        draggedItem.value = null; // Clear after drop
        isDragging.value = false; // Stop dragging
      };
  
      // Panning logic
      const startPanning = (event) => {
        if (!isDragging.value && !selectedCard.value) {
          isPanning.value = true;
          lastMousePosition.value = { x: event.clientX, y: event.clientY };
        }
      };
  
      const stopPanning = () => {
        isPanning.value = false;
      };
  
      const panBoard = (event) => {
        if (isPanning.value && !isDragging.value) {
          const deltaX = event.clientX - lastMousePosition.value.x;
          const deltaY = event.clientY - lastMousePosition.value.y;
  
          boardOffset.value.x += deltaX;
          boardOffset.value.y += deltaY;
  
          lastMousePosition.value = { x: event.clientX, y: event.clientY };
  
          updateBoardTransform();
        }
      };
  
      const updateBoardTransform = () => {
        boardTransform.value.transform = `translate(${boardOffset.value.x}px, ${boardOffset.value.y}px) scale(${scale.value})`;
      };
  
      // Handle zoom with mouse wheel
      const handleZoom = (event) => {
        event.preventDefault();
        const delta = event.deltaY > 0 ? -0.03 : 0.03; // Adjust zoom delta for smoother zoom
        scale.value = Math.min(Math.max(scale.value + delta, 0.2), 3); // Clamp zoom between 0.2 and 3
        updateBoardTransform();
      };
  
      const zoomIn = () => {
        scale.value = Math.min(scale.value + 0.03, 3); // Smaller increments for smoother zooming
        updateBoardTransform();
      };
  
      const zoomOut = () => {
        scale.value = Math.max(scale.value - 0.03, 0.2); // Smaller increments for smoother zooming
        updateBoardTransform();
      };
  
      // Card dragging logic
      const startCardDragging = (note, event) => {
        selectedCard.value = note;
        lastMousePosition.value = { x: event.clientX, y: event.clientY };
        window.addEventListener('mousemove', moveSelectedCard);
        window.addEventListener('mouseup', stopCardDragging);
      };
  
      const moveSelectedCard = (event) => {
        if (selectedCard.value) {
          const deltaX = event.clientX - lastMousePosition.value.x;
          const deltaY = event.clientY - lastMousePosition.value.y;
  
          selectedCard.value.top += deltaY;
          selectedCard.value.left += deltaX;
  
          lastMousePosition.value = { x: event.clientX, y: event.clientY };
        }
      };
  
      const stopCardDragging = () => {
        selectedCard.value = null;
        window.removeEventListener('mousemove', moveSelectedCard);
        window.removeEventListener('mouseup', stopCardDragging);
      };
  
      return {
        boardTransform,
        notes,
        startPanning,
        stopPanning,
        panBoard,
        handleMouseMove,
        handleZoom,
        zoomIn,
        zoomOut,
        handleDraggingItem,
        handleDrop,
        previewPosition,
        isDragging,
        startCardDragging,
      };
    },
  };
  </script>
  
  
  <style scoped>
  html, body {
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }
  
  .noteboard-page {
    display: flex;
    height: 80vh; /* Don't change this (has to be 80vh) */
    padding: 0;
    margin: 0;
    overflow: hidden;
    color:rgba(0, 0, 0, 0.2);
  }
  
  .sidebar {
    width: 350px;
    background-color: white;
    border-right: 0px solid #cbd5e0;
    position: fixed;
    top: 50px;
    bottom: 0;
    left: 60px;
    z-index: 100;
    overflow-y: auto;
    padding-right: 0;
  }
  
  /* Custom scroll bar */
  .sidebar::-webkit-scrollbar {
    width: 8px;
  }
  
  .sidebar::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  .sidebar::-webkit-scrollbar-thumb {
    background-color: #a7a7a7;
    border-radius: 0; /* Square corners */
  }
  
  .sidebar::-webkit-scrollbar-thumb:hover {
    background: #919191;
  }
  
  .board {
    flex-grow: 1;
    position: relative;
    cursor: grab;
    overflow: hidden;
    margin-left: 400px;
    height: calc(100vh - 50px);
  }
  
  .board-content {
    position: absolute;
    width: 4000px;
    height: 4000px;
    background: transparent;
    transform-origin: top left;
    border: 2px solid black;
  }
  
  .dotted-background {
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: radial-gradient(circle, #c0c0c0 1px, transparent 1px);
    background-size: 20px 20px;
  }
  
  .zoom-controls {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    z-index: 100;
  }
  
  .zoom-controls button {
    background-color: #e1e1e1;
    color: rgb(103, 103, 103);
    border: 3px solid rgb(201, 201, 201);
    padding: 10px;
    font-size: 20px;
    cursor: pointer;
    border-radius: 3%;
    margin-right: 10px;
  }
  
  .drag-preview {
    opacity: 0.8;
    pointer-events: none;
    border: 1px solid #e0e0e0;
    background-color: white;
    padding: 10px;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }
  </style>
  