<template>
  <div class="projects-manager">
    <!-- Header Section -->
    <header class="header">
      <n-space align="center">
        <n-button type="success" @click="openProjectDrawer">New Project</n-button>
        <n-button type="primary" @click="createNewOrganization">New Organization</n-button>
        <n-input v-model="searchQuery" placeholder="Search for a project" size="large" clearable>
          <template #suffix>
            <n-icon size="20">
              <i class="material-icons">search</i>
            </n-icon>
          </template>
        </n-input>
      </n-space>
    </header>

    <!-- Project Cards Section -->
    <div class="projects-list">
      <h2>{{ organizationName }}'s Org</h2>
      <div
        v-for="project in filteredProjects"
        :key="project?.id"
        class="project-card"
      >
        <n-card class="card-content">
          <div class="project-info">
            <h3>{{ project?.name }}</h3>
            <p>{{ project?.description }}</p>
          </div>
          <n-icon size="20" class="chevron-icon">
            <i class="material-icons">chevron_right</i>
          </n-icon>
        </n-card>
      </div>
    </div>

    <!-- Drawer for New Project Creation -->
    <n-drawer
      v-model:show="drawerVisible"
      title="Create New Project"
      width="400px"
      placement="right"
      @mask-click="closeDrawer"
    >
      <n-form ref="projectForm" label-placement="left" label-width="100px">
        <n-form-item label="Project Name">
          <n-input v-model="newProject.name" />
        </n-form-item>
        <n-form-item label="Description">
          <n-input v-model="newProject.description" type="textarea" />
        </n-form-item>
        <n-space>
          <n-button type="primary" @click="submitNewProject">Create</n-button>
          <n-button @click="closeDrawer">Cancel</n-button>
        </n-space>
      </n-form>
    </n-drawer>
  </div>
</template>

<script>
import { defineComponent, ref, computed, onMounted } from 'vue';
import { NButton, NInput, NSpace, NCard, NIcon, NDrawer, NForm, NFormItem } from 'naive-ui';
import { supabase } from '@/supabaseClient';

export default defineComponent({
  name: 'ProjectsManager',
  components: {
    NButton,
    NInput,
    NSpace,
    NCard,
    NIcon,
    NDrawer,
    NForm,
    NFormItem,
  },
  setup() {
    const searchQuery = ref('');
    const organizationName = ref("hohomelodic");
    const projects = ref([]);
    const drawerVisible = ref(false);
    const newProject = ref({
      name: '',
      description: '',
    });

    // Fetch projects from Supabase
    const fetchProjects = async () => {
      try {
        const { data, error } = await supabase
          .from('projects')
          .select('*')
          .order('created_at', { ascending: false });

        if (error) throw error;

        projects.value = data || [];
      } catch (error) {
        console.error('Error fetching projects:', error);
      }
    };

    onMounted(() => {
      fetchProjects();
    });

    const filteredProjects = computed(() => {
      if (!searchQuery.value) {
        return projects.value;
      }
      return projects.value.filter(project =>
        project.name.toLowerCase().includes(searchQuery.value.toLowerCase())
      );
    });

    const openProjectDrawer = () => {
      drawerVisible.value = true;
    };

    const closeDrawer = () => {
      drawerVisible.value = false;
      newProject.value = { name: '', description: '' }; // Reset form
    };

    const submitNewProject = async () => {
      try {
        const { data, error } = await supabase
          .from('projects')
          .insert([
            {
              user_id: '66aa5493-182c-4f7b-a2cc-f6dcc602c053', // Replace with dynamic user_id
              name: newProject.value.name,
              description: newProject.value.description,
            },
          ])
          .single();

        if (error) throw error;

        if (data) {
          projects.value.push(data); // Add the new project to the list
          closeDrawer(); // Close drawer after successful creation
        }
      } catch (error) {
        console.error('Error creating new project:', error);
      }
    };

    const createNewOrganization = () => {
      console.log('Create New Organization');
      // Implement the logic to create a new organization
    };

    return {
      searchQuery,
      organizationName,
      filteredProjects,
      drawerVisible,
      newProject,
      openProjectDrawer,
      closeDrawer,
      submitNewProject,
      createNewOrganization,
    };
  },
});
</script>

<style scoped>
.projects-manager {
  padding: 20px;
  background-color: #f9fafb;
  min-height: 100vh;
  box-sizing: border-box;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.projects-list {
  margin-top: 20px;
}

h2 {
  font-size: 24px;
  font-weight: 500;
  color: #333;
  margin-bottom: 20px;
}

.project-card {
  margin-bottom: 20px;
}

.n-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  cursor: pointer;
  transition: box-shadow 0.2s ease;
}

.n-card:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.card-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  cursor: pointer;
}

.project-info h3 {
  font-size: 18px;
  font-weight: 500;
  margin: 0;
}

.project-info p {
  font-size: 14px;
  color: #888;
  margin: 5px 0 0;
}

.chevron-icon {
  color: #888;
}

.material-icons {
  font-size: 24px;
  color: #888;
}
</style>
