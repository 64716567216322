<template>
    <div class="select-contact">
      <!-- Row displaying List Name and New List Button -->
      <div class="top-section">
        <div class="left-side">
          <h2>List Name</h2>
          <div class="icon-container">
            <span class="icon">⤵</span> <!-- Icon indicating changeable list -->
            <span class="list-name">Current List</span>
          </div>
        </div>
  
        <div class="right-side">
          <button @click="createNewList" class="new-list-button">New List</button>
        </div>
      </div>
  
      <!-- Contact Analytics section (Placed under List Name row) -->
      <ContactAnalytics />
    </div>
  </template>
  
  <script>
  import ContactAnalytics from './ContactAnalytics.vue';
  
  export default {
    components: {
      ContactAnalytics,
    },
    methods: {
      createNewList() {
        this.$router.push('/database');
      },
    },
  };
  </script>
  
  <style scoped>
  .select-contact {
    padding: 20px;
    background-color: white;
    border-bottom: 1px solid #cbd5e0;
  }
  
  /* Styling for the top section with List Name and New List Button */
  .top-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px; /* Add space between the list row and analytics section */
  }
  
  .left-side {
    display: flex;
    align-items: center;
  }
  
  .icon-container {
    display: flex;
    align-items: center;
    margin-left: 10px;
  }
  
  .icon {
    margin-right: 10px;
  }
  
  .right-side {
    margin-left: auto;
  }
  
  .new-list-button {
    background-color: #3ecda0;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .new-list-button:hover {
    background-color: #129b59;
  }
  
  /* Additional space between sections */
  .contact-analytics {
    margin-top: 30px;
  }
  </style>
  