import { createClient } from '@supabase/supabase-js';

const SUPABASE_URL = process.env.SUPABASE_URL || 'https://unujxbnobivzydnkwroo.supabase.co';
const SUPABASE_KEY = process.env.SUPABASE_KEY || 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InVudWp4Ym5vYml2enlkbmt3cm9vIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjI0NjQzMjYsImV4cCI6MjAzODA0MDMyNn0.o8xyh2PZdQ7PcqHapn-GoNOSN6v1BsuqSURJ6jypD8k';

if (!SUPABASE_URL || !SUPABASE_KEY) {
  throw new Error('Supabase URL or Key is missing!');
}

export const supabase = createClient(SUPABASE_URL, SUPABASE_KEY);




