<template>
  <div class="workflow-search">
    <!-- Header with Search Bar -->
    <div :class="['header-search', { 'move-up': searched }]">
      <input
        v-model="searchQuery"
        type="text"
        placeholder="Search by Name, Industry, etc."
        @keydown.enter="performSearch"
        class="search-bar"
      />
      <button @click="performSearch" class="search-button">Search</button>
    </div>

    <!-- Main Content Layout (Appears after search) -->
    <transition name="fade">
      <div v-if="searched" class="main-content">
        <!-- Left Panel: Companies -->
        <div class="left-panel">
          <h3>Companies</h3>
          <div v-for="company in filteredCompanies" :key="company.id" class="company-card">
            <h4>{{ company.name }}</h4>
            <p>{{ company.description }}</p>
          </div>
        </div>

        <!-- Middle Panel: Employees -->
        <div class="middle-panel">
          <h3>Employees</h3>
          <div v-for="employee in employees" :key="employee.id" class="employee-card">
            <h4>{{ employee.name }}</h4>
            <p>{{ employee.position }}</p>
            <p>Salary: {{ employee.salary }}</p>
          </div>
        </div>

        <!-- Right Panel: Insights & Recommendations -->
        <div class="right-panel">
          <h3>Insights & Recommendations</h3>
          <div class="insight-card">
            <h4>Suggested Partners</h4>
            <p>Recommendations based on your search...</p>
          </div>
          <div class="insight-card">
            <h4>Market Trends</h4>
            <p>Current market trends in the industry...</p>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchQuery: "",
      searched: false,
      companies: [
        { id: 1, name: "Best Saunas", description: "Sauna manufacturing company" },
        { id: 2, name: "Tech Solutions", description: "Technology services provider" },
        { id: 3, name: "Wellness Pros", description: "Wellness and spa specialists" },
      ],
      employees: [
        { id: 1, name: "John Doe", position: "Senior Developer", salary: "$120k/year" },
        { id: 2, name: "Jane Smith", position: "Project Manager", salary: "$90k/year" },
        { id: 3, name: "Michael Johnson", position: "Sales Director", salary: "$100k/year" },
      ],
    };
  },
  computed: {
    filteredCompanies() {
      return this.companies.filter((company) =>
        company.name.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
  },
  methods: {
    performSearch() {
      if (this.searchQuery.trim()) {
        this.searched = true;
      }
    },
  },
};
</script>

<style scoped>
/* General Styling */
.workflow-search {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #ffffff;
  color: #000000;
}

/* Search Bar Styling */
.header-search {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 20px;
  width: 100%;
  transition: all 0.6s ease-in-out;
}

.header-search.move-up {
  position: fixed;
  top: 5%;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
}

.search-bar {
  width: 60%;
  padding: 15px;
  font-size: 1.2em;
  border-radius: 8px;
  border: 1px solid #ccc;
  margin-right: 10px;
}

.search-button {
  padding: 15px 30px;
  font-size: 1.2em;
  background-color: #129b72;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

.search-button:hover {
  background-color: #107d5b;
}

/* Main Content Layout */
.main-content {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  width: 80%;
  margin-top: 20px;
}

/* Left Panel: Companies */
.left-panel {
  width: 30%;
  padding: 20px;
}

.company-card {
  background-color: #f9f9f9;
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 8px;
  border: 1px solid #ccc;
}

/* Middle Panel: Employees */
.middle-panel {
  width: 40%;
  padding: 20px;
}

.employee-card {
  background-color: #f9f9f9;
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 8px;
  border: 1px solid #ccc;
}

/* Right Panel: Insights */
.right-panel {
  width: 25%;
  padding: 20px;
}

.insight-card {
  background-color: #f9f9f9;
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 8px;
  border: 1px solid #ccc;
}

/* Transition Effect */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
