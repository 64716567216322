<template>
    <footer class="footer">
      <div class="footer-content">
        <div class="footer-logo">
          <img src="@/assets/logo.png" alt="Logo" />
        </div>
        <div class="footer-links">
          <n-button text @click="navigate('/')">Home</n-button>
          <n-button text @click="navigate('/about')">About</n-button>
          <n-button text @click="navigate('/contact')">Contact</n-button>
          <n-button text @click="navigate('/privacy')">Privacy Policy</n-button>
        </div>
      </div>
    </footer>
  </template>
  
  <script>
  import { useRouter } from 'vue-router';
  
  export default {
    name: 'FooterSection',
    setup() {
      const router = useRouter();
  
      const navigate = (url) => {
        router.push(url);
      };
  
      return {
        navigate,
      };
    },
  };
  </script>
  
  <style scoped>
  .footer {
    background-color: #f8f9fa;
    padding: 20px 0;
    border-top: 1px solid #ebebeb;
    text-align: center;
  }
  
  .footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .footer-logo img {
    height: 20px;
    margin-bottom: 10px;
  }
  
  .footer-links {
    display: flex;
    gap: 15px;
    justify-content: center;
  }
  
  .footer-links n-button {
    font-size: 14px;
  }
  </style>
  