<template>
  <div class="container mx-auto p-6 bg-white rounded-lg shadow-md">
    <p v-if="creditUsage === null" class="text-gray-600">Loading...</p>
    <p v-else-if="creditUsage.length === 0" class="text-gray-600">No data available for this user.</p>

    <div v-else class="space-y-4">
      <!-- Flex container for dropdown and total credits used -->
      <div class="flex items-center mb-4">
        <!-- Dropdown on the left side -->
        <n-select
          v-model:value="timeSpan"
          :options="timeSpanOptions"
          @update:value="setTimeSpan"
          class="w-48"
        />

        <!-- Total Credits Used on the right side, aligned to the right -->
        <div class="ml-auto text-right">
          <div class="bg-gray-100 p-3 rounded-lg shadow-md">
            <span class="text-xs text-gray-500">Total Credits Used</span>
            <div class="text-2xl font-bold text-gray-800">{{ totalCreditsUsed }}</div>
          </div>
        </div>
      </div>

      <!-- Chart Container -->
      <div class="chart-container">
        <BarChart :chartData="chartData" :chartOptions="chartOptions" />
      </div>
    </div>
  </div>
</template>


<script>
import { ref, onMounted, watch } from 'vue';
import { supabase } from '@/supabaseClient';
import BarChart from '@/components/BarChart.vue';
import { NSelect } from 'naive-ui';

export default {
  components: {
    BarChart,
    NSelect,
  },
  setup() {
    const creditUsage = ref(null);
    const timeSpan = ref('30d');
    const chartData = ref({});
    const chartOptions = ref({});
    const totalCreditsUsed = ref(0);
    const timeSpanDescription = ref('');
    const timeSpanOptions = ref([
      { label: 'Last 24 Hours', value: '24h' },
      { label: 'Last 7 Days', value: '7d' },
      { label: 'Last 30 Days', value: '30d' },
      { label: 'Last 90 Days', value: '90d' },
    ]);

    const fetchCreditUsage = async () => {
      try {
        const { data: sessionData, error: sessionError } = await supabase.auth.getSession();

        if (sessionError) {
          console.error('Error getting session:', sessionError.message);
          creditUsage.value = [];
          return;
        }

        const user = sessionData.session?.user;
        if (!user) {
          console.error('User not logged in');
          creditUsage.value = [];
          return;
        }

        const { data, error } = await supabase
          .from('credit_usage')
          .select('*')
          .eq('user_id', user.id);

        if (error) {
          console.error('Error fetching credit usage:', error.message);
          creditUsage.value = [];
        } else if (data && data.length > 0) {
          creditUsage.value = data;
          updateChart();
        } else {
          creditUsage.value = [];
        }
      } catch (error) {
        console.error('Unexpected error fetching credit usage:', error);
        creditUsage.value = [];
      }
    };

    const updateChart = () => {
      if (!creditUsage.value || creditUsage.value.length === 0) return;

      const now = new Date();
      let filteredData = [];
      let description = '';

      switch (timeSpan.value) {
        case '24h':
          filteredData = creditUsage.value.filter((usage) =>
            new Date(usage.date) >= new Date(now.getTime() - 24 * 60 * 60 * 1000)
          );
          description = '24 hours';
          break;
        case '7d':
          filteredData = creditUsage.value.filter((usage) =>
            new Date(usage.date) >= new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000)
          );
          description = '7 days';
          break;
        case '30d':
          filteredData = creditUsage.value.filter((usage) =>
            new Date(usage.date) >= new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000)
          );
          description = '30 days';
          break;
        case '90d':
          filteredData = creditUsage.value.filter((usage) =>
            new Date(usage.date) >= new Date(now.getTime() - 90 * 24 * 60 * 60 * 1000)
          );
          description = '90 days';
          break;
      }

      timeSpanDescription.value = description;

      const labels = generateLabels(timeSpan.value, now);
      const data = generateData(labels, filteredData);

      totalCreditsUsed.value = data.reduce((acc, curr) => acc + curr, 0);

      chartData.value = {
        labels,
        datasets: [
          {
            backgroundColor: '#00995c',  // Main bar color
            hoverBackgroundColor: ' #00b359',  // Lighter blue on hover
            
            data,
          },
        ],
      };

      chartOptions.value = {
  responsive: true,
  maintainAspectRatio: false,
  aspectRatio: 2,
  scales: {
    x: {
      grid: {
        display: false,  // Remove vertical grid lines
      },
      ticks: {
        display: true,  // Show x-axis ticks
        color: '#4A5568',  // Set tick color to gray
      },
    },
    y: {
      grid: {
        display: true,
        color: '#EDF2F7',  // Light gray grid lines
        borderDash: [5, 5],  // Dashed grid lines
      },
      ticks: {
        display: false,  // Show y-axis ticks
        color: '#00994d',  // Set tick color to gray
      },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            backgroundColor: '#FFFFFF',  // Dark blue tooltip background
            titleColor: '#000000',  // White title in tooltip
            bodyColor: '#333333',  // White text in tooltip
            borderColor: '#000000',  // Blue border for tooltip
            borderWidth: 1,
            callbacks: {
              label: function (tooltipItem) {
                return `${tooltipItem.formattedValue} credits used`;
              },
            },
          },
        },
      };
    };

    const generateLabels = (timeSpan, now) => {
      const labels = [];
      let start, step;

      switch (timeSpan) {
        case '24h':
          start = new Date(now.getTime() - 24 * 60 * 60 * 1000);
          step = 60 * 60 * 1000; // 1 hour
          while (start <= now) {
            labels.push(start.toLocaleTimeString('en-US', { hour: 'numeric', hour12: true }));
            start = new Date(start.getTime() + step);
          }
          break;
        case '7d':
        case '30d':
          start = new Date(now.getTime() - parseInt(timeSpan) * 24 * 60 * 60 * 1000);
          step = 24 * 60 * 60 * 1000; // 1 day
          while (start <= now) {
            labels.push(start.toLocaleDateString('en-US', { month: 'short', day: 'numeric' }));
            start = new Date(start.getTime() + step);
          }
          break;
        case '90d':
          start = new Date(now.getTime() - 90 * 24 * 60 * 60 * 1000);
          step = 7 * 24 * 60 * 60 * 1000; // 1 week
          while (start <= now) {
            labels.push(`Week ${getWeekNumber(start)}`);
            start = new Date(start.getTime() + step);
          }
          break;
      }

      return labels;
    };

    const generateData = (labels, filteredData) => {
      const data = Array(labels.length).fill(0);

      filteredData.forEach((item) => {
        const itemDate = new Date(item.date);
        const label = labels.find(label => {
          switch (timeSpan.value) {
            case '24h':
              return label === itemDate.toLocaleTimeString('en-US', { hour: 'numeric', hour12: true });
            case '7d':
            case '30d':
              return label === itemDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
            case '90d':
              return label === `Week ${getWeekNumber(itemDate)}`;
          }
        });
        if (label !== undefined) {
          const index = labels.indexOf(label);
          if (index !== -1) {
            data[index] += item.credits_used;
          }
        }
      });

      return data;
    };

    const getWeekNumber = (date) => {
      const firstDay = new Date(date.getFullYear(), 0, 1);
      const pastDaysOfYear = (date - firstDay) / 86400000;
      return Math.ceil((pastDaysOfYear + firstDay.getDay() + 1) / 7);
    };

    const setTimeSpan = (span) => {
      timeSpan.value = span;
      updateChart();
    };

    onMounted(() => {
      fetchCreditUsage();
    });

    watch(timeSpan, updateChart);

    return {
      creditUsage,
      timeSpan,
      chartData,
      chartOptions,
      totalCreditsUsed,
      timeSpanDescription,
      setTimeSpan,
      timeSpanOptions,
    };
  },
};
</script>


<style scoped>
.container {
  max-width: 90%;
  margin: auto;
  border:#cbd5e0 1px solid;
  padding: 2rem;
}

.chart-container {
  position: relative;
  height: 130px;
}

.chart-labels {
  font-size: 0.875rem;
  color: #4a4a4a;
}

.bg-gray-100 {
  background-color: #f7fafc;
}

.w-48 {
  width: 12rem;
}

.btn {
  background-color: #e2e8f0;
  border: none;
  border-radius: 0.375rem;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

</style>
