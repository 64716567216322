<template>
    <div class="email-view">
      <!-- Email Tabs -->
      <div class="email-tabs">
        <div v-for="tab in tabs" :key="tab.id" :class="['tab', { active: tab.id === activeTab }]" @click="changeTab(tab.id)">
          {{ tab.name }}
        </div>
      </div>
  
      <!-- Action Bar -->
      <div class="action-bar">
        <div class="action-left">
          <input type="checkbox" @change="selectAll" />
          <button @click="refreshEmails">↻</button> <!-- Refresh button -->
        </div>
        <div class="action-right">
          <span>{{ pagination.start }}-{{ pagination.end }} of {{ pagination.total }}</span>
          <button @click="prevPage" :disabled="pagination.page === 1">←</button>
          <button @click="nextPage" :disabled="pagination.page * pagination.perPage >= pagination.total">→</button>
        </div>
      </div>
  
      <!-- Email List -->
      <div class="email-list">
        <div v-if="!accessToken" class="no-emails">You must sign in to view emails.</div>
        <div v-else-if="emails.length === 0" class="no-emails">No emails to display.</div>
        <div v-for="email in paginatedEmails" :key="email.id" class="email-item">
          <div class="email-checkbox">
            <input type="checkbox" v-model="selectedEmails" :value="email.id" />
          </div>
          <div class="email-details">
            <div class="email-sender">{{ email.from }}</div>
            <div class="email-subject">{{ email.subject }}</div>
            <div class="email-snippet">{{ email.snippet }}</div>
          </div>
          <div class="email-time">{{ email.time }}</div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      emails: {
        type: Array,
        required: true
      },
      accessToken: {
        type: String,
        required: false
      }
    },
    data() {
      return {
        activeTab: 'primary', // Default to "Primary"
        tabs: [
          { id: 'primary', name: 'Primary' },
          { id: 'promotions', name: 'Promotions' },
          { id: 'social', name: 'Social' }
        ],
        selectedEmails: [],
        pagination: {
          page: 1,
          perPage: 10,
          total: 0,
          start: 1,
          end: 10
        }
      };
    },
    computed: {
      paginatedEmails() {
        // Return a slice of emails based on pagination
        const start = (this.pagination.page - 1) * this.pagination.perPage;
        const end = start + this.pagination.perPage;
        return this.emails.slice(start, end);
      }
    },
    watch: {
      emails(newEmails) {
        this.pagination.total = newEmails.length;
        this.updatePagination();
      },
      'pagination.page': 'updatePagination'
    },
    methods: {
      changeTab(tabId) {
        this.activeTab = tabId;
        this.refreshEmails();
      },
      refreshEmails() {
        if (!this.accessToken) {
          console.error('User is not authenticated. Cannot fetch emails.');
          return;
        }
  
        console.log('Refresh emails in tab:', this.activeTab);
        // Add your email fetching logic here using the accessToken.
      },
      selectAll(event) {
        this.selectedEmails = event.target.checked ? this.emails.map(email => email.id) : [];
      },
      prevPage() {
        if (this.pagination.page > 1) {
          this.pagination.page--;
        }
      },
      nextPage() {
        if (this.pagination.page * this.pagination.perPage < this.pagination.total) {
          this.pagination.page++;
        }
      },
      updatePagination() {
        const start = (this.pagination.page - 1) * this.pagination.perPage;
        const end = start + this.pagination.perPage;
        this.pagination.start = start + 1;
        this.pagination.end = Math.min(end, this.pagination.total);
      }
    },
    created() {
      this.pagination.total = this.emails.length;
      this.updatePagination(); // Ensure initial pagination is calculated
    }
  };
  </script>
  
  <style scoped>
  .email-view {
    display: flex;
    flex-direction: column;
    height: 100vh;
    margin: 0;
    padding: 0;
  }
  
  /* Email Tabs styles */
  .email-tabs {
    display: flex;
    background-color: #f1f3f4;
    border-bottom: 1px solid #cbd5e0;
    margin: 0;
    padding: 0;
  }
  
  .tab {
    padding: 10px 20px;
    cursor: pointer;
    color: #5f6368;
  }
  
  .tab.active {
    border-bottom: 2px solid #12935d;
    color: #119b62;
    font-weight: 600;
  }
  
  /* Action Bar styles */
  .action-bar {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    background-color: white;
    border-bottom: 1px solid #cbd5e0;
    align-items: center;
    margin: 0;
  }
  
  .action-left {
    display: flex;
    align-items: center;
    padding-left: 20px;
  }
  
  .action-right {
    display: flex;
    align-items: center;
    padding-right: 20px;
  }
  
  .action-left input {
    margin-right: 10px;
  }
  
  .action-right button {
    margin-left: 10px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 18px;
  }
  
  .action-right span {
    font-size: 14px;
    color: #5f6368;
  }
  
  /* Email List styles */
  .email-list {
    flex-grow: 1;
    overflow-y: auto;
    margin: 0;
    padding: 0;
  }
  
  .no-emails {
    text-align: center;
    padding: 20px;
    font-size: 16px;
    color: #5f6368;
  }
  
  .email-item {
    display: flex;
    padding: 10px 20px;
    border-bottom: 1px solid #e8e8e8;
    align-items: center;
  }
  
  .email-checkbox {
    margin-right: 10px;
  }
  
  .email-details {
    flex-grow: 1;
  }
  
  .email-sender {
    font-weight: bold;
  }
  
  .email-subject {
    color: #202124;
  }
  
  .email-snippet {
    color: #5f6368;
  }
  
  .email-time {
    white-space: nowrap;
    font-size: 12px;
    color: #5f6368;
  }
  </style>
  