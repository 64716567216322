<template>
  <div class="create-organization-wrapper">
    <n-card class="create-organization-card">
      <h2>Create a New Organization</h2>
      <n-input v-model="orgName" placeholder="Organization Name" class="input-field" />
      <n-button @click="createOrganization" type="primary" class="submit-button">Create Organization</n-button>
      <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
    </n-card>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue';
import { supabase } from '@/supabaseClient';

export default defineComponent({
  setup() {
    const orgName = ref('');
    const errorMessage = ref('');

    const createOrganization = async () => {
      if (!orgName.value) {
        errorMessage.value = 'Organization name is required';
        return;
      }

      // Get the current user
      const { data: { user } } = await supabase.auth.getUser();

      if (!user) {
        errorMessage.value = 'You must be logged in to create an organization';
        return;
      }

      // Create the organization
      const { data: org, error } = await supabase
        .from('organizations')
        .insert([{ name: orgName.value, user_id: user.id }])
        .single();

      if (error) {
        errorMessage.value = 'Error creating organization: ' + error.message;
        return;
      }

      // Automatically create a project for the new organization
      const { error: projectError } = await supabase
        .from('projects')
        .insert([{ name: `${orgName.value}'s Project`, organization_id: org.id }]);

      if (projectError) {
        errorMessage.value = 'Error creating project: ' + projectError.message;
        return;
      }

      // Store selected organization in sessionStorage and redirect to the dashboard
      sessionStorage.setItem('selectedOrgId', org.id);
      window.location.href = '/dashboard';
    };

    return {
      orgName,
      errorMessage,
      createOrganization,
    };
  },
});
</script>

<style scoped>
.create-organization-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f4f5f7; /* Light background for contrast */
  padding: 20px;
  box-sizing: border-box;
}

.create-organization-card {
  padding: 30px;
  max-width: 450px; /* Slightly larger card for better content spacing */
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  background-color: #ffffff; /* White background for the card */
  border: 1px solid #e3e4e8; /* Soft border to define the card edges */
}

h2 {
  font-size: 22px;
  font-weight: 600;
  color: #333333;
  margin-bottom: 20px;
  text-align: left; /* Left-aligned heading */
}

.input-field {
  margin-bottom: 20px; /* Spacing between input and button */
  font-size: 16px; /* Larger font for readability */
}

.submit-button {
  width: 100%; /* Full-width button */
  font-size: 16px; /* Slightly larger text for the button */
  background-color: #27ae60; /* Green background */
  color: #ffffff; /* White text color */
}

.submit-button:hover {
  background-color: #219150; /* Darker green on hover */
}

.error-message {
  color: #e74c3c; /* Red color for errors */
  margin-top: 15px; /* Spacing above error message */
  font-size: 14px; /* Slightly smaller font for error messages */
}
</style>
