<template>
    <div class="user-profile-button-container" v-click-outside="closeDropdown">
      <div class="user-profile-button" @click="toggleDropdown">
        <div v-if="clientImage" class="user-image">
          <img :src="clientImage" alt="User Image" />
        </div>
        <div v-else class="user-initial" :style="{ backgroundColor: generatedColor }">
          {{ clientInitial }}
        </div>
        <div class="user-info">
          <div class="user-name">{{ client?.full_name || 'Guest' }}</div>
          <div class="user-id">{{ client?.user_id || 'N/A' }}</div>
        </div>
      </div>
  
      <transition name="fade">
        <div v-if="dropdownVisible" class="dropdown-card">
          <div class="dropdown-item" @click="navigateTo('/settings')">
            <span class="material-icons">account_circle</span>
            Manage Account
          </div>
          <div class="dropdown-item" @click="navigateTo('/manage-user')">
            <span class="material-icons">credit_card</span>
            Billing
          </div>
          <div class="dropdown-item" @click="navigateTo('/settings')">
            <span class="material-icons">settings</span>
            Settings
          </div>
          <hr class="divider" />
          <div class="dropdown-item logout" @click="logOut">
            <span class="material-icons">logout</span>
            Log Out
          </div>
        </div>
      </transition>
    </div>
  </template>
  
  <script>
  import { defineComponent, ref } from 'vue';
  import { useRouter } from 'vue-router';
  import { supabase } from '@/supabaseClient';
  import clickOutside from '@/directives/clickOutside';
  
  export default defineComponent({
    name: 'UserProfileButton',
    directives: {
      clickOutside,
    },
    setup() {
      const client = ref(null);
      const clientImage = ref(null);
      const dropdownVisible = ref(false);
      const generatedColor = ref('#ccc'); // Default fallback color
      const router = useRouter();
      const clientInitial = ref('');
  
      const predefinedColors = [
        '#9C27B0', '#673AB7', '#3F51B5', '#2196F3', '#03A9F4', '#00BCD4',
        '#009688', '#4CAF50', '#8BC34A', '#CDDC39', '#FFEB3B', '#FFC107',
        '#FF9800', '#FF5722', '#795548'
      ];
  
      const fetchUserData = async () => {
        try {
          const { data: session } = await supabase.auth.getSession();
          const loggedInUser = session?.session?.user;
  
          if (loggedInUser) {
            const { data: clientData } = await supabase
              .from('clients')
              .select('full_name, user_id')
              .eq('user_id', loggedInUser.id)
              .single();
  
            client.value = clientData;
  
            if (clientData) {
              clientInitial.value = clientData.full_name.charAt(0).toUpperCase();
              generatedColor.value = generateColorFromEmail(loggedInUser.email);
            }
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      };
  
      // Hash email to a color
      const generateColorFromEmail = (email) => {
        let hash = 0;
        for (let i = 0; i < email.length; i++) {
          hash = email.charCodeAt(i) + ((hash << 5) - hash);
        }
        const index = Math.abs(hash) % predefinedColors.length;
        return predefinedColors[index];
      };
  
      const toggleDropdown = () => {
        dropdownVisible.value = !dropdownVisible.value;
      };
  
      const closeDropdown = () => {
        dropdownVisible.value = false;
      };
  
      const navigateTo = (path) => {
        router.push(path);
        closeDropdown();
      };
  
      const logOut = async () => {
        await supabase.auth.signOut();
        window.location.reload();
      };
  
      fetchUserData();
  
      return {
        client,
        clientImage,
        dropdownVisible,
        toggleDropdown,
        closeDropdown,
        navigateTo,
        logOut,
        generatedColor,
        clientInitial,
      };
    },
  });
  </script>
  
  <style scoped>
  .user-profile-button-container {
    position: relative;
  }
  
  .user-profile-button {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 8px;
  }
  
  .user-image img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 12px;
  }
  
  .user-initial {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: white;
    font-size: 20px;
    margin-right: 12px;
  }
  
  .user-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .user-name {
    font-weight: 500;
    color: #333;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 120px;
  }
  
  .user-id {
    font-size: 12px;
    color: #888;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 120px;
  }
  
  .dropdown-card {
    position: absolute;
    top: 100%;
    left: 0;
    width: 200px;
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1001;
    margin-top: 8px;
  }
  
  .dropdown-item {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .dropdown-item:hover {
    background-color: #f0f0f0;
  }
  
  .logout {
    color: red;
  }
  
  .material-icons {
    margin-right: 10px;
    font-size: 20px;
  }
  
  .divider {
    border: none;
    height: 1px;
    background-color: #ddd;
    margin: 8px 0;
  }
  
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.3s;
  }
  
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
  </style>
  