<template>
    <div class="analytics-cards">
      <!-- New card for the stacked bar chart and legend -->
      <div class="card chart-card">
        <h4>Performance Overview</h4>
  
        <!-- Custom Stacked Bar -->
        <div class="custom-stacked-bar">
          <div class="bar-section reached" :style="{ width: reachedWidth + '%' }"></div>
          <div class="bar-section opened" :style="{ width: openedWidth + '%' }"></div>
          <div class="bar-section reply" :style="{ width: replyWidth + '%' }"></div>
        </div>
  
        <!-- Legend below the bar -->
        <div class="bar-legend">
          <div class="legend-item">
            <div class="legend-color reached"></div>
            <p class="percentage">50%</p>
            <p class="label">Reached</p>
          </div>
          <div class="legend-item">
            <div class="legend-color opened"></div>
            <p class="percentage">30%</p>
            <p class="label">Opened</p>
          </div>
          <div class="legend-item">
            <div class="legend-color reply"></div>
            <p class="percentage">20%</p>
            <p class="label">Reply</p>
          </div>
        </div>
      </div>
  
      <!-- First card with reached, opened, and reply metrics -->
      <div class="card wide">
        <h4>Total</h4>
        <div class="metrics">
          <div class="metric">
            <p class="big-number">320</p>
            <p class="status-label">Reached</p>
          </div>
          <div class="metric">
            <p class="big-number">260</p>
            <p class="status-label">Opened</p>
          </div>
          <div class="metric">
            <p class="big-number">120</p>
            <p class="status-label">Reply</p>
          </div>
        </div>
      </div>
  
      <!-- Second card for total contacts -->
      <div class="card">
        <h4>Total Contacts</h4>
        <p class="big-number">829</p>
        <p class="status-increase">+15%</p>
      </div>
  
      <!-- Third card for total members -->
      <div class="card">
        <h4>Total Members</h4>
        <p class="big-number">324</p>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ContactAnalytics',
    data() {
      return {
        reached: 320,
        opened: 260,
        reply: 120,
        totalContacts: 829,
      };
    },
    computed: {
      reachedWidth() {
        return (this.reached / this.totalContacts) * 100;
      },
      openedWidth() {
        return (this.opened / this.totalContacts) * 100;
      },
      replyWidth() {
        return (this.reply / this.totalContacts) * 100;
      },
    },
  };
  </script>
  
  <style scoped>
  /* Main container for all cards */
  .analytics-cards {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
    box-sizing: border-box;
  }
  
  /* Chart card with stacked bar and legend */
  .card.chart-card {
    width: 25%; /* Ensure it's a separate card */
    background-color: white;
    border: 1px solid #cbd5e0;
    padding: 15px;
    border-radius: 8px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 180px; /* Adjust the height to fit content */
  }
  
  /* Custom Stacked Bar */
  .custom-stacked-bar {
    display: flex;
    height: 10px; /* Make the bar smaller */
    background-color: #e5e7eb;
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 10px;
  }
  
  .bar-section {
    height: 100%;
  }
  
  .reached {
    background-color: grey; /* Color for Reached */
  }
  
  .opened {
    background-color: #facc15; /* Yellow for Opened */
  }
  
  .reply {
    background-color: #10b981; /* Green for Reply */
  }
  
  /* Bar legend below the bar */
  .bar-legend {
    display: flex;
    justify-content: space-between;
    margin-top: 10px; /* Adjust spacing between bar and legend */
  }
  
  .legend-item {
    display: flex;
    align-items: center;
  }
  
  .legend-color {
    width: 10px;
    height: 10px;
    margin-right: 5px;
  }
  
  .legend-color.reached {
    background-color: grey;
  }
  
  .legend-color.opened {
    background-color: #facc15;
  }
  
  .legend-color.reply {
    background-color: #10b981;
  }
  
  .percentage {
    font-size: 12px;
    font-weight: bold;
    margin-right: 5px;
  }
  
  .label {
    font-size: 12px;
    color: #6b7280;
  }
  
  /* Card styling */
  .card {
    background-color: white;
    border: 1px solid #cbd5e0;
    padding: 15px;
    border-radius: 8px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 180px;
    width: 25%;
    overflow: hidden;
  }
  
  /* First card with the chart */
  .card.wide {
    width: 50%; /* Make the first card take up 50% of the row */
    height: 180px;
  }
  
  /* Metrics below the chart */
  .metrics {
    display: flex;
    justify-content: space-between;
  }
  
  .metric {
    text-align: center;
  }
  
  .big-number {
    font-size: 24px;
    font-weight: bold;
  }
  
  .status-label {
    font-size: 12px;
    color: #6b7280;
  }
  
  .status-increase {
    font-size: 12px;
    color: #10b981;
    margin-top: 5px;
  }
  
  h4 {
    font-size: 16px;
    color: #1f2937;
    margin-bottom: 10px;
  }
  </style>
  