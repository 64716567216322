<template>
  <div class="container">
    <!-- Left Side: Header and Description -->
    <div class="left-side">
      <h1 class="header-title">Advanced Search</h1>
      <n-divider />
      <p class="header-description">
        Use the filters below to refine your search. Select an industry, specify the range of total employees, revenue, and monthly visitors, and then click search to find relevant companies.
      </p>
    </div>

    <!-- Right Side: Advanced Search Filter Box -->
    <div class="right-side">
      <div class="advanced-search-filter">
        <div class="input-area">
          <!-- Filters Input and Sliders -->
          <div class="filters-input">
            <!-- Industry Search -->
            <div class="filter-group">
              <label class="filter-label">Industry</label>
              <n-space>
                <n-tag
                  v-for="industry in selectedIndustries"
                  :key="industry"
                  closable
                  @close="removeIndustry(industry)"
                  type="info"
                  style="margin-bottom: 5px"
                >
                  {{ industry }}
                </n-tag>
              </n-space>
              <input
                v-model="searchQuery"
                @keyup.enter="addCustomIndustry"
                @blur="addCustomIndustry"
                placeholder="Type an industry and press Enter or click away"
                class="industry-input"
              />
            </div>

            <!-- Total Employees Dropdown -->
            <div class="filter-group">
              <label class="filter-label">Total Employees</label>
              <n-select
                v-model:value="selectedEmployeeIndex"
                :options="employeeOptions"
                placeholder="Select Total Employees Range"
                class="filter-select"
              />
            </div>

            <!-- Revenue Dropdown -->
            <div class="filter-group">
              <label class="filter-label">Revenue</label>
              <n-select
                v-model:value="selectedRevenueRange"
                :options="revenueOptions"
                placeholder="Select Revenue Range"
                class="filter-select"
              />
            </div>

            <!-- Monthly Visitors Dropdown -->
            <div class="filter-group">
              <label class="filter-label">Monthly Visitors</label>
              <n-select
                v-model:value="selectedVisitorRange"
                :options="visitorOptions"
                placeholder="Select Monthly Visitors Range"
                class="filter-select"
              />
            </div>

            <!-- Search Button -->
            <n-button @click="fetchCompanyCount" class="search-button">Search</n-button>
          </div>

          <!-- Display Results -->
          <div v-if="loading" class="loading-container">
            <n-loading-bar :show="loading" />
          </div>
          <p v-if="error" class="error">{{ error }}</p>
          <div v-if="companyCount !== null" class="result-box">
            <p class="result-text">Total companies found:</p>
            <p class="result-number">{{ formattedCompanyCount }}</p>
          </div>

          <!-- Confetti Animation -->
          <div v-if="showConfetti" class="confetti"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from 'vue';
import axios from 'axios';
import { NButton, NSelect, NLoadingBar, NTag, NSpace } from 'naive-ui';
import confetti from 'canvas-confetti';

export default defineComponent({
  components: {
    NButton,
    NSelect,
    NLoadingBar,
    NTag,
    NSpace,
  },
  setup() {
    const selectedIndustries = ref([]);
    const searchQuery = ref('');

    const employeeOptions = [
      { label: 'None', value: '' },
      { label: '1-10', value: '1-10' },
      { label: '10-50', value: '10-50' },
      { label: '50-200', value: '50-200' },
      { label: '200-500', value: '200-500' },
      { label: '500-1000', value: '500-1000' },
      { label: '1000-5000', value: '1000-5000' },
      { label: '5000-10000', value: '5000-10000' },
      { label: '10000+', value: '10000+' },
    ];

    const revenueOptions = [
      { label: 'None', value: '' },
      { label: 'Under $1m', value: 'under-1m' },
      { label: '$1m-$10m', value: '1m-10m' },
      { label: '$10m-$50m', value: '10m-50m' },
      { label: '$50m-$100m', value: '50m-100m' },
      { label: '$100m-$200m', value: '100m-200m' },
      { label: '$200m-$1b', value: '200m-1b' },
      { label: 'Over $1b', value: 'over-1b' },
    ];

    const visitorOptions = [
      { label: 'None', value: '' },
      { label: 'Under 10k', value: 'under-10k' },
      { label: '10k-50k', value: '10k-50k' },
      { label: '50k-100k', value: '50k-100k' },
      { label: '100k-500k', value: '100k-500k' },
      { label: '500k-1m', value: '500k-1m' },
      { label: '1m-10m', value: '1m-10m' },
      { label: '10m-50m', value: '10m-50m' },
      { label: '50m-100m', value: '50m-100m' },
      { label: '100m-500m', value: '100m-500m' },
      { label: '500m-1b', value: '500m-1b' },
      { label: 'Over 1b', value: 'over-1b' },
    ];

    const selectedEmployeeIndex = ref('');
    const selectedRevenueRange = ref('');
    const selectedVisitorRange = ref('');
    const companyCount = ref(null);
    const loading = ref(false);
    const error = ref(null);
    const showConfetti = ref(false);

    const addCustomIndustry = () => {
      const inputValue = searchQuery.value.trim();
      if (inputValue && !selectedIndustries.value.includes(inputValue)) {
        selectedIndustries.value.push(inputValue);
        searchQuery.value = '';
      }
    };

    const removeIndustry = (industry) => {
      selectedIndustries.value = selectedIndustries.value.filter((i) => i !== industry);
    };

    const fetchCompanyCount = async () => {
      loading.value = true;
      error.value = null;
      showConfetti.value = false;

      try {
        let query = [];

        if (selectedIndustries.value.length > 0) {
          query.push({
            attribute: 'industries',
            operator: 'or',
            sign: 'equals',
            values: selectedIndustries.value,
          });
        }

        if (selectedEmployeeIndex.value) {
          query.push({
            attribute: 'totalEmployees',
            operator: 'or',
            sign: 'equals',
            values: [selectedEmployeeIndex.value],
          });
        }

        if (selectedRevenueRange.value) {
          query.push({
            attribute: 'revenue',
            operator: 'or',
            sign: 'equals',
            values: [selectedRevenueRange.value],
          });
        }

        if (selectedVisitorRange.value) {
          query.push({
            attribute: 'monthlyVisitors',
            operator: 'or',
            sign: 'equals',
            values: [selectedVisitorRange.value],
          });
        }

        const queryString = encodeURIComponent(JSON.stringify(query));
        const token = 'x40fp1F4'; // Your API token
        const url = `https://api.thecompaniesapi.com/v1/companies/count?query=${queryString}&token=${token}`;

        const response = await axios.get(url);

        if (response.data && response.data.count !== undefined) {
          companyCount.value = response.data.count;
          if (companyCount.value > 0) {
            showConfetti.value = true;
            confetti({
              particleCount: 200,
              spread: 70,
              origin: { y: 0.6 },
              colors: ['#0f0'], // Green confetti
            });
            setTimeout(() => (showConfetti.value = false), 5000); // Hide confetti after 5 seconds
          }
        } else {
          companyCount.value = 0;
        }
      } catch (err) {
        error.value = 'Error fetching company count. Please try again.';
      } finally {
        loading.value = false;
      }
    };

    const formattedCompanyCount = computed(() => {
      return companyCount.value !== null ? companyCount.value.toLocaleString() : 'N/A';
    });

    return {
      selectedIndustries,
      searchQuery,
      employeeOptions,
      revenueOptions,
      visitorOptions,
      selectedEmployeeIndex,
      selectedRevenueRange,
      selectedVisitorRange,
      fetchCompanyCount,
      companyCount,
      loading,
      error,
      formattedCompanyCount,
      addCustomIndustry,
      removeIndustry,
      showConfetti,
    };
  },
});
</script>

<style scoped>
/* Main container to hold both sides */
.container {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

/* Left Side Styles */
.left-side {
  flex: 1;
  padding-right: 20px;
}

.header-title {
  font-size: 3em;
    font-weight: bold;
    color: #08956b;
    margin-bottom: 20px;
  margin: 0;
}

.header-description {
  padding-right: 20px;
  margin: 10px 0;
  font-size: 1.2em;
  color: #666;
  line-height: 1.5em;
  display: flex;
}

/* Right Side Styles */
.right-side {
  flex: 1;
}

.advanced-search-filter {
  padding: 20px;
  border: rgba(182, 182, 182, 0.995) solid 1px;
  border-radius: 5px;
  display: flex;
  justify-content: flex-end; /* Align filter content to the right */
}

.input-area {
  display: flex;
  flex-direction: column;
  align-items: flex-end; /* Align inputs and results to the right */
  width: 100%;
}

.filters-input {
  display: flex;
  flex-direction: column;
  align-items: flex-end; /* Align input fields to the right */
  width: 100%;
  margin-bottom: 20px;
}

.filter-group {
  margin-bottom: 20px;
  width: 100%;
}

.filter-label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
  text-align: left; /* Align labels to the right */
}

.filter-select {
  width: 100%;
}

.industry-input {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.search-button {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border-radius: 4px;
  text-align: center;
}

.loading-container {
  color: #4caf50;
}

.error {
  color: red;
  text-align: center;
}

.result-box {
  align-self: center;
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  background-color: #f9f9f9;
  text-align: center; /* Align result text to the right */
  width: 80%;
  margin-top: 10px;
}

.result-text {
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 5px;
}

.result-number {
  font-size: 2.2em;
  font-weight: bold;
  color: rgba(13, 187, 126, 0.995);
  text-align: center; /* Align result number to the right */
}

.confetti {
  position: absolute;
  top: 0;
  left: 0px;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  z-index: 1000;
}
</style>
