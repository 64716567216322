<template>
  <div class="sign-in-wrapper">
    <div class="sign-in-container">
      <n-card class="sign-in-card">
        <div class="card-header">
          <a href="https://www.outria.com/">
          <img src="@/assets/OutriaLogo.png" alt="Logo" class="logo" />
        </a>
          <h1 class="title">Welcome back</h1>
        </div>

        <!-- Email Input -->
        <n-input
          v-model:value="email"
          placeholder="Email address"
          class="input-field"
          :status="emailError ? 'error' : ''"
          @blur="validateEmail"
        />
        <div v-if="emailError" class="error-message">{{ emailError }}</div>

        <!-- Password Input -->
        <n-input
          v-model:value="password"
          placeholder="Password"
          type="password"
          class="input-field"
          :status="passwordError ? 'error' : ''"
          @blur="validatePassword"
        />
        <div v-if="passwordError" class="error-message">{{ passwordError }}</div>

        <!-- Continue Button -->
        <n-button @click="handleSubmit" class="submit-button">Continue</n-button>
        <div v-if="signInError" class="error-message">{{ signInError }}</div>

        
        <!-- OR Separator -->
        <div class="or-separator">
          <span>OR</span>
        </div>

        <!-- Google Sign-In Button -->
        <n-button class="oauth-button google-button" @click="handleGoogleSignIn">
          <img src="https://developers.google.com/identity/images/g-logo.png" class="oauth-logo" alt="Google logo" />
          <span>Continue with Google</span>
        </n-button>

        <!-- LinkedIn Sign-In Button -->
        <n-button class="oauth-button linkedin-button" @click="handleLinkedInSignIn">
          <img src="https://upload.wikimedia.org/wikipedia/commons/c/ca/LinkedIn_logo_initials.png" class="oauth-logo" alt="LinkedIn logo" />
          <span>Continue with LinkedIn</span>
        </n-button>

        <div class="sign-up-link">
          <span>Don't have an account? </span>
          <a href="/signup">Sign up</a>
        </div>

      </n-card>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue';
import { NButton, NInput, NCard } from 'naive-ui';
import { useRouter } from 'vue-router';
import { supabase } from '@/supabaseClient';

export default defineComponent({
  components: {
    NButton,
    NInput,
    NCard,
  },
  setup() {
    const email = ref('');
    const password = ref('');
    const emailError = ref('');
    const passwordError = ref('');
    const signInError = ref('');
    const router = useRouter();

    const validateEmail = () => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      emailError.value = emailRegex.test(email.value) ? '' : 'Invalid email address';
    };

    const validatePassword = () => {
      passwordError.value = password.value.length >= 6 ? '' : 'Password must be at least 6 characters';
    };

    const handleSubmit = async () => {
      validateEmail();
      validatePassword();

      if (!emailError.value && !passwordError.value) {
        try {
          const { error } = await supabase.auth.signInWithPassword({
            email: email.value,
            password: password.value,
          });

          if (error) {
            signInError.value = error.message;
          } else {
            router.push('/select-organization');
          }
        } catch (error) {
          signInError.value = 'An unexpected error occurred';
        }
      }
    };

    const handleGoogleSignIn = async () => {
      try {
        const { error } = await supabase.auth.signInWithOAuth({
          provider: 'google',
        });

        if (error) {
          signInError.value = error.message;
        }
      } catch (error) {
        signInError.value = 'An unexpected error occurred during Google sign-in';
      }
    };

    const handleLinkedInSignIn = async () => {
      try {
        const { error } = await supabase.auth.signInWithOAuth({
          provider: 'linkedin',
        });

        if (error) {
          signInError.value = error.message;
        }
      } catch (error) {
        signInError.value = 'An unexpected error occurred during LinkedIn sign-in';
      }
    };

    return {
      email,
      password,
      emailError,
      passwordError,
      signInError,
      validateEmail,
      validatePassword,
      handleSubmit,
      handleGoogleSignIn,
      handleLinkedInSignIn,
    };
  },
});
</script>

<style scoped>
.sign-in-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #ffffff;
}

.sign-in-container {
  width: 100%;
  max-width: 400px;
}

.sign-in-card {
  padding: 40px 20px;
  background-color: #ffffff;
  
  border: 1px solid #cbd5e0;
}

.card-header {
  text-align: center;
  margin-bottom: 20px;
}

.logo {
  height: 50px;
  margin-bottom: 20px;
}

.title {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 20px;
  color: #333333;
}

.input-field {
  margin-bottom: 10px;
}

.submit-button {
  width: 100%;
  background-color: #3ecda0;
  color: white;
}

.sign-up-link {
  margin-top: 20px;
  text-align: center;
  font-size: 14px;
}

.sign-up-link a {
  color: #129b59;
  font-weight: bold;
  text-decoration: none;
}

.or-separator {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  font-size: 12px;
  color: #999999;
}

.or-separator:before,
.or-separator:after {
  content: '';
  flex: 1;
  height: 1px;
  background: #e0e0e0;
  margin: 0 10px;
}

.oauth-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  border: 1px solid #e0e0e0;
  background-color: #f5f5f5;
  color: #333333;
  font-size: 14px;
  height: 40px;
}

.oauth-logo {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}
</style>
