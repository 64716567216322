<template>
  <div class="database-page">
    <div class="sidebar">
      <div class="menu-header">
        <h3 class="menu-section-title">Ads Campaign List</h3>
        <n-input placeholder="Search..." v-model="searchQuery" class="search-input" clearable />
        <div class="filter-buttons">
          <n-button text @click="filterStatus('Active')" :class="{'active-filter': activeFilter === 'Active'}">Active</n-button>
          <n-button text @click="filterStatus('Finished')" :class="{'active-filter': activeFilter === 'Finished'}">Finished</n-button>
          <n-button text @click="filterStatus('Pending')" :class="{'active-filter': activeFilter === 'Pending'}">Pending</n-button>
        </div>
      </div>
      <n-divider class="divider" />
      <div class="menu-section">
        <div v-for="campaign in filteredCampaigns" :key="campaign.id" class="menu-item" @click="selectCampaign(campaign)">
          <div class="campaign-icon">
            <img :src="getCampaignIcon(campaign.type)" alt="Campaign Icon" />
          </div>
          <div class="campaign-details">
            <div class="campaign-name">{{ campaign.name }}</div>
            <div class="campaign-info">
              <span class="campaign-budget">Budget: ${{ campaign.budget }}</span>
              <span class="campaign-dates">Date: {{ campaign.dateCreated }}</span>
            </div>
            <div class="campaign-status" :class="{'active-status': campaign.status === 'Active'}">
              {{ campaign.status }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <CampaignView :campaign="selectedCampaign" />
    </div>

    <!-- Create Campaign Drawer -->
    <n-drawer v-model:show="showCreateDrawer" width="350px" placement="right">
      <n-drawer-content title="Create New Campaign">
        <n-form ref="createFormRef">
          <n-form-item label="Campaign Name" path="name">
            <n-input v-model="newCampaign.name" placeholder="Enter campaign name" />
          </n-form-item>
          <n-form-item label="Description" path="description">
            <n-input v-model="newCampaign.description" placeholder="Enter campaign description" />
          </n-form-item>
          <n-form-item label="Campaign Type" path="type">
            <n-select v-model="newCampaign.type" :options="campaignTypes" placeholder="Select campaign type" />
          </n-form-item>
          <n-space justify="end">
            <n-button @click="showCreateDrawer = false">Cancel</n-button>
            <n-button type="primary" @click="submitCreateCampaign">Create</n-button>
          </n-space>
        </n-form>
      </n-drawer-content>
    </n-drawer>
  </div>
</template>



<script>
import { defineComponent, ref, computed, onMounted } from 'vue';
import { NButton, NDivider, NInput, NDrawer, NDrawerContent, NForm, NFormItem, NSelect, NSpace } from 'naive-ui';
import CampaignView from '@/components/CampaignView.vue';
import { supabase } from '@/supabaseClient';

export default defineComponent({
  name: 'DatabasePage',
  components: {
    NButton,
    NDivider,
    NInput,
    NDrawer,
    NDrawerContent,
    NForm,
    NFormItem,
    NSelect,
    NSpace,
    CampaignView,
  },
  setup() {
    const campaigns = ref([]);
    const selectedCampaign = ref(null);
    const searchQuery = ref('');
    const activeFilter = ref(''); // Empty by default to show all campaigns
    const showCreateDrawer = ref(false);

    // Define the new campaign object
    const newCampaign = ref({
      name: '',
      description: '',
      type: '',
      budget: 0,
      dateCreated: new Date().toLocaleDateString(),
      status: 'Finished',
    });

    const fetchCampaigns = async () => {
      const { data, error } = await supabase.from('campaigns').select('*');
      if (error) {
        console.error('Error fetching campaigns:', error);
      } else {
        campaigns.value = data.map(campaign => ({
          ...campaign,
          budget: campaign.budget || 0,
          dateCreated: campaign.dateCreated || new Date().toLocaleDateString(),
          status: campaign.status || 'Finished'
        }));
      }
    };

    const getCampaignIcon = (type) => {
      if (type === 'search_companies') {
        return require('@/assets/searchc.png');
      }
      return require('@/assets/id.png'); // Default icon
    };

    const filterStatus = (status) => {
      activeFilter.value = status;
    };

    const filteredCampaigns = computed(() => {
      if (!activeFilter.value) {
        return campaigns.value;
      }
      return campaigns.value.filter(campaign => {
        return (
          campaign.status === activeFilter.value &&
          campaign.name.toLowerCase().includes(searchQuery.value.toLowerCase())
        );
      });
    });

    const selectCampaign = (campaign) => {
      selectedCampaign.value = campaign;
    };

    const submitCreateCampaign = async () => {
      if (!newCampaign.value.name) {
        alert('Please enter a campaign name');
        return;
      }

      try {
        const { data, error } = await supabase
          .from('campaigns')
          .insert([newCampaign.value]);

        if (error) {
          console.error('Error creating new campaign:', error);
          return;
        }

        campaigns.value.push(data[0]);
        showCreateDrawer.value = false;
        newCampaign.value = {
          name: '',
          description: '',
          type: '',
          budget: 0,
          dateCreated: new Date().toLocaleDateString(),
          status: 'Finished',
        };
      } catch (error) {
        console.error('Unexpected error:', error);
      }
    };

    onMounted(fetchCampaigns);

    return {
      campaigns,
      selectedCampaign,
      searchQuery,
      activeFilter,
      filterStatus,
      filteredCampaigns,
      getCampaignIcon,
      selectCampaign,
      submitCreateCampaign,
      showCreateDrawer,
      newCampaign,
    };
  },
});
</script>





<style scoped>
.database-page {
  display: flex;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.sidebar {
  width: 220px;
  background-color: #ffffff;
  border-right: 1px solid #e5e7eb;
  padding: 20px;
  box-sizing: border-box;
  overflow-y: auto;
}

.menu-header {
  padding-bottom: 20px;
}

.menu-section-title {
  font-size: 18px;
  font-weight: 700;
  color: #111827;
  margin-bottom: 20px;
}

.search-input {
  margin-bottom: 20px;
}

.filter-buttons {
  display: flex;
  justify-content: space-between;
}

.filter-buttons .n-button {
  flex: 1;
  margin: 0 5px;
  padding: 5px 10px;
  border-radius: 4px;
  background-color: #f3f4f6;
  font-size: 14px;
  color: #6b7280;
  text-align: center;
}

.filter-buttons .active-filter {
  background-color: #e5e7eb;
  font-weight: bold;
}

.menu-section {
  padding: 10px 0;
}

.menu-item {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  margin-bottom: 15px;
  border-radius: 8px;
  background-color: #f9fafb;
  border: 1px solid #e5e7eb;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.menu-item:hover {
  background-color: #e5e7eb;
}

.campaign-icon img {
  width: 30px;
  height: 30px;
}

.campaign-details {
  margin-left: 10px;
  flex: 1;
}

.campaign-name {
  font-size: 16px;
  font-weight: 600;
  color: #111827;
  margin-bottom: 5px;
}

.campaign-info {
  font-size: 12px;
  color: #6b7280;
}

.campaign-status {
  font-size: 12px;
  font-weight: 600;
  margin-top: 5px;
}

.active-status {
  color: #10b981;
}

.divider {
  margin: 20px 0;
  border-color: #e5e7eb;
}

.content {
  flex-grow: 1;
  padding: 20px;
  margin-left: 220px;
  box-sizing: border-box;
  overflow-y: auto;
  min-height: calc(100vh - 50px);
}
</style>


