<template>
    <div v-if="!loading">
      <div class="email-template" :style="{ top: templatePosition.y + 'px', left: templatePosition.x + 'px' }" @mousedown="startDragging">
        <!-- Email Template ID Section -->
        <div class="template-id-section">
          <span class="template-id-label">Template ID:</span>
          <span class="template-id">{{ emailTemplateId || 'No ID Found' }}</span>
          <span class="material-icons copy-icon" @click="copyToClipboard">content_copy</span>
          <span class="material-icons delete-icon" @click="deleteTemplate">delete</span>
        </div>
        <hr class="divider" />
  
        <!-- Top bar for viewing modes (desktop, mobile, etc.) -->
        <div class="view-options">
          <span @click="setView('desktop')" :class="{ active: viewMode === 'desktop' }">
            <span class="material-icons">desktop_mac</span> Desktop
          </span>
          <span @click="setView('mobile')" :class="{ active: viewMode === 'mobile' }">
            <span class="material-icons">smartphone</span> Mobile
          </span>
        </div>
        <hr class="divider" />
  
        <!-- Sender preview with current date -->
        <div class="sender-preview">
          <div class="sender-info">
            <img class="sender-avatar" src="https://via.placeholder.com/40" alt="Sender avatar" />
            <div class="sender-details">
              <p class="sender-name">{{ senderName }}</p>
              <p class="sender-email">{{ senderEmail }}</p>
            </div>
          </div>
          <p class="email-date">{{ formattedDate }}</p>
        </div>
        <hr class="divider" />
  
        <!-- Email content where components will be dropped -->
        <div class="email-content">
          <p v-if="!components.length">Drag and drop components to create your email template.</p>
  
          <div v-for="(component, index) in components" :key="index" class="dropped-component">
            <!-- Text Component -->
            <div v-if="component.type === 'text'">
              <n-input v-model="component.content" placeholder="Editable Text" />
            </div>
            <!-- Image Component -->
            <div v-if="component.type === 'image'">
              <input type="file" @change="handleImageUpload($event, index)" />
              <img v-if="component.src" :src="component.src" alt="Uploaded image" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <p>Loading...</p>
    </div>
  </template>
  
  <script>
  import { ref, onMounted } from 'vue';
  import { supabase } from '@/supabaseClient'; // Your Supabase client
  import { useNotification } from 'naive-ui';
  import { v4 as uuidv4 } from 'uuid';
  
  export default {
    name: 'EmailTemplateBasic',
    props: ['templateId', 'templateData'],
    setup(props, { emit }) {
      const loading = ref(true);
      const emailTemplateId = ref(props.templateId || null); // Email template ID from props or null
      const viewMode = ref('desktop');
      const notification = useNotification();
      const isDragging = ref(false);
      const templatePosition = ref(props.templateData?.position || { x: 100, y: 100 }); // Initial position
      const components = ref(props.templateData?.components || []); // Components dropped into the template
  
      // Simulate sender's details
      const senderName = ref('Regina Cooper');
      const senderEmail = ref('regina_cooper@gmail.com');
  
      const fetchTemplateId = async () => {
        if (!emailTemplateId.value) {
          const newTemplateId = uuidv4();
          try {
            const { error } = await supabase
              .from('user_templates')
              .insert({
                template_id: newTemplateId,
                user_id: (await supabase.auth.getSession()).data.session.user.id,
                position: templatePosition.value,
                components: components.value,
              });
            if (error) throw error;
  
            emailTemplateId.value = newTemplateId;
            emit('templateCreated', newTemplateId); // Emit event to the board
          } catch (error) {
            notification.error({
              content: 'Error creating new template.',
              duration: 2500,
            });
          }
        }
        loading.value = false;
      };
  
      // Save the position and components on move or change
      const saveTemplate = async () => {
        try {
          const { error } = await supabase
            .from('user_templates')
            .update({
              position: templatePosition.value,
              components: components.value,
            })
            .eq('template_id', emailTemplateId.value);
  
          if (error) throw error;
        } catch (error) {
          notification.error({
            content: 'Error saving template.',
            duration: 2500,
          });
        }
      };
  
      // Handle template movement (dragging)
      const startDragging = (event) => {
        isDragging.value = true;
        const startX = event.clientX;
        const startY = event.clientY;
        const initialX = templatePosition.value.x;
        const initialY = templatePosition.value.y;
  
        const move = (e) => {
          if (isDragging.value) {
            templatePosition.value.x = initialX + (e.clientX - startX);
            templatePosition.value.y = initialY + (e.clientY - startY);
          }
        };
  
        const stopDragging = () => {
          isDragging.value = false;
          window.removeEventListener('mousemove', move);
          window.removeEventListener('mouseup', stopDragging);
          saveTemplate();
        };
  
        window.addEventListener('mousemove', move);
        window.addEventListener('mouseup', stopDragging);
      };
  
      // Delete template
      const deleteTemplate = async () => {
        try {
          const { error } = await supabase.from('user_templates').delete().eq('template_id', emailTemplateId.value);
          if (error) throw error;
          emit('templateDeleted', emailTemplateId.value); // Notify the board to remove the template
        } catch (error) {
          notification.error({
            content: 'Error deleting template.',
            duration: 2500,
          });
        }
      };
  
      // Handle image upload
      const handleImageUpload = (event, index) => {
        const file = event.target.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onload = (e) => {
            components.value[index].src = e.target.result;
            saveTemplate();
          };
          reader.readAsDataURL(file);
        }
      };
  
      const copyToClipboard = () => {
        if (emailTemplateId.value) {
          navigator.clipboard.writeText(emailTemplateId.value).then(
            () => {
              notification.success({
                content: 'Template ID copied to clipboard!',
                duration: 1500,
              });
            },
            (err) => {
              notification.error({
                content: 'Failed to copy Template ID.',
                meta: err.message,
                duration: 2500,
              });
            }
          );
        }
      };
  
      onMounted(() => {
        fetchTemplateId();
      });
  
      return {
        loading,
        emailTemplateId,
        viewMode,
        senderName,
        senderEmail,
        components,
        templatePosition,
        isDragging,
        startDragging,
        copyToClipboard,
        deleteTemplate,
        handleImageUpload,
      };
    },
  };
  </script>
  
  
  <style scoped>
  .email-template {
    width: 100%;
    max-width: 600px;
    margin: 20px auto;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
    padding: 20px;
    position: relative;
  }
  
  .template-id-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    color: #333;
  }
  
  .template-id-label {
    font-weight: bold;
  }
  
  .template-id {
    color: #3ecda0;
    margin-right: 10px;
  }
  
  .copy-icon {
    cursor: pointer;
    color: #3ecda0;
  }
  
  .view-options {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
  }
  
  .view-options span {
    cursor: pointer;
    margin-left: 15px;
    display: flex;
    align-items: center;
    color: #666;
  }
  
  .view-options .material-icons {
    margin-right: 5px;
  }
  
  .view-options .active {
    color: #3ecda0;
  }
  
  .sender-preview {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .sender-info {
    display: flex;
    align-items: center;
  }
  
  .sender-avatar {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
  
  .sender-details {
    line-height: 1.2;
  }
  
  .sender-name {
    font-weight: bold;
    font-size: 14px;
  }
  
  .sender-email {
    font-size: 12px;
    color: #3ecda0;
  }
  
  .email-date {
    font-size: 12px;
    color: #888;
  }
  
  .email-preview-message {
    padding: 20px;
    background-color: #f9f9f9;
    border: 1px dashed #ccc;
    text-align: center;
    color: #888;
    min-height: 300px;
    position: relative;
  }
  
  .email-preview-message.highlight {
    border: 2px dashed #3ecda0;
  }
  
  .dropped-component {
    position: absolute;
    cursor: move;
  }
  
  .divider {
    border: none;
    border-top: 1px solid #e0e0e0;
    margin: 15px 0;
  }
  </style>
  