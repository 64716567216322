<template>
    <div>
      <!-- Navbar -->
      <HomeNavBar />
      
      <!-- Main content -->
      <div class="main-content">
        <slot></slot> <!-- This is where the page content will be inserted -->
      </div>
      
      <!-- Footer -->
      <HomeFooter />
    </div>
  </template>
  
  <script>
  import HomeNavBar from '@/components/NavBar.vue';
  import HomeFooter from '@/components/FooterSection.vue';
  
  export default {
    components: {
      HomeNavBar,
      HomeFooter,
    },
  };
  </script>
  
  <style scoped>
  .main-content {
    padding: 20px;
  }
  </style>
  