<template>
    <div class="statistics-container">
      <div class="separator"></div>
      <div class="statistics">
        <div class="statistic">
          <div class="number">{{ animatedCompanies }}</div>
          <div class="label">Companies</div>
        </div>
        <div class="statistic">
          <div class="number">{{ animatedIndustries }}</div>
          <div class="label">Industries</div>
        </div>
        <div class="statistic">
          <div class="number">{{ animatedLocations }}</div>
          <div class="label">Locations</div>
        </div>
        <div class="statistic">
          <div class="number">{{ animatedDataPoints }}</div>
          <div class="label">Data Points</div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { defineComponent, ref, onMounted } from 'vue';
  
  export default defineComponent({
    name: 'StatisticsSection',
    setup() {
      const animatedCompanies = ref('0');
      const animatedIndustries = ref('0');
      const animatedLocations = ref('0');
      const animatedDataPoints = ref('0');
  
      const animateValue = (ref, start, end, duration, format) => {
        let startTime = null;
        const step = (timestamp) => {
          if (!startTime) startTime = timestamp;
          const progress = timestamp - startTime;
          const current = Math.min(start + (end - start) * (progress / duration), end);
          ref.value = format(current);
          if (progress < duration) {
            requestAnimationFrame(step);
          }
        };
        requestAnimationFrame(step);
      };
  
      const formatNumber = (number) => {
        if (number >= 1000000) return (number / 1000000).toFixed(1) + 'M';
        if (number >= 1000) return (number / 1000).toFixed(1) + 'k';
        return number.toFixed(0);
      };
  
      onMounted(() => {
        animateValue(animatedCompanies, 0, 83400000, 5000, formatNumber);
        animateValue(animatedIndustries, 0, 24300, 5000, formatNumber);
        animateValue(animatedLocations, 0, 155400, 5000, formatNumber);
        animateValue(animatedDataPoints, 0, 98, 5000, formatNumber);
      });
  
      return {
        animatedCompanies,
        animatedIndustries,
        animatedLocations,
        animatedDataPoints,
      };
    },
  });
  </script>
  
  <style scoped>
  .statistics-container {
    
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 60px;
    margin-bottom: -10px;
  }
  
  .separator {
    width: 70%;
    height: 1px;
    background-color: #ccc;
    margin-bottom: 20px;
  }
  
  .statistics {
    display: flex;
    justify-content: space-around;
    width: 60%;

  }
  
  .statistic {
    text-align: center;
  }
  
  .number {
    font-size: 1.7rem;
    font-weight: bold;
    color: #444343;
  }
  
  .label {
    font-size: 1.1rem;
    color: #333;
  }

  
  </style>
  