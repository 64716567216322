<template>
  <div>
    <HeaderWithLinks :userName="userName" :quickLinks="quickLinks" />
    <ContinentTabs />
  </div>
</template>

<script>
import HeaderWithLinks from '@/components/HeaderWithLinks.vue';
import ContinentTabs from '@/components/ContinentTabs.vue';

export default {
  components: {
    HeaderWithLinks,
    ContinentTabs
  },
  data() {
    return {
      userName: 'John Doe', // Replace with actual data
      quickLinks: [
        { label: 'Home', url: '/home' },
        { label: 'Profile', url: '/profile' },
        { label: 'Settings', url: '/settings' },
        { label: 'Logout', url: '/logout' }
      ]
    };
  }
};
</script>

<style scoped>
/* Add any additional styles here */
</style>
