<template>
    <div class="balance-card-container">
      <div class="balance-card bg-gray-100 p-4 rounded-lg shadow-md">
        <div class="balance-content">
          <div class="balance-item">
            <h3>Total Balance</h3>
            <span class="text-xs text-gray-500">Your current credits.</span>
            <div class="text-xl font-bold text-gray-800">{{ userBalance }}</div>
          </div>
          <n-divider />
          <h3>Data</h3>
          <div class="balance-item">
            <span class="text-xs text-gray-500">Another Info</span>
            <div class="text-xl font-bold text-gray-800">{{ userBalance }}</div>
          </div>
        </div>
      </div>
  
      <!-- World Map -->
      <div class="world-map-container">
        <v-chart :options="mapOptions" autoresize />
      </div>
    </div>
  </template>
  
  <script>
  import { defineComponent } from 'vue';
  import { use } from 'echarts/core';
  import VChart from 'vue-echarts';
  import { MapChart } from 'echarts/charts';
  import { CanvasRenderer } from 'echarts/renderers';
  import { TooltipComponent, GeoComponent, VisualMapComponent } from 'echarts/components';
  
  // Register necessary components
  use([
    CanvasRenderer,
    MapChart,
    TooltipComponent,
    GeoComponent,
    VisualMapComponent
  ]);
  
  export default defineComponent({
    name: 'BalanceCard',
    components: {
      VChart
    },
    props: {
      userBalance: {
        type: Number,
        required: true
      }
    },
    data() {
      return {
        mapOptions: {
          tooltip: {
            trigger: 'item',
            formatter: '{b}'
          },
          geo: {
            map: 'world',
            roam: true,
            label: {
              show: false
            },
            itemStyle: {
              normal: {
                areaColor: '#e0e0e0',
                borderColor: '#999999'
              },
              emphasis: {
                areaColor: '#a3c7e5'
              }
            }
          }
        }
      };
    }
  });
  </script>
  
  <style scoped>
  .balance-card-container {
    width: 300px; /* Adjust width as needed */
    border: #cbd5e0 1px solid;
    padding: 1rem;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* Align items to the top */
  }
  
  .balance-card {
    display: flex;
    flex-direction: column;
    height: 100%; /* Ensure it takes full height of the container */
  }
  
  .balance-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* Align content to the top */
    flex-grow: 1; /* Allow content to expand and fill space */
  }
  
  .balance-item {
    margin-bottom: 0.5rem;
  }
  
  .bg-gray-100 {
    background-color: #f7fafc;
  }
  
  .world-map-container {
    margin-top: 1rem;
    height: 200px; /* Adjust the height as needed */
  }
  </style>
  